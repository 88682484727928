import React from 'react';

const Footer = () => {
  return (
    <div
      md={{
        width: 'calc(100vw - 240px)',
      }}
      style={{
        padding: 32,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        justifyContent: 'flex-end',
        marginLeft: -24,
        marginBottom: -24,
        marginRight: -24,
        right: 0,
        marginTop:`${window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(max-width: 767px)").matches?"10px":"unset"}`
      }}
    >
      {' '}
      © 2020-{new Date().getFullYear()} KasperTech{' '}
    </div>
  );
};

export default Footer;
