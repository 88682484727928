import React, { useContext } from "react";
import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import customTheme from "../../../theme";
import {
  AccountCircle,
  AdminPanelSettings,
  BugReport,
  // CloudSync,
  Dashboard,
  DesignServices,
  DocumentScanner,
  FeaturedPlayListOutlined,
  Speed,
  SupervisedUserCircleRounded
} from "@mui/icons-material";
import SurroundSoundIcon from "@mui/icons-material/SurroundSound";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import { UserContext } from "../../../context/UserContext";

let secondary = customTheme.palette.secondary.main;
let light = customTheme.palette.light.main;

const SidebarList = ({ mobileOpen, handleDrawerToggle }) => {
  const location = useLocation();
  const path = location.pathname;
  const { userData } = useContext(UserContext);

  return (
    <div>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="/dashboard">
          <img
            src="/assets/logo/super-admin-logo-white-2.svg"
            alt="Luminox"
            style={{
              width: "auto",
              height: 44,
              marginBottom: -10,
            }}
          />
        </Link>
      </Toolbar>
      <Divider />
      <List>
        <ListItem
          button
          key={0}
          component={Link}
          to={"/dashboard"}
          onClick={mobileOpen ? handleDrawerToggle : null}
          style={
            path === "/dashboard"
              ? { background: light, color: secondary }
              : { background: "none" }
          }
        >
          <ListItemIcon
            style={
              path === "/dashboard" ? { color: secondary } : { color: "#fff" }
            }
          >
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>

        {userData !== undefined &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("SITE_TABLE")) && (
            <ListItem
              button
              key={1}
              component={Link}
              to={"/dashboard/site"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path.includes("/dashboard/site")
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path.includes("/dashboard/site")
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary={"Site"} />
            </ListItem>)}

        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes(
              "FETCH_DEVICE_LOGS"
            )) && (
            <ListItem
              button
              key={2}
              component={Link}
              to={"/dashboard/logs"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path.includes("/dashboard/logs")
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path.includes("/dashboard/logs")
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <DocumentScanner />
              </ListItemIcon>
              <ListItemText primary={"Logs"} />
            </ListItem>)}

        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("FETCH_ENERGY")) && (
            <ListItem
              button
              key={3}
              component={Link}
              to={"/dashboard/energy"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path.includes("/dashboard/energy")
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path.includes("/dashboard/energy")
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <Speed />
              </ListItemIcon>
              <ListItemText primary={"Energy"} />
            </ListItem>)}

        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("FETCH_BATCHES")) && (
            <ListItem
              button
              key={4}
              component={Link}
              to={"/dashboard/batch"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path === "/dashboard/batch"
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path === "/dashboard/batch"
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <BatchPredictionIcon />
              </ListItemIcon>
              <ListItemText primary={"Batches"} />
            </ListItem>)}
        {/* 
        <ListItem
          button
          key={0}
          component={Link}
          to={"/dashboard/version"}
          onClick={mobileOpen ? handleDrawerToggle : ""}
          style={
            path === "/dashboard/version"
              ? { background: light, color: secondary }
              : { background: "none" }
          }
        >
          <ListItemIcon
            style={
              path === "/dashboard/version"
                ? { color: secondary }
                : { color: "#fff" }
            }
          >
            <CloudSync />
          </ListItemIcon>
          <ListItemText primary={"Version"} />
        </ListItem> */}
        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("FETCH_DESIGNS")) && (
            <ListItem
              button
              key={5}
              component={Link}
              to={"/dashboard/design"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path.includes("/dashboard/design")
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path.includes("/dashboard/design")
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <DesignServices />
              </ListItemIcon>
              <ListItemText primary={"Designs"} />
            </ListItem>)}

        {userData &&
          (userData?.user.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("TESTING_DEVICE") ||
            userData?.user?.role?.permissions.includes("TESTING_LOGS")) && (
            <ListItem
              button
              key={6}
              component={Link}
              to={"/dashboard/testing"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path === "/dashboard/testing"
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path === "/dashboard/testing"
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <BugReport />
              </ListItemIcon>
              <ListItemText primary={"Testing"} />
            </ListItem>)}

        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("DEVICE_INVENTORY")) && (
            <ListItem
              button
              key={7}
              component={Link}
              to={"/dashboard/device"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path.includes("/dashboard/device")
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path.includes("/dashboard/device")
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <SurroundSoundIcon />
              </ListItemIcon>
              <ListItemText primary={"Devices"} />
            </ListItem>)}

        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes(
              "FETCH_USERS"
            )) && (
            <ListItem
              button
              key={8}
              component={Link}
              to={"/dashboard/users"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path === "/dashboard/users"
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path === "/dashboard/users"
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <SupervisedUserCircleRounded />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItem>)}

        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("FETCH_KTROLES")) && (
            <ListItem
              button
              key={9}
              component={Link}
              to={"/dashboard/role"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path === "/dashboard/role"
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path === "/dashboard/role"
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <AdminPanelSettings />
              </ListItemIcon>
              <ListItemText primary={"KT Role"} />
            </ListItem>
          )}

        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("FETCH_KTUSERS")) && (
            <ListItem
              button
              key={10}
              component={Link}
              to={"/dashboard/ktuser"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path === "/dashboard/ktuser"
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path === "/dashboard/ktuser"
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary={"KT Users"} />
            </ListItem>)}

        {userData &&
          (userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("FETCH_FEATURES")) && (
            <ListItem
              button
              key={11}
              component={Link}
              to={"/dashboard/feature"}
              onClick={mobileOpen ? handleDrawerToggle : null}
              style={
                path === "/dashboard/feature"
                  ? { background: light, color: secondary }
                  : { background: "none" }
              }
            >
              <ListItemIcon
                style={
                  path === "/dashboard/feature"
                    ? { color: secondary }
                    : { color: "#fff" }
                }
              >
                <FeaturedPlayListOutlined />
              </ListItemIcon>
              <ListItemText primary={"Feature"} />
            </ListItem>)}

      </List>
    </div>
  );
};

export default SidebarList;
