import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { createNewCompany } from "../../../api/company";

const CreateNewCompany = ({
  formOpen,
  handleFormClose,
  setSeverity,
  setMessage,
  setAlertOpen,
  companyData,
  setCompanyData,
}) => {
  const [loading,setLoading]=useState(false);
  const handleSubmit = () => {
    setLoading(true);
    // code to register company
    createNewCompany(
      companyData,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      setLoading
    );
  };

  return (
    <React.Fragment>
      <Dialog open={formOpen} onClose={handleFormClose}>
        <DialogTitle>Create New Site</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="siteName"
            label="Site Name"
            value={companyData.company_name}
            name="siteName"
            onChange={(e) => {
              setCompanyData({ ...companyData, company_name: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="Latitude"
            label="Latitude"
            value={companyData.latitude}
            name="Latitude"
            onChange={(e) => {
              setCompanyData({ ...companyData, latitude: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="Longitude"
            label="Longitude"
            value={companyData.longitude}
            name="Longitude"
            onChange={(e) => {
              setCompanyData({ ...companyData, longitude: e.target.value });
            }}
          />
          <FormControlLabel
            label="Has metering"
            control={
              <Checkbox
                inputProps={{ "aria-label": "Has metering" }}
                checked={companyData.allow_metering}
                sx={{ alignSelf: "center" }}
                onChange={(e) => {
                  setCompanyData({
                    ...companyData,
                    allow_metering: e.target.checked,
                  });
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions style={{ padding: "16px 28px" }}>
          <Button
            type="submit"
            variant="outlined"
            onClick={handleSubmit}
            color="secondary"
          >
            {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create New Site"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateNewCompany;
