/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import { getEnergyLogs } from "../../../../api/device";
import { Cached } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function DeviceEnergyLogs({
  device_id,
  logData,
  setLogData,
  setAlertOpen,
  setSeverity,
  setMessage,
}) {
  // console.log(logData);
  const [tableLoading, setTableLoading] = useState(false);
  const fetchDeviceLogs = async () => {
    setTableLoading(true);
    await getEnergyLogs(
      device_id,
      0,
      5,
      "",
      setLogData,
      "",
      setTableLoading,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  };
  const navigate= useNavigate();
  useEffect(() => {
    fetchDeviceLogs();
  }, []);
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", margin: "20px 0px" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Device Energy Logs</Typography>
          <div style={{ display: "flex", alignItems: "left" }}>
            <Button 
            variant="outlined"
            sx={{ ml: 2, mr: 2 }}
            onClick={(e)=>{
              navigate(`/dashboard/energy/${device_id}`)
            }}>
              View All Logs
            </Button>
            <IconButton
              onClick={(e) => {
                fetchDeviceLogs();
              }}
            >
              <Tooltip title="Refresh" arrow placement="top">
                <Cached />
              </Tooltip>
            </IconButton>
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key={"energy"} align={"center"}>
                  Energy(Kw/h)
                </TableCell>
                <TableCell key={"voltage"} align={"center"} colSpan={3}>
                  Voltage(V)
                </TableCell>
                <TableCell key={"current"} align={"center"} colSpan={3}>
                  Current (A)
                </TableCell>
                <TableCell key={"power"} align={"center"} colSpan={3}>
                  Power(W)
                </TableCell>
                <TableCell key={"pf"} align={"center"} colSpan={3}>
                  PowerFactor
                </TableCell>
                <TableCell key={"ct"} align={"center"}>
                  Created At
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                ></TableCell>
                <TableCell>Phase 1</TableCell>
                <TableCell>Phase 2</TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                >
                  Phase 3
                </TableCell>
                <TableCell>Phase 1</TableCell>
                <TableCell>Phase 2</TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                >
                  Phase 3
                </TableCell>
                <TableCell>Phase 1</TableCell>
                <TableCell>Phase 2</TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                >
                  Phase 3
                </TableCell>
                <TableCell>Phase 1</TableCell>
                <TableCell>Phase 2</TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                >
                  Phase 3
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {tableLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell>Loading data...</TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {logData.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.energy}
                      </TableCell>
                      <TableCell align="center">{row.phase1.voltage}</TableCell>
                      <TableCell align="center">{row.phase2.voltage}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.phase3.voltage}
                      </TableCell>
                      <TableCell align="center">{row.phase1.current}</TableCell>
                      <TableCell align="center">{row.phase2.current}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.phase3.current}
                      </TableCell>
                      <TableCell align="center">{row.phase1.power}</TableCell>
                      <TableCell align="center">{row.phase2.power}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.phase3.power}
                      </TableCell>
                      <TableCell align="center">
                        {row.phase1.powerfactor}
                      </TableCell>
                      <TableCell align="center">
                        {row.phase2.powerfactor}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.phase3.powerfactor}
                      </TableCell>

                      <TableCell align="center">
                        {new Date(row.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
