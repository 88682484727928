// Batch apis

import axiosIntance from "../helper/axios";

const getAllBatchList = (
  setBatchData,
  searchQuery,
  table,
  page,
  limit,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading,
  filterData
) => {
  if (table === "true") {
    // setLoading(true);
    axiosIntance
      .get(`/batch/all?table=${table}&page=${page}&limit=${limit}&isDeleted=${filterData.deleted}&createdAt=${filterData.createdAt}&searchTerm=${searchQuery}`)
      .then((res) => {
        setBatchData(res.data.data.batch_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setMessage("Error in fetching device list");
        setAlertOpen(true);
        setLoading(false);
      });
  } else {
    axiosIntance
      .get(`/batch/all`)
      .then((res) => {
        setBatchData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const createBatch = (
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/batch/register", data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      setSeverity("error");
      setMessage(err.response.data.error);
      setAlertOpen(true);
      setLoading(false);
    });
};

const deleteBatchById = (
  batch_id,
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading,
  setReload,
  reload
) => {
  setLoading(true);
  axiosIntance
    .delete(`/batch/delete/${batch_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setLoading(false);
      setReload(!reload);
    })
    .catch((err) => {
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const restoreBatchById = (
  batch_id,
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading,
  setReload,
  reload
) => {
  setLoading(true);
  axiosIntance
    .delete(`/batch/restore/${batch_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setLoading(false);
      setReload(!reload);
    })
    .catch((err) => {
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

export { getAllBatchList, createBatch,deleteBatchById,restoreBatchById };
