import React, { useState, useEffect } from "react";
import { Breadcrumbs, Divider } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import { useNavigate } from "react-router-dom";

import Loader from "../../components/Loader";
import { getDeviceList } from "../../api/device";
import { getAllCompanies } from "../../api/company";
import EnergyFilter from "./elements/EnergyFilter";
import EnergyTable from "./elements/EnergyTable";

const EnergyLogs = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    start_time: "",
    end_time: "",
  });
  const [deviceLoad, setDeviceLoad] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [fetch, setFetch] = useState(false);

  const [formState, setFormState] = useState({
    action: [],
  });
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setDeviceLoad(true);
    let dt = [];
    selectedCompanies.map((opt) => dt.push(opt._id));

    getDeviceList(
      setDeviceData,
      setDeviceLoad,
      "false",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      dt
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  useEffect(() => {
    getAllCompanies(setCompanyData, setLoading, "false");
  }, []);
  const handleFormClose = () => {
    setFilterOpen(false);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageTitle>{"Device Metering logs"}</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>
            <ActiveLink>Metering</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <EnergyTable
            deviceData={deviceData}
            loading={loading}
            setFilterOpen={setFilterOpen}
            formState={formState}
            refresh={refresh}
            setRefresh={setRefresh}
            filterData={filterData}
            setFilterData={setFilterData}
            setFormState={setFormState}
            companyData={companyData}
            deviceLoad={deviceLoad}
            setSelectedCompanies={setSelectedCompanies}
            selectedCompanies={selectedCompanies}
            fetch={fetch}
            setFetch={setFetch}
          />
          <EnergyFilter
            filterOpen={filterOpen}
            handleFilterClose={handleFormClose}
            formState={formState}
            setFormState={setFormState}
            refresh={refresh}
            setRefresh={setRefresh}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </>
      )}
    </div>
  );
};

export default EnergyLogs;
