import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllBatchList } from "../../../api/batch";
import { registerDevice } from "../../../api/device";
import { getAllVersionList } from "../../../api/version";
import { getAllDesignList } from "../../../api/design";

export default function CreateDevice({
  formOpen,
  handleFormClose,
  deviceData,
  setDeviceData,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {
  const [batchData, setBatchData] = useState([]);
  const [versionData, setVersionData] = useState([]);
  const [designData, setDesignData] = useState([]);
  const [loading,setLoading]=useState(false);

  const handleSubmit = () => {
    setLoading(true);
    // code to register user
    registerDevice(
      deviceData,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      setLoading
    );
  };

  useEffect(() => {
    getAllBatchList(setBatchData, "false");
    // getAllVersionList(setVersionData, "false");
    getAllDesignList(setDesignData, "false");
  }, []);

  return (
    <>
      <Dialog open={formOpen} onClose={handleFormClose}>
        <DialogTitle>Create New Device</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="normal"
            fullWidth
            label="Design"
            value={deviceData.design_id}
            onChange={(e) => {
              setDeviceData({
                ...deviceData,
                design_id: e.target.value,
              });
              getAllVersionList(setVersionData, "false", e.target.value);
            }}
          >
            {designData &&
              designData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.design_id + " " + option.title}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            select
            margin="normal"
            fullWidth
            label="Version"
            value={deviceData.version_id}
            onChange={(e) => {
              setDeviceData({
                ...deviceData,
                version_id: e.target.value,
              });
            }}
          >
            {versionData &&
              versionData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.version_id}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            select
            margin="normal"
            fullWidth
            label="Batch"
            value={deviceData.batch_id}
            onChange={(e) => {
              setDeviceData({
                ...deviceData,
                batch_id: e.target.value,
              });
            }}
          >
            {batchData &&
              batchData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.batch_name + "-" + option.batch_code}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            margin="normal"
            required
            fullWidth
            id="device_description"
            label="Device description"
            name="device_description"
            autoFocus
            onChange={(e) => {
              setDeviceData({
                ...deviceData,
                device_description: e.target.value,
              });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="imi_no"
            label="IMI number"
            name="imi_no"
            autoFocus
            onChange={(e) => {
              setDeviceData({ ...deviceData, imi_no: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="sim_no"
            label="SIM number"
            name="sim_no"
            autoFocus
            onChange={(e) => {
              setDeviceData({ ...deviceData, sim_no: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="mobile_no"
            label="Mobile number (SIM)"
            name="mobile_no"
            autoFocus
            onChange={(e) => {
              setDeviceData({ ...deviceData, mobile_no: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit}
          >
            {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:`Create Device`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
