/*jshint esversion: 11 */
import React, { useState } from 'react';
import { Button, Alert, Checkbox, FormControlLabel, Typography, TextField, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Breadcrumbs, } from '@mui/material';
import { useEffect } from 'react';
import { CircularProgress, Snackbar } from '@mui/material';
import { createKtUserRole, fetchKtUserPermission } from '../../../api/role';
import { ActiveLink, PageTitle, PathLink } from '../../../helper/style';
import { useNavigate } from 'react-router-dom';

const CreateRoleModal = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const [permissionListLoading, setPermissionListLoading] = useState(false);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [roleData, setRoleData] = useState({
    role_name: '',
    permissions: []
  });
  const [roleCreateRequestLoading, setRoleCreateRequestLoading] =
    useState(false);

  useEffect(() => {
    fetchAvailablePermissions();
    // eslint-disable-next-line
  }, []);

  const fetchAvailablePermissions = async () => {
    setPermissionListLoading(true);
    fetchKtUserPermission(setAvailablePermissions, setPermissionListLoading, setSeverity, setAlertOpen, setMessage,);
  };

  //   function buildSelectionInput(permissionMap) {
  //     const permissionInputMap = new Map();
  //     Object.keys(permissionMap)
  //       .flatMap((k) => permissionMap[k])
  //       .forEach((t) => {
  //         permissionInputMap.set(t, false);
  //       });
  //     return Object.fromEntries(permissionInputMap);
  //   }

  async function handleRoleCreation(event) {
    event.preventDefault();
    if (roleData.permissions.length === 0) {
      setAlertOpen(true);
      setSeverity("error");
      setMessage('Please select at least one permission');
      return;
    }
    setRoleCreateRequestLoading(true);
    await createKtUserRole(
      roleData,
      setSeverity,
      setMessage,
      setAlertOpen,
      setRoleCreateRequestLoading,
      setRoleData,
      navigate
    );
  }
  const handleAlertClose = () => {
    setAlertOpen(false);
  }
  const handlepermssionchange = (e, permission) => {
    if (e.target.checked) {
      setRoleData(prevData => ({
        ...prevData,
        permissions: [...prevData.permissions, permission]
      }));
    } else {
      setRoleData(prevData => ({
        ...prevData,
        permissions: prevData.permissions.filter(p => p !== permission)
      }));
    }
  }
  function chunkArray(arr, size) {
    return arr.reduce((acc, _, i) => {
      if (i % size === 0) {
        acc.push(arr.slice(i, i + size));
      }
      return acc;
    }, []);
  }
  return (
    <React.Fragment>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <PageTitle>{"Create New Role"}</PageTitle>
      <Breadcrumbs aria-label="breadcrumb">
        <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
          Dashboard
        </PathLink>
        <PathLink underline="hover" onClick={() => navigate("/dashboard/role")}>
          KT Role
        </PathLink>
        <ActiveLink>Create Role</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Paper variant="outlined" square style={{ padding: '20px', textAlign: 'left' }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <TextField
            name="role_name"
            label="Role Name"
            placeholder="Enter Role Name ..."
            type="text"
            required
            fullWidth
            style={{ "width": "89%", "marginRight": "10px" }}
            value={roleData.role_name}
            onChange={(e) => setRoleData({ ...roleData, role_name: e.target.value })}
          // style={{ marginRight: '10px' }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleRoleCreation}
            disabled={roleCreateRequestLoading}
          >
            {roleCreateRequestLoading ? (
              <>
                Loading{" "}
                <CircularProgress color="inherit" size={20} style={{ marginLeft: "8px" }} />
              </>
            ) : (
              'Create Role'
            )}
          </Button>
        </div>

        {/* <Divider style={{ marginBottom: '20px' }} /> */}
        {permissionListLoading ? (
          <CircularProgress />
        ) : (
          <TableContainer>
            <Table>
              <TableBody>
                {chunkArray(Object.keys(availablePermissions), 3).map((sections, idx) => (
                  <TableRow key={idx}>
                    {sections.map((section) => (
                      <TableCell key={section} style={{ verticalAlign: 'top', border: "1px solid black" }}>
                        <Typography variant="h6">{section}</Typography>
                        {/* <Divider /> */}
                        {availablePermissions[section].map((permission, idx) => (
                          <FormControlLabel
                            key={idx}
                            control={
                              <Checkbox
                                checked={roleData.permissions.includes(permission)}
                                onChange={(e) => handlepermssionchange(e, permission)}
                                name={permission}
                                color="primary"
                              />
                            }
                            label={permission}
                          />
                        ))}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      </Paper>
    </React.Fragment>
  );
};

export default CreateRoleModal;
