// Apis for all the transaction

import axiosIntance from "../helper/axios";

const createTransaction = async (
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/transactions/create", data)
    .then((res) => {
      setMessage("Plan Added successfully");
      setAlertOpen(true);
      setSeverity("success");
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setMessage(err.response.data.error);
      setAlertOpen(true);
      setSeverity("error");
      setLoading(false);
    });
};

const getAllTransactions = (
  setRows,
  setLoading,
  companyId,
  page,
  limit,
  setTotal,
  searchQuery
) => {
  axiosIntance
    .get(
      `/company/transaction/all/${companyId}?page=${page + 1}&limit=${limit}&searchTerm=${searchQuery}`
    )
    .then((res) => {
      setRows(res.data.data.transaction_data);
      setTotal(res.data.data.metadata.total_results);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};

export { createTransaction, getAllTransactions };
