import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

export default function DeleteDialog({
  deleteOpen,
  deleteLoading,
  handleDeleteConfirm,
  handleDeleteCancel,
  restore,
  handleRestoreConfirm
}) {
  return (
    <>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {restore ? "Restore Company" : "Delete Company"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {restore
              ? "Are You Sure you want to restore the site"
              : "Are You Sure you want to delete the site"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="success"
            onClick={(e) => {
              if (restore === true) {
                console.log("HERE")
                handleRestoreConfirm()
              } else {
                handleDeleteConfirm();
              }
            }}
          >
            {deleteLoading ? (
              <>
                Loading{" "}
                <CircularProgress
                  color="inherit"
                  size={20}
                  style={{ marginLeft: "8px" }}
                />
              </>
            ) : (
              "Yes"
            )}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={(e) => {
              handleDeleteCancel();
            }}
          >
            {deleteLoading ? (
              <>
                Loading{" "}
                <CircularProgress
                  color="inherit"
                  size={20}
                  style={{ marginLeft: "8px" }}
                />
              </>
            ) : (
              "No"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
