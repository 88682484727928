import axiosIntance from "../helper/axios";

const createRole = (
  roleData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/role/create", roleData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const updateRole = (
  roleId,
  roleData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .put(`/role/update/${roleId}`, roleData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};
const deleteRole = (
  roleId,
  siteId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .delete(`/role/delete/${siteId}/${roleId}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};
const fetchRolesofCompany = (
  companyId,
  setData,
  setLoading,
  page,
  limit,
  table,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  setCommonData,
  commonData,
  searchQuery
) => {
  // setLoading(true);
  if (table === "true") {
    axiosIntance
      .get(
        `/role/fetch/${companyId}?page=${
          page + 1
        }&limit=${limit}&table=${table}&searchTerm=${searchQuery}`
      )
      .then((res) => {
        setData(res.data.data.role_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        console.log(err.response.data.error);
        setAlertOpen(true);
        setSeverity("error");
        setMessage(err.response.data.error);
      });
  } else {
    setLoading(true);
    axiosIntance
      .get(`/role/fetch/${companyId}?table=${table}`)
      .then((res) => {
        setLoading(false);
        setCommonData({ ...commonData, roleData: res.data.data });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setCommonData({ ...commonData, roleData: 0 });
      });
  }
};
const fetchKtUserPermission = (
  setData,
  setLoading,
  setSeverity,
  setAlertOpen,
  setMessage
) => {
  axiosIntance
    .get(`/role/ktuser/permission/all`)
    .then((res) => {
      setLoading(false);
      setData(res.data.data);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};
const fetchKtUserRole = (
  role_id,
  setroleData,
  setLoading
) => {
  axiosIntance
    .get(`/role/ktuser/get/${role_id}`)
    .then((res) => {
      setLoading(false);
      setroleData(res.data.data);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
const fetchKtUserRoles = (
  setData,
  page,
  limit,
  setTotal,
  table,
  setLoading,
  setSeverity,
  setAlertOpen,
  setMessage,
  searchQuery
) => {
  axiosIntance
    .get(`/role/ktuser/all?page=${page + 1}&limit=${limit}&table=${table}&searchTerm=${searchQuery}`)
    .then((res) => {
      setLoading(false);
      setData(res.data.data.role_data);
      if(table===true)setTotal(res.data.data.metadata.total_results);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const createKtUserRole = (
  roleData,
  setSeverity,
  setMessage,
  setAlertOpen,
  setLoading,
  setRoleData,
  navigate
) => {
  axiosIntance
    .post("/role/ktuser/create", roleData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      // handleFormClose();
      setLoading(false);
      setRoleData({role_name: '',permissions: []});
      navigate("/dashboard/role");
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const updateKtUserRole = (
  roleId,
  roleData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setUpdateLoading,
  navigate
) => {
  axiosIntance
    .put(`/role/ktuser/update/${roleId}`, roleData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      // handleFormClose();
      setUpdateLoading(false);
      navigate("/dashboard/role")
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setUpdateLoading(false);
    });
};

const deleteKtUserRole = (
  roleId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .delete(`/role/ktuser/delete/${roleId}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};
export { 
  createRole,
  updateRole,
  deleteRole,
  fetchRolesofCompany,
  fetchKtUserPermission,
  createKtUserRole,
  fetchKtUserRoles,
  updateKtUserRole,
  deleteKtUserRole,
  fetchKtUserRole
};
