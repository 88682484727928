import React from 'react';
import { CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loader;
