/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
  Badge
} from "@mui/material";
import { Search as SearchIcon, FilterList, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { deleteDesignById, getAllDesignList, restoreDesignById } from "../../../api/design";

import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "code", label: "Design Code" },
  { id: "title", label: " Title" },
  { id: "updated_at", label: "Updated At" },
  { id: "view", label: "View Design" },
  { id: "update", label: "Update Design" },
  { id: "delete", label: "Delete Design" },
];

const DesignTable = ({
  setSeverity,
  setMessage,
  setAlertOpen,
  reload,
  setFormOpen,
  setUpdateData,
  setUpdateOpen,
  setReload,
  filterCount,
  setFilterOpen,
  filterData
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [designTableData, setDesignTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { userData } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    getAllDesignList(
      setDesignTableData,
      "true",
      page,
      rowsPerPage,
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading,
      searchTerm,
      filterData
    );
  }, [reload, page, rowsPerPage]);

  // useEffect(() => {
  //   // Filter data based on search query after a delay
  //   const timer = setTimeout(() => {
  //     const filteredData = designTableData.filter((row) =>
  //     row.title.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredDesignTableData(filteredData);
  //   }, 300); // Adjust the delay time as needed

  //   return () => clearTimeout(timer);
  // }, [searchTerm, designTableData]);

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteDesign = (id) => {
    deleteDesignById(
      id,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading,
      setReload,
      reload
    );
  };
  const restoreDesign = (id) => {
    restoreDesignById(
      id,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading,
      setReload,
      reload
    );
  };

  let debounceTimer;
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getAllDesignList(
        setDesignTableData,
        "true",
        0,
        rowsPerPage,
        setTotal,
        setSeverity,
        setAlertOpen,
        setMessage,
        setLoading,
        event.target.value,
        filterData
      );
    }, 1000);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Design Table</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
            </Search>
            {/* <Button onClick={fetchDesigns} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
             <Badge badgeContent={filterCount} color="primary">
              <IconButton
                onClick={(e) => {
                  setFilterOpen(true);
                }}
              >
                <Tooltip title="Filters" arrow placement="top">
                  <FilterList />
                </Tooltip>
              </IconButton>
            </Badge>
            {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("CREATE_DESIGN"))&&<IconButton>
              <Tooltip title="Add New Design" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {designTableData.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        {row.design_id}
                      </TableCell>
                      <TableCell>{row.title}</TableCell>

                      <TableCell>
                        {new Date(row.updatedAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>

                      <TableCell>
                        <Button
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("VIEW_DESIGN"))}
                          color="primary"
                          variant="outlined"
                          onClick={() =>
                            navigate(`/dashboard/design/${row._id}`)
                          }
                        >
                          View Design{" "}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_DESIGN"))}
                          color="warning"
                          variant="outlined"
                          onClick={() => {
                            setUpdateData({
                              _id: row._id,
                              design_id: row.design_id,
                              description: row.description,
                              title: row.title,
                            });
                            setUpdateOpen(true);
                          }}
                        >
                          Update Design
                        </Button>
                      </TableCell>
                      {row.is_deleted===false?<TableCell>
                        <Button
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_DESIGN"))}
                          color="error"
                          variant="outlined"
                          onClick={() => {
                            deleteDesign(row._id);
                          }}
                        >
                          Delete Design
                        </Button>
                      </TableCell>:<TableCell>
                        <Button
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_DESIGN"))}
                          color="error"
                          variant="outlined"
                          onClick={() => {
                            restoreDesign(row._id);
                          }}
                        >
                          Restore Design
                        </Button>
                      </TableCell>}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default DesignTable;
