import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Snackbar,
  Alert,
  CircularProgress,
  Paper, Table, TableBody, TableCell, TableContainer, TableRow, Breadcrumbs
} from "@mui/material";
import { fetchKtUserPermission, fetchKtUserRole, updateKtUserRole } from "../../../api/role";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { ActiveLink, PageTitle, PathLink } from "../../../helper/style";

const UpdateRole = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [roleData, setroleData] = useState({});
  const [permissionListLoading, setPermissionListLoading] = useState(false);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { role_id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    fetchAvailablePermissions();
    fetchKtUserRole(role_id, setroleData, setLoading);
    // eslint-disable-next-line
  }, []);

  const fetchAvailablePermissions = async () => {
    setPermissionListLoading(true);
    fetchKtUserPermission(setAvailablePermissions, setPermissionListLoading, setSeverity, setAlertOpen, setMessage,);
  };
  const handleSubmit = () => {
    setUpdateLoading(true);
    updateKtUserRole(
      role_id,
      roleData,
      setSeverity,
      setMessage,
      setAlertOpen,
      "",
      setUpdateLoading,
      navigate
    );
    // navigate("/dashboard/role")
  };
  const handlepermssionchange = (e, permission) => {
    if (e.target.checked) {
      setroleData(prevData => ({
        ...prevData,
        permissions: [...prevData.permissions, permission]
      }));
    } else {
      setroleData(prevData => ({
        ...prevData,
        permissions: prevData.permissions.filter(p => p !== permission)
      }));
    }
  }
  const handleAlertClose = () => {
    setAlertOpen(false);
  }
  function chunkArray(arr, size) {
    return arr.reduce((acc, _, i) => {
      if (i % size === 0) {
        acc.push(arr.slice(i, i + size));
      }
      return acc;
    }, []);
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <PageTitle>{"Update Role"}</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>
            <PathLink underline="hover" onClick={() => navigate("/dashboard/role")}>
              KT Role
            </PathLink>
            <ActiveLink>Update Role</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <Paper variant="outlined" square style={{ padding: '20px', textAlign: 'left' }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
              <TextField
                margin="normal"
                required
                fullWidth
                style={{ "width": "89%", "marginRight": "10px" }}
                id="roleName"
                label="Role Name"
                value={roleData.role_name}
                name="siteName"
                onChange={(e) => {
                  setroleData({ ...roleData, role_name: e.target.value });
                }}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{ "marginTop": "6px" }}
                onClick={handleSubmit}
                disabled={updateLoading}
              >
                {updateLoading ? (
                  <>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>
                ) : (
                  'Update Role'
                )}
              </Button>
            </div>

            {/* <Divider style={{ marginBottom: '20px' }} /> */}
            {permissionListLoading ? (
              <CircularProgress />
            ) : (
              <TableContainer>
                <Table>
                  <TableBody>
                    {chunkArray(Object.keys(availablePermissions), 3).map((sections, idx) => (
                      <TableRow key={idx}>
                        {sections.map((section) => (
                          <TableCell key={section} style={{ verticalAlign: 'top', border: "1px solid black" }}>
                            <Typography variant="h6">{section}</Typography>
                            {/* <Divider /> */}
                            {availablePermissions[section].map((permission, idx) => (
                              <FormControlLabel
                                key={idx}
                                control={
                                  <Checkbox
                                    checked={roleData.permissions?.includes(permission)}
                                    onChange={(e) => handlepermssionchange(e, permission)}
                                    name={permission}
                                    color="primary"
                                  />
                                }
                                label={permission}
                              />
                            ))}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper></React.Fragment>)}
    </>

  );
};

export default UpdateRole;
