import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { SimCard, Group } from "@mui/icons-material";

const StatCards = ({ deviceCount, versionCount }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Devices
              </Typography>
              <Typography variant="h4" color="primary">
                {!deviceCount ? "0" : deviceCount}
              </Typography>
            </Box>
            <SimCard sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }} />
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Versions
              </Typography>
              <Typography variant="h4" color="primary">
                {!versionCount ? "0" : versionCount}
              </Typography>
            </Box>
            <Group sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }} />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Users
              </Typography>
              <Typography variant="h4" color="primary">
                {!versionCount ? "0" : versionCount}
              </Typography>
            </Box>
            <Group sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default StatCards;
