/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  Button,
  Avatar,
  Box,
  Checkbox,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  TextField,
  DialogActions,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import {
  checkTestDevices,
  getDeviceList,
  getDeviceSignalStrength,
  resetDevice,
  simDetails,
  updateDeviceState,
} from "../../../api/device";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 230,
  },
});

const headers = [
  { id: "deviceID", label: "Device ID" },
  { id: "Timer", label: "Timer" },
  { id: "state", label: "State" },
  { id: "logs", label: "Logs" },
  { id: "update", label: "Update" },
  { id: "signal", label: "Signal Strength" },
  { id: "sim", label: "Sim Data" },
];

const DeviceTable = ({
  setSeverity,
  setMessage,
  setAlertOpen,
  setDeviceData,
  deviceData,
  setDeviceID,
  setUpdateForm,
  change,

  selectedDevices,
  setSelectedDevices,
  testingStatus,
  data,
  setData,
  setTestingStatus,
  handleFormClose,
  setSignalDialog,
  signalDialog,
  dialog,
  setDialog,
  alertOpen,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [testingTime, setTestingTime] = useState([]);

  const [loading, setLoading] = useState(false);
  const filterData = {
    version: "",
    design: "",
    assigned: false,
    batch: "",
  };
  const [deviceState, setDeviceState] = useState({
    timer: "",
    mode: "",
    switch: "",
    timerStatus: "",
    modeStatus: "",
    switchStatus: "",
    id: "",
    threshold: "",
    blocked: false,
  });
  const [stateOpen, setStateOpen] = useState(false);
  const [dialogLoad, setDialogLoad] = useState(true);
  const [signal, setSignal] = useState();
  const [simData, setSimData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { userData } = useContext(UserContext);

  // Function to handle search input change
  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getDeviceList(
        setData,
        setLoading,
        "true",
        0,
        rowsPerPage,
        filterData,
        setTotal,
        setSeverity,
        setAlertOpen,
        setMessage,
        "",
        event.target.value
      );
    }, 1000);
  };


  // show device state
  const showDeviceState = (deviceData, deviceId, blocked) => {
    setDeviceState({
      switch: deviceData.switch,
      switchStatus:
        deviceData.switchStatus === "1"
          ? "Switch Status: ON"
          : "Switch Status: OFF",
      mode: deviceData.mode,
      modeStatus:
        deviceData.modeStatus === "1" ? "Status: MANUAL" : "Status: AUTO",
      timer: deviceData.timer === "9999,9999" ? "NOT SET" : deviceData.timer,
      timerStatus:
        deviceData.timerStatus === "9999,9999"
          ? "Status: NOT SET"
          : "Status: " + deviceData.timerStatus,
      id: deviceId,
      threshold: deviceData.threshold ? deviceData.threshold : "0",
      blocked: blocked,
    });
    setStateOpen(true);
  };
  const handleStateUpdate = () => {
    let data = {
      switch_val: deviceState.switch,
      deviceId: deviceState.id,
      mode: deviceState.mode,
      threshold: deviceState.threshold,
      timer:
        deviceState.timer === "NOT SET" || deviceState.timer === ""
          ? "9999,9999"
          : deviceState.timer,
    };
    updateDeviceState(
      data,
      setSeverity,
      setAlertOpen,
      setMessage,
      handleFormClose,
      setStateOpen
    );
  };

  // Reset Device function
  const handleResetDevice = () => {
    resetDevice(
      setSeverity,
      setAlertOpen,
      setMessage,
      handleFormClose,
      setStateOpen,
      { deviceId: deviceState.id }
    );
  };

  // pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Device selection using checkbox
  const handleDeviceSelect = (e, device_id) => {
    if (e.target.checked) {
      setSelectedDevices((prev) => [...prev, device_id]);
    } else {
      setSelectedDevices((prev) => prev.filter((id) => id !== device_id));
    }
  };

  const handleAllDeviceSelect = (e, data) => {
    if (e.target.checked) {
      const deviceids = data.map((device) => device.device_id);
      let arr = deviceids;
      arr.push(...selectedDevices.filter((c) => !arr.includes(c)));
      setSelectedDevices(arr);
    } else {
      setSelectedDevices([]);
    }
  };

  // Get data details of sim
  const getSimData = (mobile_no) => {
    simDetails(
      mobile_no,
      setSimData,
      setDialogLoad,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  };

  // Get signal strength
  const getSignalStrength = (device_code) => {
    getDeviceSignalStrength(
      device_code,
      setSignal,
      setDialogLoad,
      setSeverity,
      setAlertOpen,
      setMessage
    );
  };

  // Get all device ist
  useEffect(() => {
    setLoading(true);
    getDeviceList(
      setData,
      setLoading,
      "true",
      page,
      rowsPerPage,
      filterData,
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      "",
      searchQuery
    );
  }, [page, rowsPerPage, change]);
  // Check Device testing
  useEffect(() => {
    checkTestDevices(
      setSelectedDevices,
      setTestingStatus,
      setLoading,
      setSeverity,
      setAlertOpen,
      setMessage,
      setTestingTime
    );
  }, [testingStatus]);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Devices</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Devices"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {/* <Button onClick={fetchDeviceList} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
            {/* <IconButton
              onClick={(e) => {
                setFilterOpen(true);
              }}
            >
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton> */}
            {/* <IconButton>
              <Tooltip title="Add Device" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton> */}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key="action">
                  <Checkbox
                    disabled={testingStatus}
                    checked={false}
                    onChange={(e) => handleAllDeviceSelect(e, data)}
                  />
                </TableCell>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell>
                        <Checkbox
                          disabled={testingStatus}
                          checked={selectedDevices.includes(row.device_id)}
                          onChange={(e) => handleDeviceSelect(e, row.device_id)}
                        />
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            style={
                              row.status === "online"
                                ? {
                                  backgroundColor: "#3cb043",
                                  height: 10,
                                  width: 10,
                                  boxShadow: "0 0 10px 1px green",
                                }
                                : row.status === "updating"
                                  ? {
                                    backgroundColor: "#FFC300",
                                    height: 10,
                                    width: 10,
                                    boxShadow: "0 0 10px 1px yellow",
                                  }
                                  : {
                                    backgroundColor: "#d0312d",
                                    height: 10,
                                    width: 10,
                                    boxShadow: "0 0 10px 1px red",
                                  }
                            }
                          >
                            &nbsp;
                          </Avatar>
                          <CustomTooltip
                            arrow
                            placement="top"
                            title={
                              "Created At: " +
                              new Date(row.createdAt).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                              }) +
                              "\nUpdated At: " +
                              new Date(row.updatedAt).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                              })
                            }
                          >
                            <span style={{ marginLeft: 12 }}>
                              {row.device_id}
                            </span>
                          </CustomTooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {selectedDevices.includes(row.device_id) &&
                          testingTime &&
                          testingTime.length > 0 ? (
                          <>
                            <div>ONTime: {testingTime[0]}min.</div>{" "}
                            <div>OFFTime: {testingTime[1]}min.</div>
                          </>
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("SET_DEVICE_STATE"))}
                          onClick={(e) =>
                            showDeviceState(
                              row.device_data,
                              row.device_id,
                              row.blocked
                            )
                          }
                        >
                          State
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("FETCH_DEVICE_LOGS"))}
                          onClick={(e) => {
                            window.open(`/dashboard/logs/${row._id}`);
                          }}
                        >
                          Get Logs
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("UPDATE_DEVICE"))}
                          onClick={(e) => {
                            setUpdateForm(true);
                            setDeviceID(row._id);
                            setDeviceData({
                              ...deviceData,
                              device_description: row.device_description,
                              imi_no: row.imi_no,
                              sim_no: row.sim_no,
                              mobile_no: row.mobile_no,
                              password: row.password,
                            });
                          }}
                        >
                          Update
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="info"
                          disabled={row.blocked || !(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("FETCH_DEVICE_SIGNAL"))}
                          onClick={() => {
                            setSignalDialog(true);
                            getSignalStrength(row.device_id);
                          }}
                        >
                          get Signal
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("FETCH_SIM_DATA"))}
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setDialog(true);
                            getSimData(row.mobile_no);
                          }}
                        >
                          Sim Data
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        open={stateOpen}
        onClose={(e) => {
          setStateOpen(false);
        }}
      >
        <DialogTitle>State of Device {deviceState.id}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Tooltip arrow placement="top" title={deviceState.switchStatus}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  id="switch"
                  label="Switch"
                  value={deviceState.switch}
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      switch: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={"0"}>OFF</MenuItem>
                  <MenuItem value={"1"}>ON</MenuItem>
                </TextField>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip arrow placement="top" title={deviceState.timerStatus}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="time"
                  label="Timer"
                  name="time"
                  value={deviceState.timer}
                  placeholder="start time,end time"
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      timer: e.target.value,
                    });
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip arrow placement="top" title={deviceState.modeStatus}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  id="switch"
                  label="Mode"
                  name="switch"
                  value={deviceState.mode}
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      mode: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={"0"}>AUTO</MenuItem>
                  <MenuItem value={"1"}>MANUAL</MenuItem>
                </TextField>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                id="switch"
                label="Threshold"
                name="switch"
                value={deviceState.threshold}
                onChange={(e) => {
                  setDeviceState({
                    ...deviceState,
                    threshold: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="warning"
            disabled={deviceState.blocked}
            onClick={handleResetDevice}
          >
            Reset Device
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            disabled={deviceState.blocked}
            onClick={handleStateUpdate}
          >
            Update State
          </Button>
        </DialogActions>
      </Dialog>
      {/* ---------------------- Sim Data Dialog ------------------- */}
      <Dialog
        open={dialog}
        onClose={() => {
          setDialog(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Sim Data</DialogTitle>

        {dialogLoad ? (
          <DialogContent>
            <p>Loading Data....</p>
          </DialogContent>
        ) : (
          <>
            {alertOpen ? (
              <DialogContent>Error</DialogContent>
            ) : (
              <DialogContent>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="simno"
                  label="Sim No"
                  value={simData?.simNo}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="planname"
                  label="Plan Name"
                  value={simData?.planName}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="usedData"
                  label="Used Data"
                  value={simData?.usedDataUsage + " MB"}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="DataLeft"
                  label="Data Left"
                  value={simData?.remainingDataUsage + " MB"}
                />
              </DialogContent>
            )}
          </>
        )}
      </Dialog>

      {/* Signal Strength Data dialog */}

      <Dialog
        open={signalDialog}
        onClose={() => {
          setSignalDialog(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Signal Strength</DialogTitle>

        {dialogLoad ? (
          <DialogContent>
            <p>Loading Data....</p>
          </DialogContent>
        ) : (
          <>
            {alertOpen ? (
              <DialogContent>Error</DialogContent>
            ) : (
              <DialogContent>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="signal"
                  label="Signal Strength"
                  value={signal}
                />
              </DialogContent>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default DeviceTable;
