import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import CrudGroup from "./elements/CrudGroup";
import ViewGroups from "./elements/ViewGroups";
import { UserContext } from "../../../../context/UserContext";

export default function GroupDashboard({
  setCommonData,
  commonData,
  deviceData,
}) {
  const { siteId } = useParams();
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [groupId, setGroupId] = useState(null);
  const { userData } = useContext(UserContext);

  const [formOpen, setFormOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [change, setChange] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [formState, setFormState] = useState({
    deviceArray: [],
  });
  const [deleteForm, setDeleteForm] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleFormClose = () => {
    setFormOpen(false);
    setGroupId("");
    setUpdate(false);
    setChange(!change);
    setGroupName("");
    setFormState({
      deviceArray: [],
    });
  };

  const handleDeleteFormClose = () => {
    setDeleteForm(false);
    setChange(!change);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("FETCH_SITE_GROUPS"))&&<ViewGroups
        siteId={siteId}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setFormOpen={setFormOpen}
        setUpdate={setUpdate}
        setGroupId={setGroupId}
        change={change}
        setGroupName={setGroupName}
        setFormState={setFormState}
        setCommonData={setCommonData}
        commonData={commonData}
        setDeleteForm={setDeleteForm}
      />}

      {(formOpen || deleteForm) && (
        <CrudGroup
          formOpen={formOpen}
          handleFormClose={handleFormClose}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          groupId={groupId}
          update={update}
          siteId={siteId}
          groupName={groupName}
          setGroupName={setGroupName}
          setFormState={setFormState}
          formState={formState}
          deviceData={deviceData}
          deleteForm={deleteForm}
          handleDeleteFormClose={handleDeleteFormClose}
        />
      )}
    </>
  );
}
