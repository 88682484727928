import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { syncMsisdnDetails } from "../../../api/device";

export default function MsisdnSyncDialog({ dialog, file, handleFormClose,setSeverity,setAlertOpen,setMessage }) {
  const handlesubmit=()=>{
    syncMsisdnDetails(file,setSeverity,setAlertOpen,setMessage,handleFormClose);
  }
  return (
    <Dialog
      open={dialog}
      onClose={handleFormClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Upload Sim inventory file
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to upload the file <b>{file.name}</b> ?
          <br />
          Download the Bulk KYC file from airtel panel and upload it!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="success" onClick={handlesubmit}>
          {false ? (
            <>
              Loading{" "}
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginLeft: "8px" }}
              />
            </>
          ) : (
            "Yes"
          )}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {
            handleFormClose();
          }}
        >
          {false ? (
            <>
              Loading{" "}
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginLeft: "8px" }}
              />
            </>
          ) : (
            "No"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
