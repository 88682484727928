import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlockDevice from "./elements/BlockDevice";
import CreateDevice from "./elements/CreateDevice";
import RemoveDevice from "./elements/RemoveDevice";
import UpdateDevice from "./elements/UpdateDevice";
import ViewDevices from "./elements/ViewDevices";
import UpdateVersion from "./elements/UpdateVersion";
import ShowAuto from "./elements/ShowAuto";
import { UserContext } from "../../../../context/UserContext";
import { getAllVersionList } from "../../../../api/version";

export default function DeviceDashboard({ setCommonData, commonData }) {
  const { siteId } = useParams();
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [block, setBlock] = useState();
  const [blockForm, setBlockForm] = useState(false);
  const [removeForm, setRemoveForm] = useState(false);
  const [version, setVersion] = useState();
  const [versionForm, setVersionForm] = useState(false);
  const [autoForm, setAutoForm] = useState(false);
  const [auto, setAuto] = useState();
  const [dialog, setDialog] = useState(false);
  const [signalDialog, setSignalDialog] = useState(false);
  const { userData } = useContext(UserContext);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    version: "",
    has_metering: ""
  });
  const [filterCount, setFilterCount] = useState("");
  const [versionData, setVersionData] = useState([]);
  const [designId, setDesignId] = useState("");

  const [formOpen, setFormOpen] = useState(false);
  const [deviceData, setDeviceData] = useState({
    device_description: "",
    company_id: siteId,
    imi_no: "",
    mobile_no: "",
    sim_no: "",
    blocked: false,
    has_metering: false
  });

  const [updateForm, setUpdateForm] = useState(false);
  const [deviceId, setDeviceID] = useState("");
  const [devicesId, setDevicesID] = useState([]);

  const [change, setChange] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
    setDialog(false);
  };
  const handleFormClose = () => {
    setFormOpen(false);
    setDeviceData({
      ...deviceData,
      imi_no: "",
      mobile_no: "",
      sim_no: "",
      device_description: "",
      has_metering: false,
    });
    setUpdateForm(false);
    setChange(!change);
    setDeviceID();
    setRemoveForm(false);
    setVersionForm(false);
    setAutoForm(false);
    setBlockForm(false);
    setFilterOpen(false);
    let count = 0;
    if (filterData.has_metering !== "") {
      count = count + 1;
    }
    if (filterData.version !== "") {
      count = count + 1;
    }
    setFilterCount(count);
    setDeviceID();
    setVersion();
  };

  useEffect(() => {
    getAllVersionList(setVersionData, "false");
  }, []);

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      {(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("FETCH_SITE_DEVICES")) && <ViewDevices
        siteId={siteId}
        setCommonData={setCommonData}
        commonData={commonData}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setUpdateForm={setUpdateForm}
        setDeviceID={setDeviceID}
        setDeviceData={setDeviceData}
        change={change}
        setBlock={setBlock}
        setBlockForm={setBlockForm}
        setFormOpen={setFormOpen}
        setRemoveForm={setRemoveForm}
        setAuto={setAuto}
        setAutoForm={setAutoForm}
        handleFormClose={handleFormClose}
        setVersionForm={setVersionForm}
        alertOpen={alertOpen}
        dialog={dialog}
        setDialog={setDialog}
        setSignalDialog={setSignalDialog}
        signalDialog={signalDialog}
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        filterData={filterData}
        setFilterData={setFilterData}
        filterCount={filterCount}
        setFilterCount={setFilterCount}
        versionData={versionData}
        setDesignId={setDesignId}
      />}
      {formOpen && (
        <CreateDevice
          formOpen={formOpen}
          handleFormClose={handleFormClose}
          deviceData={deviceData}
          setDeviceData={setDeviceData}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          deviceId={deviceId}
          setDeviceID={setDeviceID}
          siteId={siteId}
          devicesId={devicesId}
          setDevicesID={setDevicesID}
        />
      )}

      {updateForm && (
        <UpdateDevice
          handleFormClose={handleFormClose}
          updateForm={updateForm}
          deviceData={deviceData}
          setDeviceData={setDeviceData}
          deviceId={deviceId}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
        />
      )}

      {versionForm && (
        <UpdateVersion
          handleFormClose={handleFormClose}
          version={version}
          versionForm={versionForm}
          deviceId={deviceId}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          setVersion={setVersion}
          versionData={versionData}
          setVersionData={setVersionData}
          designId={designId}
        />
      )}
      {autoForm && (
        <ShowAuto
          handleFormClose={handleFormClose}
          auto={auto}
          autoForm={autoForm}
          deviceId={deviceId}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
        />
      )}

      {blockForm && (
        <BlockDevice
          handleFormClose={handleFormClose}
          block={block}
          blockForm={blockForm}
          deviceId={deviceId}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
        />
      )}
      {removeForm && (
        <RemoveDevice
          removeForm={removeForm}
          deviceId={deviceId}
          siteId={siteId}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          handleFormClose={handleFormClose}
        />
      )}
    </>
  );
}
