import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { FileUploadOutlined } from "@mui/icons-material";
import { updateVersion } from "../../../../../api/version";

export default function UpdateVersion({
  dialog,
  handleClose,
  versionData,
  setVersionData,
  setSeverity,
  setMessage,
  setAlertOpen,
  attachment,
  setAttachment,
  features,
  featureData,
  setFeatureData,
}) {
  const [loading, setLoading] = useState(false)
  const handleUpdateVersion = () => {
    setLoading(true)
    const form = new FormData();
    form.append("version_id", versionData.version_id);
    form.append("description", versionData.description);
    form.append("file", attachment);
    featureData.map((fd) => {
      form.append("features", fd);
    })
    updateVersion(
      versionData._id,
      form,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleClose, setLoading
    );
  };
  const handleChange = (event, value) => {
    setFeatureData(value.map((option) => option._id));
  };
  return (
    <>
      {/* Dialog to create new Batch */}

      <Dialog open={dialog} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Update Version</DialogTitle>

        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            value={versionData.description}
            onChange={(e) => {
              setVersionData({ ...versionData, description: e.target.value });
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Version bin file"
            value={
              attachment
                ? attachment.name +
                " - " +
                (attachment.size / 1000).toString() +
                " Kb"
                : ""
            }
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton component="label">
                  <FileUploadOutlined />
                  <input
                    styles={{ display: "none" }}
                    type="file"
                    hidden
                    onChange={(e) => {
                      setAttachment(e.target.files[0]);
                    }}
                    name="files"
                  />
                </IconButton>
              ),
            }}
          />
          <Autocomplete
            fullWidth
            style={{ paddingTop: "10px" }}
            multiple
            options={features}
            getOptionLabel={(option) => option.name}
            value={features.filter((feature) =>
              featureData.includes(feature._id)
            )}
            // isOptionEqualToValue={(option, value) => option._id === value._id}
            renderInput={(params) => (
              <TextField {...params} label="Features" variant="outlined" />
            )}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleUpdateVersion}
            disabled={loading}
          >
            {loading ? "Updating..." : "Update Version"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
