import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import CrudRole from "./element/CrudRole";
import ViewRoles from "./element/ViewRole";
import { UserContext } from "../../../../context/UserContext";
import DeleteRole from "./element/DeleteRole";

export default function RoleDashboard({ setCommonData, commonData }) {
  const { siteId } = useParams();
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const { userData } = useContext(UserContext);
  const [formOpen, setFormOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [change, setChange] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [roleName, setRoleName] = useState("");
  const [formState, setFormState] = React.useState({
    permissionArray: [],
  });

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleFormClose = () => {
    setRoleId();
    setRoleName("");
    setFormState({ permissionArray: [] });
    setFormOpen(false);
    setUpdate(false);
    setChange(!change);
    setDeleteOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {((userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("FETCH_SITE_ROLES")))&&<ViewRoles
        siteId={siteId}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setFormOpen={setFormOpen}
        setUpdate={setUpdate}
        setRoleId={setRoleId}
        change={change}
        setRoleName={setRoleName}
        setFormState={setFormState}
        setCommonData={setCommonData}
        commonData={commonData}
        setDeleteOpen={setDeleteOpen}
      />}

      {formOpen && (
        <CrudRole
          formOpen={formOpen}
          handleFormClose={handleFormClose}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          roleId={roleId}
          update={update}
          siteId={siteId}
          roleName={roleName}
          setRoleName={setRoleName}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {deleteOpen && <DeleteRole 
      deleteForm={deleteOpen}
      roleId={roleId}
      siteId={siteId}
      setAlertOpen={setAlertOpen}
      setSeverity={setSeverity}
      setMessage={setMessage}
      handleFormClose={handleFormClose}
      />}
    </>
  );
}
