/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  InputBase,
  TableRow,
  Typography,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import {
  Search as SearchIcon,
} from "@mui/icons-material";
import { getKtUsers } from "../../../api/ktUser";
import { styled } from "@mui/material/styles";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const headers = [
  { key: "name", label: "Name", align: "left" },
  { key: "email", label: "Email", align: "left" },
  { key: "role", label: "Role", align: "left" },
  { key: "update", label: "Update", align: "center" },
  { key: "delete", label: "Delete", align: "center" },
];

const KtUsersTable = ({
  setData,
  data,
  setKtUserId,
  setUpdateModal,
  setDeleteModal,
  loading,
  setCreateModal,
  refresh,
  setRefresh,
  fetch,
  setFetch,
  setKtUserData
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [alertOpen, setAlertOpen] = useState(false);
  const { userData } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState("");
  
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [total, setTotal] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchKtUsers = async () => {
    setTableLoading(true);

    await getKtUsers(
      setData,
  setTableLoading,
  page,
  rowsPerPage,
  "true",
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  searchQuery
    );
  };
  useEffect(()=>{
    fetchKtUsers();
  },[page, rowsPerPage, refresh])
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getKtUsers(
        setData,
    setTableLoading,
    0,
    rowsPerPage,
    "true",
    setTotal,
    setSeverity,
    setAlertOpen,
    setMessage,
    event.target.value
      );
    }, 1000);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>

        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
          }}
        >
          <Typography variant="h6">KT Users</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search KT Users"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
          {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("CREATE_KTUSER"))&&<div style={{ display: "flex", alignItems: "left" }}>
            <Button 
            variant="outlined"
            onClick={()=>{
              setCreateModal(true)
            }}
            >
            Create New KT User
            </Button>
          </div>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableLoading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell align="left" key={row.name}>{row.name}</TableCell>
                      <TableCell align="left" key={row.email}>{row.email}</TableCell>
                      <TableCell align="left" key={row.role_id}>{row.role}</TableCell>
                      <TableCell align="center" key="update">
                        <Button 
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_KTUSER"))}
                        variant="outlined"
                        onClick={()=>{
                          setKtUserId(row._id);
                          setKtUserData(row);
                          setUpdateModal(true);
                        }}
                        >Update KT User</Button>
                      </TableCell>
                      <TableCell align="center" key="delete">
                        <Button 
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_KTUSER"))}
                        variant="outlined"
                        color="error"
                        onClick={()=>{
                          setKtUserId(row._id);
                          setDeleteModal(true);
                        }}
                        >Delete KT User</Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 75, 250]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Create new device dialog */}
    </>
  );
};

export default KtUsersTable;
