import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";
import {
  activatePlansBySuperAdmin,
  expirePlanBySuperAdmin,
} from "../../../../../api/company";

export default function UpdatePlanStatus({
  action,
  setAction,
  setUpcoming,
  setAlertOpen,
  setSeverity,
  setMessage,
  upcoming,
  handleFormClose
}) {
  const expirePlan = () => {
    expirePlanBySuperAdmin(
      action.plan_id,
      setAlertOpen,
      setSeverity,
      setMessage,
      upcoming,
      handleFormClose
    );
  };

  const activatePlan = () => {
    activatePlansBySuperAdmin(
      action.plan_id,
      setAlertOpen,
      setSeverity,
      setMessage,
      handleFormClose
    );
  };
  return (
    <Dialog
      open={action.open}
      onClose={() => {
        setAction({ open: false, type: "", plan_id: "" });
        setUpcoming("");
      }}
    >
      <DialogTitle>
        {action.type === "expire" ? "Expire Plan" : "Activate Plan"}
      </DialogTitle>
      <DialogContent>
        Are you sure you want to{" "}
        {action.type === "expire" ? "Expire Plan" : "Activate Plan"}
        {action.type === "expire" && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setUpcoming(e.target.checked);
                  }}
                />
              }
              label="Activate upcoming plan"
            />
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="outlined"
          color={action.type === "expire" ? "error" : "success"}
          onClick={() => {
            action.type === "expire" ? expirePlan() : activatePlan();
          }}
        >
          {action.type === "expire" ? "Expire" : "Activate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
