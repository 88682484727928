const routes = {
  // Public
  landing: "/",
  login: "/login",
  register: "/register",

  /** Private */
  dashboard: "/dashboard",

  // Nested Routes: Dashboard
  device: "/dashboard/device",
  deviceDashboard: "/dashboard/device/:device_id",

  // Site
  site: "/dashboard/site",
  siteDashboard: "/dashboard/site/:siteId",

  // Logs
  logs: "/dashboard/logs",
  logstable: "/dashboard/logs/:device_id",

  // Energy metering
  energy: "/dashboard/energy",
  energylogs: "/dashboard/energy/:device_id",
  // Plans
  plans: "/dashboard/plans",

  // Batch
  batch: "/dashboard/batch",

  // version
  version: "/dashboard/version",

  // design
  design: "/dashboard/design",
  designDashboard: "/dashboard/design/:designId",
  
    //Testing
    testing:"/dashboard/testing",

    //Role
  role:"/dashboard/role",
  createRole:"/dashboard/role/create",
  updateRole:"/dashboard/role/update/:role_id",

  //KT User
  ktuser:"/dashboard/ktuser",

  //Users
  users: "/dashboard/users",

  //Features
  feature: "/dashboard/feature",
};

export default routes;
