import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {  getAllCompanies, sendNotification } from "../../../api/company";

export default function NotificationDialog({
  setSeverity,
  setMessage,
  setAlertOpen,
  notificationOpen,
  handleNotificationClose,
  notificationData,
  setNotificationData,
}) {
  const [companies, setcompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (event) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      [event.target.name]: event.target.value,
    }));
  };
  const handleSendNotification = () => {
    if (
      notificationData.title === "" ||
      notificationData.body === "" ||
      notificationData.companies.length === 0
    ) {
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Please Fill All Fields");
      return;
    }
    setLoading(true);
    sendNotification(
      setSeverity,
      setMessage,
      setAlertOpen,
      handleNotificationClose,
      notificationData,
      setLoading
    );
  };
  useEffect(() => {
    getAllCompanies(
      setcompanies,
      setLoading,
      "false",
      setAlertOpen,
      setSeverity,
      setMessage
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Dialog
        open={notificationOpen}
        onClose={handleNotificationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Send Notification"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="body"
            label="Notification Title"
            value={notificationData.title}
            name="NotificationTitle"
            onChange={(e) => {
              setNotificationData({
                ...notificationData,
                title: e.target.value,
              });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="body"
            label="Notification Body"
            value={notificationData.body}
            name="NotificationBody"
            onChange={(e) => {
              setNotificationData({
                ...notificationData,
                body: e.target.value,
              });
            }}
          />
          <TextField
            select
            margin="normal"
            fullWidth
            label="Companies"
            name="companies"
            id="companies"
            SelectProps={{
              multiple: true,
              value: notificationData.companies,
              onChange: handleFieldChange,
            }}
          >
            {companies &&
              companies.map((data) => {
                return (
                  <MenuItem value={data._id} key={data._id}>{data.company_name}</MenuItem>
                );
              })}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              handleSendNotification();
            }}
          >
            {loading ? (
              <>
                Loading{" "}
                <CircularProgress
                  color="inherit"
                  size={20}
                  style={{ marginLeft: "8px" }}
                />
              </>
            ) : (
              "Send Notification"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
