// Log filters

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
  } from "@mui/material";
  import React, {  } from "react";
  
  export default function Testing({
    testingOpen,
    handletestingClose,
    testingData,
    settestingData,
    handleTestingFilterClose
  }) {
  
    return (
      <>
        <Dialog
          open={testingOpen}
          onClose={handletestingClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Test Devices"}</DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              fullWidth
              label="On Time"
            //   value={testingData.ontime}
              onChange={(e) => {
                settestingData({ ...testingData, ontime: e.target.value });
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Off Time"
            //   value={testingData.offtime}
              onChange={(e) => {
                settestingData({ ...testingData, offtime: e.target.value });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                handleTestingFilterClose();
              }}
            >
              Start Testing
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  