import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { autoModeVisible } from "../../../../../api/device";

export default function ShowAuto({
  autoForm,
  handleFormClose,
  auto,
  deviceId,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {
  const handleAutoVisible = () => {
      autoModeVisible(
        deviceId,
        !auto,
        setSeverity,
        setMessage,
        setAlertOpen,
        handleFormClose
      );
  };
  return (
    <>
      {/* Block confirmation dialog */}

      <Dialog
        open={autoForm}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Auto Mode configure"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to <b>{auto === true ? "hide" : "show"}</b>{" "}
            the auto mode
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>No</Button>
          <Button color="error" onClick={handleAutoVisible}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
