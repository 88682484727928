import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateDeviceVersion } from "../../../api/device";
import { getAllVersionList } from "../../../api/version";

export default function UpdateVersion({
  versionForm,
  version,
  handleFormClose,
  deviceId,
  setSeverity,
  setMessage,
  setAlertOpen,
  setVersion,
  designId,
}) {
  const [versionData, setVersionData] = useState([]);
  const handleUpdateVersion = () => {
    updateDeviceVersion(
      deviceId,
      version,
      setSeverity,
      setAlertOpen,
      setMessage,
      handleFormClose
    );
  };
  useEffect(() => {
    getAllVersionList(setVersionData, "false", designId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Dialog
        open={versionForm}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update Version"}</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="normal"
            fullWidth
            label="Version"
            value={version}
            onChange={(e) => {
              setVersion(e.target.value);
            }}
          >
            {versionData &&
              versionData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.version_id}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleUpdateVersion}>
            Update Version
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
