import axiosIntance from "../helper/axios";

const createGroup = (
  groupData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .post("/group/register", groupData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const updateGroup = (
  groupId,
  groupData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .put(`/group/update/${groupId}`, groupData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const fetchGroupsCompany = (
  companyId,
  setGroupData,
  setLoading,
  page,
  limit,
  table,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  setCommonData,
  commonData,
  searchQuery
) => {
  // setLoading(true);
  if (table === "true") {
    axiosIntance
      .get(
        `/group/company/${companyId}?page=${
          page + 1
        }&limit=${limit}&table=${table}&searchTerm=${searchQuery}`
      )
      .then((res) => {
        setGroupData(res.data.data.group_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.error);
        setAlertOpen(true);
        setSeverity("error");
        setMessage(err.response.data.error);
      });
  } else {
    setLoading(true);
    axiosIntance
      .get(`/group/company/${companyId}?table=${table}`)
      .then((res) => {
        setLoading(false);
        setCommonData({ ...commonData, groupData: res.data.data });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        console.log(err.response.data.error);
        setCommonData({ ...commonData, groupData: 0 });
      });
  }
};

const deleteGroupSuperAdmin = (
  groupId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleDeleteFormClose
) => {
  axiosIntance
    .delete(`/group/delete/${groupId}`)
    .then((res) => {
      console.log(res.data);
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleDeleteFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

export { createGroup, updateGroup, fetchGroupsCompany, deleteGroupSuperAdmin };
