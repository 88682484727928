/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Breadcrumbs, Divider } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import { useNavigate } from "react-router-dom";

import Loader from "../../components/Loader";
import RoleTable from "./elements/RoleTable";
import { deleteKtUserRole } from "../../api/role";
import DeleteDialog from "./elements/DeleteRole";

const Role = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [roleId, setRoleId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [createRoleModal, setCreateRoleModal] = useState(false);
  const [updateRoleModal, setUpdateRoleModal] = useState(false);

  const [deleteRoleModal,setDeleteRoleModal]= useState(false);
  const [deleteLoading,setDeleteLoading]= useState(false);

  const [formState, setFormState] = useState({
    action: [],
  });

  const handleFormClose = () => {
    setCreateRoleModal(false);
    setUpdateRoleModal(false);
    setDeleteRoleModal(false);
    setRefresh(!refresh);
  };
  const closeCreateModal=()=>{
    setCreateRoleModal(false);
  }
  const closeUpdateModal=()=>{
    setUpdateRoleModal(false);
  }
  const handleDeleteConfirm=()=>{
    setDeleteLoading(true);
    deleteKtUserRole(roleId,setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,setDeleteLoading)
  }
  const handleDeleteCancel=()=>{
    setDeleteRoleModal(false);
  }
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageTitle>{"KT Role"}</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>
            <ActiveLink>Role</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <RoleTable
            setRoleData={setRoleData}
            setRoleId={setRoleId}
            setUpdateRoleModal={setUpdateRoleModal}
            setDeleteRoleModal={setDeleteRoleModal}
            loading={loading}
            setCreateRoleModal={setCreateRoleModal}
            formState={formState}
            refresh={refresh}
            setRefresh={setRefresh}
            setFormState={setFormState}
            fetch={fetch}
            setFetch={setFetch}
          />

          {/* <CreateRoleModal 
          createRoleModal={createRoleModal}
          availablePermissions={availablePermissions}
          setAvailablePermissions={setAvailablePermissions}
          closeCreateModal={closeCreateModal}
          handleFormClose={handleFormClose}
          />

          <UpdateRole 
          updateOpen={updateRoleModal}
          handleUpdateClose={closeUpdateModal}
          handleFormClose={handleFormClose}
          availablePermissions={availablePermissions}
          roleData={roleData}
          setroleData={setRoleData}
          roleId={roleId}
          /> */}

          <DeleteDialog 
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
          deleteOpen={deleteRoleModal}
          deleteLoading={deleteLoading}
          handleDeleteConfirm={handleDeleteConfirm}
          handleDeleteCancel={handleDeleteCancel}
          />
        </>
      )}
    </div>
  );
};

export default Role;
