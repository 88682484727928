import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import Axios from "axios";
// import Axios from "./helper/axios";
import { UserContext } from "./../context/UserContext";

const PrivateOutlet = ({ children }) => {
  const { userData } = useContext(UserContext); //getting Data form UserContext

  return userData.user ? children : <Navigate to="/login" />;
};

export default PrivateOutlet;
