import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import routes from "./routes";

// Components
import Loader from "./components/Loader";
import { UserContext } from "./context/UserContext";

// Layouts
import AuthLayout from "./layouts/AuthLayout";
import PublicLayout from "./layouts/PublicLayout";
import PrivateOutlet from "./routes/PrivateOutlet";

// Public Pages
// import Landing from "./pages/Landing";
import Login from "./pages/auth/Login";
import PageNotFound from "./pages/general/PageNotFound";
import Device from "./pages/Device";
import Company from "./pages/Company";
import CompanyDashboard from "./pages/CompanyDashboard";

import Axios from "./helper/axios";

// Private Pages
import Dashboard from "./pages/Dashboard";

import Logs from "./pages/Logs";
import Plans from "./pages/Plans";
import Batch from "./pages/Batch";
import Version from "./pages/Version";
import EnergyLogs from "./pages/EnergyLogs";
import Designs from "./pages/Design";
import DesignDashboard from "./pages/DesignDashboard";
import Users from "./pages/Users";
import TestingDashboard from "./pages/Testing";
import DeviceMetering from "./pages/Device/elements/Metering";
import Role from "./pages/KtUserRole";
import KtUser from "./pages/KTUser";
import CreateRoleModal from "./pages/KtUserRole/elements/CreateRole";
import UpdateRole from "./pages/KtUserRole/elements/UpdateRole";
import Feature from "./pages/Feature";

const publicPages = [
  // {
  //   path: routes.landing,
  //   page: Landing,
  //   layout: PublicLayout,
  // },
  {
    path: routes.login,
    page: Login,
    layout: PublicLayout,
  },
];

const privatePages = [
  {
    path: routes.dashboard,
    page: Dashboard,
    layout: AuthLayout,
  },

  {
    path: routes.device,
    page: Device,
    layout: AuthLayout,
  },
  {
    path: routes.deviceDashboard,
    page: DeviceMetering,
    layout: AuthLayout,
  },
  {
    path: routes.site,
    page: Company,
    layout: AuthLayout,
  },
  {
    path: routes.siteDashboard,
    page: CompanyDashboard,
    layout: AuthLayout,
  },
  {
    path: routes.plans,
    page: Plans,
    layout: AuthLayout,
  },
  {
    path: routes.logs,
    page: Logs,
    layout: AuthLayout,
  },
  {
    path: routes.logstable,
    page: Logs,
    layout: AuthLayout,
  },
  {
    path: routes.energy,
    page: EnergyLogs,
    layout: AuthLayout,
  },
  {
    path: routes.energylogs,
    page: EnergyLogs,
    layout: AuthLayout,
  },
  {
    path: routes.batch,
    page: Batch,
    layout: AuthLayout,
  },
  {
    path: routes.version,
    page: Version,
    layout: AuthLayout,
  },
  {
    path: routes.design,
    page: Designs,
    layout: AuthLayout,
  },
  {
    path: routes.designDashboard,
    page: DesignDashboard,
    layout: AuthLayout,
  },
  {
    path: routes.testing,
    page: TestingDashboard,
    layout: AuthLayout,
  },
  {
    path: routes.role,
    page: Role,
    layout: AuthLayout,
  },
  {
    path: routes.createRole,
    page: CreateRoleModal,
    layout: AuthLayout,
  },
  {
    path: routes.updateRole,
    page: UpdateRole,
    layout: AuthLayout,
  },
  {
    path: routes.ktuser,
    page: KtUser,
    layout: AuthLayout,
  },
  {
    path: routes.users,
    page: Users,
    layout: AuthLayout,
  },
  {
    path: routes.feature,
    page: Feature,
    layout: AuthLayout,
  },
];

const App = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
  });

  useEffect(() => {
    const checkLoggedin = async () => {
      try {
        let token = localStorage.getItem("auth-token");
        if (token === null) {
          localStorage.setItem("auth-token", "");
          token = "";
        }
        const userRes = await Axios.get("/auth/info");
        setUserData({
          token,
          user: userRes.data.data,
        });
      } finally {
        setLoading(false);
      }
    };

    checkLoggedin();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <UserContext.Provider value={{ userData, setUserData }}>
            <Routes>
              {/* Mapping Public Routes */}
              <Route
                path="/"
                element={<Navigate to="/login" replace={true} />}
              />

              {publicPages.map(({ path, page: Page, layout: Layout }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Layout>
                      <Page />
                    </Layout>
                  }
                />
              ))}

              {/* Mapping Private Routes */}
              {privatePages.map(({ path, page: Page, layout: Layout }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <PrivateOutlet>
                      <Layout>
                        <Page />
                      </Layout>
                    </PrivateOutlet>
                  }
                />
              ))}

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </UserContext.Provider>
        </Router>
      )}
    </>
  );
};

export default App;
