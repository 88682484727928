import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { getSimLocation, publishInitialData, updateDevice } from "../../../api/device";

export default function UpdateDevice({
  handleFormClose,
  updateForm,
  deviceData,
  setDeviceData,
  deviceId,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {

  const [locationLoading, setLocationLoading] = useState(false)
  const handleUpdate = () => {
    const data = {
      imi_no: deviceData.imi_no,
      sim_no: deviceData.sim_no,
      location: deviceData.device_description,
      mobile_no: deviceData.mobile_no,
      auto: deviceData.show_auto,
    };
    updateDevice(
      deviceId,
      data,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose
    );
  };

  const handleInitialPublish = () => {
    publishInitialData(deviceId, setSeverity, setMessage, setAlertOpen, handleFormClose);
  };

  const handleLocationFetch = () => {
    if (!deviceData.mobile_no) {
      setAlertOpen(true)
      setMessage("No msisdn number associated")
      setSeverity("error")
      return
    }
    setLocationLoading(true)
    getSimLocation(deviceId, deviceData.mobile_no, setSeverity, setMessage, setAlertOpen, handleFormClose);
  };


  return (
    <>
      {/* Update device block */}
      <Dialog open={updateForm} onClose={handleFormClose}>
        <DialogTitle>Update Device</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Device Password"
            name="password"
            disabled={true}
            value={deviceData.password}
            onClick={(e) => {
              navigator.clipboard.writeText(deviceData.password);
              setAlertOpen(true);
              setSeverity("success");
              setMessage("Password copied to clipboard");
            }}
          />
          {deviceData.location && (
            <TextField
              margin="normal"
              fullWidth
              id='location'
              disabled={true}
              value={deviceData.location.formatted_address}
            />
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="device_description"
            label="Device description"
            name="device_description"
            value={deviceData.device_description}
            autoFocus
            onChange={(e) => {
              setDeviceData({
                ...deviceData,
                device_description: e.target.value,
              });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="imi_no"
            label="IMI number"
            name="imi_no"
            autoFocus
            value={deviceData.imi_no}
            onChange={(e) => {
              setDeviceData({ ...deviceData, imi_no: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="sim_no"
            label="SIM number"
            name="sim_no"
            autoFocus
            value={deviceData.sim_no}
            onChange={(e) => {
              setDeviceData({ ...deviceData, sim_no: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="mobile_no"
            label="Mobile number (SIM)"
            name="mobile_no"
            value={deviceData.mobile_no}
            autoFocus
            onChange={(e) => {
              setDeviceData({ ...deviceData, mobile_no: e.target.value });
            }}
          />
          <Tooltip arrow placement="top" title={deviceData.show_auto}>
            <TextField
              margin="normal"
              fullWidth
              select
              id="autoVisible"
              label="Auto Visible"
              value={deviceData.show_auto}
              onChange={(e) => {
                setDeviceData({
                  ...deviceData,
                  show_auto: e.target.value,
                });
                // setUpdatedDeviceState({
                //   ...updatedDeviceState,
                //   auto: e.target.value,
                // });
              }}
            >
              <MenuItem value={false}>NO</MenuItem>
              <MenuItem value={true}>YES</MenuItem>
            </TextField>
          </Tooltip>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleLocationFetch}
            disabled={locationLoading}

          >
            {locationLoading ? "Loading...." : "Get Device Location"}
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleInitialPublish}
          >
            Publish Initial Data
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // sx={{ mt: 3, mb: 2 }}
            onClick={handleUpdate}
          >
            Update Device
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
