/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
  Collapse,
  Box,
  Badge,
} from "@mui/material";
import {
  Search as SearchIcon,
  FilterList,
  AddBox,
  Campaign,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { getAllCompanies } from "../../../api/company";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function Row(props) {
  const {
    row,
    setCompanyId,
    setUpdateOpen,
    setCompanyData,
    setDialog,
    setPlanUpdate,
    setPlanId,
    setNewPlan,
    setDeleteOpen,
    setRestore,
    userData
  } = props;
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: `${(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("FETCH_SITE_PLANS")||userData?.user?.role?.permissions.includes("CREATE_SITE_PLAN"))?"unset":"auto"}`  } }}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row._id}
      >
        <TableCell align="right" key="collapseIcon">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          key={row.company_code}
          scope="row"
          style={{ cursor: `${(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("VIEW_SITE"))?"pointer":"unset"}` }}
          onClick={() => (userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("VIEW_SITE"))?navigate(`/dashboard/site/${row._id}`):""}
        >
          {row.company_code}
        </TableCell>
        <TableCell  key={row.company_name}>{row.company_name}</TableCell>
        <TableCell key={row.allow_metering}>{row.allow_metering === true ? "YES" : "NO"}</TableCell>
        <TableCell key={"groups "+row.no_of_groups}>{row.no_of_groups}</TableCell>
        <TableCell key={"devices "+row.no_of_devices}>{row.no_of_devices}</TableCell>
        <TableCell key={"users "+row.no_of_users}>{row.no_of_users}</TableCell>
        <TableCell key={"roles "+row.no_of_roles}>{row.no_of_roles}</TableCell>
        <TableCell align="center" key={row.created_at}>
          {new Date(row.created_at).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </TableCell>
        <TableCell align="center" key="update">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_SITE"))}
            onClick={(e) => {
              setCompanyId(row._id);
              setCompanyData({
                company_name: row.company_name,
                latitude: row.latitude,
                longitude: row.longitude,
                allow_metering: row.allow_metering,
                notification: row.notification,
                subsequent: row?.notification_time?.subsequent,
                first: row?.notification_time?.first,
              });

              setUpdateOpen(true);
            }}
          >
            Update
          </Button>
        </TableCell>
        <TableCell align="center" key="delete">
          {row.is_deleted ? (
            <Button
              variant="outlined"
              color="warning"
              size="small"
              disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_SITE"))}
              onClick={(e) => {
                setCompanyId(row._id);
                setDeleteOpen(true);
                setRestore(true);
              }}
            >
              Restore
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="error"
              size="small"
              disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_SITE"))}
              onClick={(e) => {
                setCompanyId(row._id);
                setDeleteOpen(true);
              }}
            >
              Delete
            </Button>
          )}
        </TableCell>
      </TableRow>
      {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("FETCH_SITE_PLANS")||userData?.user?.role?.permissions.includes("CREATE_SITE_PLAN"))&&<TableRow key="plan_row">
        <TableCell key="plans" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginBottom: 5 }}>
              <div
                style={{
                  margin: "16px 8px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Sites Table</Typography>
                {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("CREATE_SITE_PLAN"))&&<div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      setDialog(true);
                      setCompanyId(row._id);
                    }}
                  >
                    Create Site Plan
                  </Button>
                </div>}
              </div>
              {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("FETCH_SITE_PLANS"))&&<Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="plan_code">Plan Code</TableCell>
                    <TableCell key="plan_name">Plan Name</TableCell>
                    <TableCell key="plan_users">Allowed Users</TableCell>
                    <TableCell key="plan_groups">Allowed Groups</TableCell>
                    <TableCell key="plan_price">Price (in Rs)</TableCell>
                    <TableCell key="plan_time">Time in Days</TableCell>
                    <TableCell key="plan_action">Update</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.plan_data.map((plan_row) => (
                    <TableRow key={plan_row._id}>
                      <TableCell component="th" scope="row">
                        {plan_row.plan_code}
                      </TableCell>
                      <TableCell key={plan_row.plan_name}>{plan_row.plan_name}</TableCell>
                      <TableCell key={"users "+plan_row.users_allowed}>{plan_row.users_allowed}</TableCell>
                      <TableCell key={"groups "+plan_row.groups_allowed}>{plan_row.groups_allowed}</TableCell>
                      <TableCell key={plan_row.price}>{plan_row.price}</TableCell>
                      <TableCell key={plan_row.time_period}>{plan_row.time_period}</TableCell>
                      <TableCell key="plan_update">
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          disabled={!((userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_SITE_PLAN")))}
                          onClick={(e) => {
                            setDialog(true);
                            setPlanId(plan_row._id);
                            setPlanUpdate(true);
                            setNewPlan({
                              plan_code: plan_row.plan_code,
                              plan_name: plan_row.plan_name,
                              users_allowed: plan_row.users_allowed,
                              groups_allowed: plan_row.groups_allowed,
                              price: plan_row.price,
                              time_period: plan_row.time_period,
                            });
                          }}
                        >
                          Update Plan
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>}
    </React.Fragment>
  );
}

const headers = [
  { id: "collapse", label: "" },
  { id: "code", label: "Site Code" },
  { id: "name", label: "Name" },
  { id: "meter", label: "Has Metering" },
  { id: "groups", label: "Groups" },
  { id: "devices", label: "Devices" },
  { id: "users", label: "Users" },
  { id: "roles", label: "Roles" },
  { id: "created_at", label: "Created At", align: "center" },
  { id: "update", label: "Update", align: "center" },
  { id: "delete", label: "Delete" },
];

const CompanyTable = ({
  setAlertOpen,
  setSeverity,
  setMessage,
  reload,
  setFormOpen,
  setUpdateOpen,
  setCompanyData,
  setCompanyId,
  setDialog,
  setPlanUpdate,
  setPlanId,
  setNewPlan,
  companyList,
  setCompanyList,
  setDeleteOpen,
  filterCount,
  setFilterOpen,
  filterData,
  setNotificationOpen,
  setRestore,
}) => {
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  let debounceTimer;
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getAllCompanies(
        setCompanyList,
        setLoading,
        "true",
        setAlertOpen,
        setSeverity,
        setMessage,
        0,
        rowsPerPage,
        setTotal,
        event.target.value,
        filterData
      );
    }, 1000);
  };

  React.useEffect(() => {
    fetchCompanyList();
  }, [page, rowsPerPage, reload]);

  const fetchCompanyList = () => {
    setLoading(true);
    getAllCompanies(
      setCompanyList,
      setLoading,
      "true",
      setAlertOpen,
      setSeverity,
      setMessage,
      page,
      rowsPerPage,
      setTotal,
      searchTerm,
      filterData
    );
  };
  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("SEND_NOTIFICATION"))&&<Box>
        <Button
          variant="outlined"
          color="info"
          sx={{
            mb: 2,
          }}
          onClick={() => setNotificationOpen(true)}
          startIcon={<Campaign />}
        >
          Send Bulk notification
        </Button>
      </Box>}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Sites Table</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={handleSearch}
              />
            </Search>
            {/* <Button
              onClick={fetchCompanyList}
              variant="outlined"
              color="primary"
              aria-label="search"
            >
              Search{""}
            </Button> */}
            <Badge badgeContent={filterCount} color="primary">
              <IconButton
                onClick={(e) => {
                  setFilterOpen(true);
                }}
              >
                <Tooltip title="Filters" arrow placement="top">
                  <FilterList />
                </Tooltip>
              </IconButton>
            </Badge>
            {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("CREATE_SITE"))&&<IconButton>
              <Tooltip title="Create New Site" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {companyList.map((row) => {
                  return (
                    <Row
                      key={row._id}
                      row={row}
                      setCompanyData={setCompanyData}
                      setUpdateOpen={setUpdateOpen}
                      setCompanyId={setCompanyId}
                      setDialog={setDialog}
                      setPlanUpdate={setPlanUpdate}
                      setPlanId={setPlanId}
                      setNewPlan={setNewPlan}
                      setDeleteOpen={setDeleteOpen}
                      setRestore={setRestore}
                      userData={userData}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default CompanyTable;
