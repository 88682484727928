import axiosIntance from "../helper/axios";

const getAllDesignList = (
  setDesignData,
  table,
  page,
  limit,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading,
  searchTerm,
  filterData
) => {
  if (table === "true") {
    // setLoading(true);
    axiosIntance
      .get(`/design/all?page=${page + 1}&limit=${limit}&table=${table}&isDeleted=${filterData.deleted}&createdAt=${filterData.createdAt}&searchTerm=${searchTerm}`)
      .then((res) => {
        setDesignData(res.data.data.design_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setMessage("Error in fetching design list");
        setAlertOpen(true);
        setLoading(false);
      });
  } else {
    axiosIntance
      .get(`/design/all`)
      .then((res) => {
        setDesignData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const createNewDesign = (
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/design/create", data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      setSeverity("error");
      console.log(err);
      setMessage(err.response.data.error);
      setAlertOpen(true);
      setLoading(false);
    });
};

const updateDesign = (
  design_id,
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .put(`/design/update/${design_id}`, data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setSeverity("error");
      setMessage(err.response.data.error);
      setAlertOpen(true);
    });
};

const deleteDesignById = (
  design_id,
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading,
  setReload,
  reload
) => {
  setLoading(true);
  axiosIntance
    .delete(`/design/delete/${design_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setLoading(false);
      setReload(!reload);
    })
    .catch((err) => {
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const restoreDesignById = (
  design_id,
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading,
  setReload,
  reload
) => {
  setLoading(true);
  axiosIntance
    .delete(`/design/restore/${design_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setLoading(false);
      setReload(!reload);
    })
    .catch((err) => {
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const getDesignById = (
  designId,
  setCount,
  setDesignData,
  setLoading,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  axiosIntance
    .get(`/design/fetch/${designId}`)
    .then((res) => {
      setDesignData(res.data.data.design_data);
      setCount({
        deviceCount: res.data.data.no_devices,
        versionCount: res.data.data.no_version,
      });
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setMessage("Error in fetching design data");
      setAlertOpen(true);
      setLoading(false);
    });
};

const getDesingFile = (
  key,
  type,
  setLoading,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  setLoading(true);
  axiosIntance
    .post(`/design/file`, { key: key, type: type }, { responseType: "blob" })
    .then((res) => {
      const fileName = type + "." + key.split(".")[1];

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([res.data]));
      link.download = fileName; // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setMessage("Error in fetching design data");
      setAlertOpen(true);
      setLoading(false);
    });
};

export {
  getAllDesignList,
  createNewDesign,
  updateDesign,
  deleteDesignById,
  restoreDesignById,
  getDesignById,
  getDesingFile,
};
