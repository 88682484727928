// Log filters

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { getAllVersionList } from "../../../api/version";
import { getAllBatchList } from "../../../api/batch";
import { getAllDesignList } from "../../../api/design";

export default function DeviceFilter({
  filterOpen,
  handleFilterClose,
  filterData,
  setFilterData,
  setFilterCount,
}) {
  // const [versionData, setVersionData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [designData, setDesignData] = useState([]);

  useEffect(() => {
    // getAllVersionList(setVersionData, "false");
    getAllDesignList(setDesignData, "false");
    getAllBatchList(setBatchData, "false");
  }, []);

  return (
    <>
      <Dialog
        open={filterOpen}
        onClose={handleFilterClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Filter Devices"}</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="normal"
            fullWidth
            label="Design"
            value={filterData.design}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                design: e.target.value,
              });
              // getAllVersionList(setVersionData, "false", e.target.value);
            }}
          >
            {designData &&
              designData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.design_id + " - " + option.title}
                </MenuItem>
              ))}
          </TextField>
          {/* <TextField
            select
            margin="normal"
            fullWidth
            label="Version"
            value={filterData.version}
            onChange={(e) => {
              setFilterData({ ...filterData, version: e.target.value });
            }}
          >
            {versionData &&
              versionData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.version_id}
                </MenuItem>
              ))}
          </TextField> */}
          <TextField
            select
            margin="normal"
            fullWidth
            label="Batch"
            value={filterData.batch}
            onChange={(e) => {
              setFilterData({ ...filterData, batch: e.target.value });
            }}
          >
            {batchData &&
              batchData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.batch_code + " x " + option.batch_name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            label="Show Assigned"
            name="Show_Assigned"
            select
            fullWidth
            margin="normal"
            value={filterData.assigned}
            onChange={(e) => {
              setFilterData({ ...filterData, assigned: e.target.value });
            }}
          >
            <MenuItem key={"false"} value={"false"}>
              NO
            </MenuItem>
            <MenuItem key={"true"} value={"true"}>
              YES
            </MenuItem>
          </TextField>
          {/* <TextField
            label="Is Deleted"
            name="Is Deleted"
            select
            fullWidth
            margin="normal"
            value={filterData.deleted}
            onChange={(e) => {
              setFilterData({ ...filterData, deleted: e.target.value });
            }}
          >
            <MenuItem key={"false"} value={"false"}>
              NO
            </MenuItem>
            <MenuItem key={"true"} value={"true"}>
              YES
            </MenuItem>
          </TextField> */}
          <TextField
  label="Created At"
  name="Created At"
  type="date"
  fullWidth
  margin="normal"
  value={filterData.createdAt}
  onChange={(e) => {
    setFilterData({ ...filterData, createdAt: e.target.value });
  }}
  InputLabelProps={{
    shrink: true,
  }}
/>

        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={(e) => {
              setFilterData({
                version: "",
                assigned: "",
                batch: "",
                design: "",
              });
              handleFilterClose();
              setFilterCount(0);
            }}
          >
            Reset Filter
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              handleFilterClose();
            }}
          >
            Set filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}