/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  Box,
  Collapse
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

import { fetchTestingStats, getTestingLogs } from "../../../../api/device";
import LogFilter from "./LogFilter";
import TestingStats from "./TestingStats";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Row(props) {
  const {
    row,
    setTestingStatsOpen,
    setTestingStatsDevicesOptions,
    setTestingCode,
    setTestingStatstime,
  } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
        <TableCell align="left">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.testing_code}
        </TableCell>

        <Tooltip arrow placement="top" title={row.devices.map((device) => device).join(", ")}>
          <TableCell>
            {row.devices.length > 3 ? row.devices.slice(0, 3).join(", ") + " ..." : row.devices.join(", ")}
          </TableCell>
        </Tooltip>
        <TableCell>
          <Button
            disabled={row.testings < 2 ? true : false}
            onClick={(e) => {
              setTestingStatsDevicesOptions(row.devices)
              setTestingStatstime(row.testings[1].createdAt)
              setTestingStatsOpen(true);
              setTestingCode(row.testing_code);
            }}
          >
            Get Stats
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginBottom: 5 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Topic</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.testings.map((testing) => (
                    <TableRow key={testing._id}>
                      <TableCell component="th" scope="row">
                        {testing.topic}
                      </TableCell>
                      <TableCell>{testing.action}</TableCell>
                      <TableCell>{new Date(testing.createdAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
const headers = [
  { id: "collapse", label: "" },
  { id: "code", label: "Code" },
  { id: "devices", label: "Devices" },
  { id: "stats", label: "Stats" },
];

const TestingLogs = ({
  setSeverity,
  setMessage,
  setAlertOpen,
  setFormOpen,
  setDeviceID,
  setUpdateForm,
  change,
  testingStatus,
  logsData,
  setLogsData,
  testingData,
  handleFormClose
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [LogsFilterOpen, setLogsFilterOpen] = useState(false);
  const [LogsfilterData, setLogsFilterData] = useState({
    start_time: "",
    end_time: "",
  });
  const [TestingStatsOpen, setTestingStatsOpen] = useState(false);
  const [TestingStatsDevices, setTestingStatsDevices] = useState([]);
  const [TestingStatsDevicesOptions, setTestingStatsDevicesOptions] = useState([]);
  const [TestingStatstime, setTestingStatstime] = useState();
  const [TestingCode, setTestingCode] = useState("");
  const handleTestingStatsClose = () => {
    setTestingStatsDevices([]);
    setTestingStatsOpen(false);
    setTestingCode("");
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleLogsFilterClose = (e) => {
    setLogsFilterOpen(false);
  }
  const fetchTestingLogs = async () => {
    setLoading(true);
    let filter = {
      start_time:
        LogsfilterData.start_time !== ""
          ? new Date(LogsfilterData.start_time).toISOString()
          : "",
      end_time:
        LogsfilterData.end_time !== ""
          ? new Date(LogsfilterData.end_time).toISOString()
          : "",
    };

    await getTestingLogs(
      page,
      rowsPerPage,
      filter,
      setLogsData,
      setTotal,
      setLoading,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  };
  const fetchdevicestats = async () => {
    const data = {
      selectedDevices: TestingStatsDevices,
      endTime: TestingStatstime,
      testing_code: TestingCode
    }
    await fetchTestingStats(data, setAlertOpen, setSeverity, setMessage, TestingCode);
  }
  useEffect(() => {
    fetchTestingLogs();
  }, [testingStatus, page, rowsPerPage, change, refresh]);
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "10px" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Testing Logs</Typography>
          <IconButton
            onClick={(e) => {
              setLogsFilterOpen(true);
            }}
          >
            <Tooltip title="Filters" arrow placement="top">
              <FilterList />
            </Tooltip>
          </IconButton>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {logsData.map((row) => {
                  return (
                    <Row
                      row={row}
                      setTestingStatsOpen={setTestingStatsOpen}
                      setTestingStatsDevicesOptions={setTestingStatsDevicesOptions}
                      setTestingCode={setTestingCode}
                      setTestingStatstime={setTestingStatstime}
                      key={row._id}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <LogFilter
          LogsFilterOpen={LogsFilterOpen}
          handleLogsFilterClose={handleLogsFilterClose}
          refresh={refresh}
          setRefresh={setRefresh}
          filterData={LogsfilterData}
          setFilterData={setLogsFilterData}
        />
        <TestingStats
          TestingStatsOpen={TestingStatsOpen}
          handleTestingStatsClose={handleTestingStatsClose}
          refresh={refresh}
          setRefresh={setRefresh}
          devices={TestingStatsDevicesOptions}
          selectedDevices={TestingStatsDevices}
          setselectedDevices={setTestingStatsDevices}
          fetchdevicestats={fetchdevicestats}
          TestingCode={TestingCode}
        />
      </Paper>

    </>
  );
};

export default TestingLogs;
