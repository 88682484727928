/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  assignDeviceToCompany,
  getUnAssignedDevices,
} from "../../../../../api/device";

export default function CreateDevice({
  formOpen,
  handleFormClose,
  setSeverity,
  setMessage,
  setAlertOpen,
  deviceId,
  setDeviceID,
  siteId,
  devicesId,
  setDevicesID
}) {
  const [devices, setDevices] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleChange = (event, value) => {
    setDevicesID(value.map(option => option._id));
  };

  const handleSubmit = () => {
    // code to register user
    setButtonLoading(true);
    assignDeviceToCompany(
      devicesId,
      siteId,
      setSeverity,
      setAlertOpen,
      setMessage,
      handleFormClose,
      setButtonLoading
    );
  };

  useEffect(() => {
    getUnAssignedDevices(
      setDevices,
      setButtonLoading,
      setSeverity,
      setAlertOpen,
      setMessage
    );
  }, []);

  return (
    <>
      <Dialog open={formOpen} onClose={handleFormClose}>
        <DialogTitle>Add New Device</DialogTitle>
        <DialogContent>
          {/* <TextField
            select
            margin="normal"
            fullWidth
            label="Device Id"
            value={deviceId}
            onChange={(e) => {
              console.log(e.target.value);
              setDeviceID(e.target.value);
            }}
          >
            {!loading &&
              devices.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.device_id}
                </MenuItem>
              ))}
          </TextField> */}
          <Autocomplete
            fullWidth
            style={{ paddingTop: "10px" }}
            multiple
            options={devices}
            getOptionLabel={(option) => option.device_id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Device Id"
                variant="outlined"
              />
            )}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          {buttonLoading ? <>Loading{" "}<CircularProgress
            color="inherit"
            size={20}
            style={{ marginLeft: "8px" }}
          /></> : <Button
            type="submit"
            variant="contained"
            color="primary"
            // sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit}
          >
            Assign Device{" "}
          </Button>}
        </DialogActions>
      </Dialog>

      {/* Block confirmation dialog */}
    </>
  );
}
