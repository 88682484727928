import React, { useState, useEffect } from "react";
import { Alert, Breadcrumbs, Divider, Snackbar } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import { useNavigate } from "react-router-dom";

import Loader from "../../components/Loader";
import {fetchKtUserRoles } from "../../api/role";
import KtUsersTable from "./elements/KtUsersTable";
import UpdateKtUser from "./elements/UpdateKtUser";
import DeleteKtUser from "./elements/DeleteKtUser";
import { deleteKtUser } from "../../api/ktUser";
import CreateModal from "./elements/CreateKtUser";
import { getAllCompanies } from "../../api/company";

const KtUser = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sites, setSites] = useState([]);
  const [ktUserId, setKtUserId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [deleteModal,setDeleteModal]= useState(false);
  const [deleteLoading,setDeleteLoading]= useState(false);

  const [ktUserData,setKtUserData]= useState({
    name:'',
    email:'',
    password:'',
    role:''
  });
  const [roles,setRoles]=useState([]);
  useEffect(()=>{
    fetchKtUserRoles(
      setRoles,
      "",
      "",
      "",
      "false",
      setLoading,
      setSeverity,
      setAlertOpen,
      setMessage
    )
    getAllCompanies(setSites,setLoading);
  },[]);
  const handleFormClose = () => {
    setCreateModal(false);
    setUpdateModal(false);
    setDeleteModal(false);
    setRefresh(!refresh);
  };
  const closeCreateModal=()=>{
    setCreateModal(false);
  }
  const closeUpdateModal=()=>{
    setUpdateModal(false);
  }
  const handleDeleteConfirm=()=>{
    setDeleteLoading(true);
    deleteKtUser(ktUserId,setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,setDeleteLoading)
  }
  const handleDeleteCancel=()=>{
    setDeleteModal(false);
  }
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
          <PageTitle>{"KT User"}</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>
            <ActiveLink>KT User</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <KtUsersTable
            setData={setData}
            data={data}
            setKtUserId={setKtUserId}
            setUpdateModal={setUpdateModal}
            setDeleteModal={setDeleteModal}
            loading={loading}
            setCreateModal={setCreateModal}
            refresh={refresh}
            setRefresh={setRefresh}
            fetch={fetch}
            setFetch={setFetch}
            setKtUserData={setKtUserData}
          />

          <CreateModal 
          createModal={createModal}
          closeCreateModal={closeCreateModal}
          handleFormClose={handleFormClose}
          roles={roles}
          setRoles={setRoles}
          sites={sites}
          />

          <UpdateKtUser 
          updateOpen={updateModal}
          handleUpdateClose={closeUpdateModal}
          handleFormClose={handleFormClose}
          ktUserData={ktUserData}
          setKtUserData={setKtUserData}
          ktUserId={ktUserId}
          roles={roles}
          sites={sites}
          />

          <DeleteKtUser 
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
          deleteOpen={deleteModal}
          deleteLoading={deleteLoading}
          handleDeleteConfirm={handleDeleteConfirm}
          handleDeleteCancel={handleDeleteCancel}
          />
        </>
      )}
    </div>
  );
};

export default KtUser;
