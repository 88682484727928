/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { Search as SearchIcon, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { planDetails } from "../../../../../api/company";
import { UserContext } from "../../../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "name", label: "Plan Name" },
  { id: "code", label: "Plan Code" },
  { id: "status", label: "Plan Status" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  {
    id: "assosiated_transaction",
    label: "Transaction",
  },
  { id: "Action", label: "Action", align: "center" },
];

const ViewPlans = ({
  siteId,
  setFormOpen,
  change,
  setAction,
  setExpiry,
  expiry,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { userData } = useContext(UserContext);

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  useEffect(() => {
    planDetails(siteId, setRows, page, rowsPerPage, setTotal,searchQuery);
  }, [change, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // useEffect(() => {
  //   // Filter data based on search query after a delay
  //   const timer = setTimeout(() => {
  //     const filtered = rows.filter((row) =>
  //       row.plan_code.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setFilteredData(filtered);
  //   }, 300); // Adjust the delay time as needed

  //   return () => clearTimeout(timer);
  // }, [searchQuery, rows]);
  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      planDetails(siteId, setRows, 0, rowsPerPage, setTotal,event.target.value);
    }, 1000);
    
  };
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Company Plans</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Plans"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {/* <Button onClick={fetchPlans} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
            {/* <IconButton>
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton> */}
            {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("ADD_SITE_PLAN"))&&<IconButton>
              <Tooltip title="Add Plans" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <Tooltip
                      title={`Users : ${row.users_allowed} 
                      Groups : ${row.groups_allowed}
                      `}
                      arrow
                      placement="top"
                    >
                      <TableCell component="th" scope="row">
                        {row.plan_name}
                      </TableCell>
                    </Tooltip>
                    <TableCell>{row.plan_code}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        sx={{
                          color:
                            row.plan_status === "active"
                              ? "green"
                              : row.plan_status === "upcoming"
                              ? "blue"
                              : "red",
                        }}
                      >
                        {row.plan_status.toUpperCase()}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {row.start_date === undefined ? (
                        "-"
                      ) : (
                        <>
                          {new Date(row.start_date).toLocaleDateString(
                            "en-IN",
                            {
                              timeZone: "Asia/Kolkata",
                            }
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {row.end_date === undefined ? (
                        "-"
                      ) : (
                        <>
                          {row.plan_status === "active" ? (
                            <>
                              <Button
                                color="warning"
                                onClick={() => {
                                  setExpiry({
                                    ...expiry,
                                    open: true,
                                    plan_id: row._id,
                                  });
                                }}
                              >
                                {new Date(row.end_date).toLocaleDateString(
                                  "en-IN",
                                  {
                                    timeZone: "Asia/Kolkata",
                                  }
                                )}
                              </Button>
                            </>
                          ) : (
                            <>
                              {new Date(row.end_date).toLocaleDateString(
                                "en-IN",
                                {
                                  timeZone: "Asia/Kolkata",
                                }
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.transaction_details.transaction_id}
                    </TableCell>

                    <TableCell align="center">
                      {row.plan_status === "active" ? (
                        <Button
                          color="error"
                          size="small"
                          variant="outlined"
                          disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("SITE_PLAN_ACTION"))}
                          onClick={() => {
                            setAction({
                              open: true,
                              type: "expire",
                              plan_id: row._id,
                            });
                          }}
                        >
                          Expire
                        </Button>
                      ) : (
                        <Button
                          disabled={row.plan_status === "expired"|| !(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("SITE_PLAN_ACTION"))}
                          size="small"
                          variant="outlined"
                          color="success"
                          onClick={() => {
                            setAction({
                              open: true,
                              type: "activate",
                              plan_id: row._id,
                            });
                          }}
                        >
                          Activate
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 15, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default ViewPlans;
