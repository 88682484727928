import React, { useState, useContext, useEffect } from 'react';
import {Menu,Button,Grid,Badge, CircularProgress, Card, Tooltip} from '@mui/material';
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { UserContext } from '../../context/UserContext';
import { fetchNotifications, marknotifications } from '../../api/notification';

const NotificationDropdown = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notification, setNotification] = useState([]);
  const [unreadNotification, setUnreadNotification] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const { userData } = useContext(UserContext);

  const fetchNextNotifications = async (page) => {
    setPage(page);
    fetchNotifications(userData.user._id, page, setNotification,setUnreadNotification,setHasMore);
  }

  useEffect(()=>{
    fetchNextNotifications(page);
  },[])

  return (
    <React.Fragment>
      <Button
        onClick={() => {setMenuOpen(!menuOpen);marknotifications(userData.user._id,page);}}
        className="btn header-item noti-icon waves-effect"
        id="page-header-notifications-dropdown"
        style={{cursor:"pointer"}}
      >
        <i className="ri-notification-3-line"></i>
        <Tooltip title="Notifications">
          <Badge
            badgeContent={unreadNotification.toString()}
            style={{
              color: `${window.innerWidth < 720 ? "white" : "#000000"}`,
              right: "5px",
            }}
          >
            <NotificationsNoneIcon
              style={{
                cursor: "pointer",
                color: `${window.innerWidth < 720 ? "white" : "#213240"}`,
              }}
            />
          </Badge>
        </Tooltip>
      </Button>
      <Menu
        anchorEl={document.getElementById('page-header-notifications-dropdown')}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        PaperProps={{
          style: {
            minWidth: '350px',
          },
        }}
      >
        {/* <div className="p-3">
          <Grid container alignItems="center">
            <Grid item>
              <h6 className="m-0">
                Notifications
              </h6>
            </Grid>
          </Grid>
        </div> */}
        {menuOpen && (
          <InfiniteScroll
            dataLength={notification.length}
            next={() => fetchNextNotifications(page + 1)}
            hasMore={hasMore}
            loader={
              <div className="text-center py-5">
                <CircularProgress color='primary' height={60} width={60}/>
              </div>
            }
            height={350}
            endMessage={
              <div className="text-center py-3" style={{textAlign:"center"}}>
                <p className="lead">No more details right now</p>
              </div>
            }
          >
            {notification.map((item) => {
              console.log(item);
              return(
                <Card key={item._id}>
                  <Grid container>
                    <Grid item xs>
                    <div className="d-flex justify-content-between align-items-center" style={{padding:"0px 10px"}}>
                        <h4 style={{margin:"5px 0px"}}>
                          {item.notification_data.title}
                        </h4>
                      </div>
                      <div className="font-size-12 text-muted" style={{padding:"0px 10px"}}>
                        <p style={{margin:"0px"}}>{item.notification_data.body}</p>
                        <p style={{textAlign:"end",margin:"0px"}}>
                          <i className="mdi mdi-clock-outline"></i> {moment(new Date(item.createdAt)).fromNow()}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
            )})}
          </InfiniteScroll>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default NotificationDropdown;
