import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";

export default function FeatureFilter({
  filterOpen,
  handleFilterClose,
  filterData,
  setFilterData,
}) {
  return (
    <>
      <Dialog
        open={filterOpen}
        onClose={handleFilterClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Filter Features"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Is Deleted"
            name="Is Deleted"
            select
            fullWidth
            margin="normal"
            value={filterData.deleted}
            onChange={(e) => {
              setFilterData({ ...filterData, deleted: e.target.value });
            }}
          >
            <MenuItem key={"false"} value={false}>
              NO
            </MenuItem>
            <MenuItem key={"true"} value={true}>
              YES
            </MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={(e) => {
              setFilterData({
                deleted: false,
                createdAt: "",
              });
              handleFilterClose();
            }}
          >
            Reset Filter
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              handleFilterClose();
            }}
          >
            Set filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
