// All the plan functionalities

import { Alert, Snackbar } from "@mui/material";
import React, { useState } from "react";

import ViewPlans from "./elements/ViewPlans";
import AddPlan from "./elements/AddPlan";
import UpdatePlanStatus from "./elements/UpdatePlanStatus";
import UpdateExpiry from "./elements/UpdateExpiry";
import { useContext } from "react";
import { UserContext } from "../../../../context/UserContext";

export default function PlansDashboard({ siteId, userData }) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [change, setChange] = useState(false);
  const  user  = useContext(UserContext).userData;
  const [action, setAction] = useState({
    open: false,
    type: "",
    plan_id: "",
  });
  const [expiry, setExpiry] = useState({
    days: 1,
    add: false,
    open: false,
    plan_id: "",
  });

  const [upcoming, setUpcoming] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setChange(!change);
    setExpiry({ days: 1, add: false, open: false, plan_id: "" });
    setAction({
      open: false,
      type: "",
      plan_id: "",
    });
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {(user?.user?.isSuperAdmin===true||user?.user?.role?.permissions.includes("FETCH_SITE_PLANS"))&&<ViewPlans
        siteId={siteId}
        setAction={setAction}
        setExpiry={setExpiry}
        expiry={expiry}
        setFormOpen={setFormOpen}
        change={change}
      />}
      {formOpen && (
        <AddPlan
          formOpen={formOpen}
          handleFormClose={handleFormClose}
          siteId={siteId}
          userData={userData}
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
        />
      )}

      {action.open && (
        <UpdatePlanStatus
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
          upcoming={upcoming}
          setUpcoming={setUpcoming}
          setAction={setAction}
          action={action}
          handleFormClose={handleFormClose}
        />
      )}
      {expiry.open && (
        <UpdateExpiry
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
          setExpiry={setExpiry}
          expiry={expiry}
          handleFormClose={handleFormClose}
        />
      )}
    </>
  );
}
