import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Divider,
  Grid,
} from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import StatCards from "./elements/StatCards";
import { getCompanyData } from "../../api/company";
import TransactionTable from "./elements/TransactionTable";
import GroupDashboard from "./elements/group";
import DeviceDashboard from "./elements/device";
import UserDashboard from "./elements/users";
import RoleDashboard from "./elements/role";
import PlansDashboard from "./elements/plans";
import { UserContext } from "../../context/UserContext";

const CompanyDashboard = () => {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState();
  const [deviceData, setDeviceData] = useState(false);
  const [groupData, setGroupData] = useState(false);
  const [userData, setUserData] = useState(false);
  const [roleData, setRoleData] = useState(false);
  const { user } = useContext(UserContext).userData;

  useEffect(() => {
    getCompanyData(siteId, setCompanyData, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageTitle>{companyData.company_name}</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>
            <PathLink
              underline="hover"
              onClick={() => navigate("/dashboard/site")}
            >
              Site
            </PathLink>
            <ActiveLink>{companyData.company_code}</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          {companyData.is_deleted && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              <AlertTitle>Warning</AlertTitle>
              This site is deleted site. If you want to do any operations on the
              site, please restore it first!
            </Alert>
          )}

          <StatCards
            deviceCount={deviceData}
            userCount={userData}
            roleCount={roleData}
            groupCount={groupData}
          />
          <Grid container spacing={3} style={{ marginTop: 8 }}>
            {/* Devices Table */}
            <Grid item xs={12}>
              <DeviceDashboard
                commonData={deviceData}
                setCommonData={setDeviceData}
              />
            </Grid>

            {/* Users Table */}
            {(user?.isSuperAdmin===true||user?.role?.permissions.includes("FETCH_SITE_USERS"))&&<Grid item xs={12} md={user?.isSuperAdmin===true||user?.role?.permissions.includes("FETCH_SITE_ROLES")?7:12}>
              <UserDashboard
                commonData={userData}
                setCommonData={setUserData}
                groupData={groupData}
                roleData={roleData}
              />
            </Grid>}

            {/* Roles Table */}
            {(user?.isSuperAdmin===true||user?.role?.permissions.includes("FETCH_SITE_ROLES"))&&<Grid item xs={12} md={(user?.isSuperAdmin===true||user?.role?.permissions.includes("FETCH_SITE_USERS"))?5:12} style={{ paddingLeft: "24px" }}>
              <RoleDashboard
                commonData={roleData}
                setCommonData={setRoleData}
              />
            </Grid>}

            {/* Groups Table */}
            <Grid item xs={12}>
              <GroupDashboard
                setCommonData={setGroupData}
                commonData={groupData}
                deviceData={deviceData}
              />
            </Grid>

            {/* Company Plans table */}
            <Grid item xs={12}>
              <PlansDashboard siteId={siteId} userData={userData} />
            </Grid>

            {/* Company Transactions table */}
            {(user?.isSuperAdmin===true||user?.role?.permissions.includes("FETCH_SITE_TRANSACTIONS"))&&<Grid item xs={12}>
              <TransactionTable siteId={siteId} />
            </Grid>}
          </Grid>
        </>
      )}
    </div>
  );
};

export default CompanyDashboard;
