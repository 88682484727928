/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Grid,
  Snackbar,
  Alert,
  Button,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { Cached, Download, FilterList } from "@mui/icons-material";
import { getDeviceLogs } from "../../../api/device";
import { CSVLink } from "react-csv";

const headers = [
  { key: "user_name", label: "User Name", align: "left" },
  { key: "user_id", label: "Phone number", align: "left" },
  { key: "action", label: "Topic", align: "left" },
  { key: "value_published", label: "Value Published", align: "left" },
  { key: "metadata", label: "Meta Data", align: "left" },
  { key: "createdAt", label: "Created At", align: "left" },
];

const LogsTable = ({
  deviceData,
  loading,
  setFilterOpen,
  refresh,
  setRefresh,
  formState,
  filterData,
  setFormState,
  setFilterData,
  companyData,
  deviceLoad,
  selectedCompanies,
  setSelectedCompanies,
  fetch,
  setFetch,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [alertOpen, setAlertOpen] = useState(false);
  const [logData, setLogData] = useState([]);
  const [severity, setSeverity] = useState();
  const [deviceId, setDeviceId] = useState();
  const [message, setMessage] = useState();
  const [total, setTotal] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const navigate= useNavigate();
  const {device_id}= useParams();
  useEffect(()=>{
  if(device_id){
    setDeviceId(device_id);
  }    
},[device_id])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchDeviceLogs = async () => {
    setTableLoading(true);
    let filter = {
      action: formState.action,
      start_time:
        filterData.start_time !== ""
          ? new Date(filterData.start_time).toISOString()
          : "",
      end_time:
        filterData.end_time !== ""
          ? new Date(filterData.end_time).toISOString()
          : "",
    };

    await getDeviceLogs(
      deviceId,
      page,
      rowsPerPage,
      filter,
      setLogData,
      setTotal,
      setTableLoading,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  };

  useEffect(() => {
    if (deviceId !== undefined) {
      fetchDeviceLogs();
      navigate(`/dashboard/logs/${deviceId}`);
    }
  }, [deviceId, page, rowsPerPage, refresh]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  let sel_device = deviceData.filter((item) => item._id === deviceId);
  let ld = [];
  logData.forEach((element) => {
    ld.push({
      ...element,
      createdAt: new Date(element.createdAt).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      }),
    });
  });

  const csvReport = {
    filename: `Logs-${sel_device[0]?.device_id}.csv`,
    headers: headers,
    data: ld,
  };

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          {!loading && (
            <>
              <Autocomplete
                multiple
                id="companies"
                limitTags={2}
                options={companyData}
                getOptionLabel={(option) => option.company_name}
                value={selectedCompanies}
                onChange={(e, value) => {
                  // let dt = [];

                  setSelectedCompanies(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Site"
                    placeholder="Select Mulitple sites"
                    helperText="Optional"
                  />
                )}
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  setFetch(!fetch);
                }}
              >
                Get Company Device List
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {deviceLoad ? (
            <TextField
              label="Devices"
              value={"Loading Data.."}
              disabled
              fullWidth
            />
          ) : (
            <Autocomplete
              id="devices"
              limitTags={2}
              options={deviceData}
              value={deviceId?deviceData.filter((device)=>device._id===deviceId)[0]:null}
              getOptionLabel={(option) => option.device_id+"- "+option.device_description}
              onChange={(e, value) => {
                setDeviceId(value._id);
                setPage(0);
                setFilterData({ end_time: "", start_time: "" });
                setFormState({ action: [] });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Devices"
                  required
                  helperText="Select one Device"
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>

        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
          }}
        >
          <Typography variant="h6">Logs</Typography>
          <div style={{ display: "flex", alignItems: "left" }}>
            {logData.length !== 0 && (
              <>
                <CSVLink
                  style={{ color: "green", textDecoration: "none" }}
                  {...csvReport}
                >
                  <Button
                    variant="outlined"
                    sx={{ ml: 2, mr: 2 }}
                    endIcon={<Download />}
                  >
                    CSV
                  </Button>
                </CSVLink>
              </>
            )}
            <IconButton
              onClick={(e) => {
                setFilterOpen(true);
              }}
            >
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(e) => {
                setPage(0);
                setRefresh(!refresh);
              }}
            >
              <Tooltip title="Refresh" arrow placement="top">
                <Cached />
              </Tooltip>
            </IconButton>
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableLoading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {logData.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell align="left" >
                        {row.user_name !== undefined
                          ? row.user_name
                          : row.user_id}
                      </TableCell>
                      <TableCell align="left">{row.user_id}</TableCell>
                      <TableCell align="left">{row.action}</TableCell>
                      <TableCell align="left">{row.value_published}</TableCell>
                      <TableCell align="left">{row.metadata}</TableCell>
                      <TableCell align="left">
                        {new Date(row.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 75, 250]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Create new device dialog */}
    </>
  );
};

export default LogsTable;
