/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Alert, Breadcrumbs, Divider, Grid, Snackbar } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import { getDesignById } from "../../api/design";
import StatCards from "./elements/StatCards";
import VersionDashboard from "./elements/version";
import DesignLog from "./elements/DesignLog";
import DownloadFile from "./elements/DownloadFile";
import { UserContext } from "../../context/UserContext";

const DesignDashboard = () => {
  const navigate = useNavigate();
  const { designId } = useParams();
  const [loading, setLoading] = useState(true);
  const [designData, setDesignData] = useState();
  const [count, setCount] = useState({
    versionCount: 0,
    deviceCount: 0,
  });
  const [severity, setSeverity] = useState();
  const { userData } = useContext(UserContext);
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getDesignById(
      designId,
      setCount,
      setDesignData,
      setLoading,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  }, []);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <PageTitle>{designData?.title}</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>
            <PathLink
              underline="hover"
              onClick={() => navigate("/dashboard/design")}
            >
              Design
            </PathLink>
            <ActiveLink>{designData?.design_id}</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />

          <StatCards
            deviceCount={count.deviceCount}
            versionCount={count.versionCount}
          />
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />

          <DownloadFile
            designData={designData}
            setAlertOpen={setAlertOpen}
            setSeverity={setSeverity}
            setMessage={setMessage}
          />
          <Grid container spacing={3} style={{ marginTop: 8 }}>
            {/* Devices Table */}
            <Grid item xs={12}>
              <VersionDashboard />
            </Grid>
            {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DESIGN_LOGS"))&&<Grid item xs={12}>
              <DesignLog logData={designData.update_logs} />
            </Grid>}
          </Grid>
        </>
      )}
    </div>
  );
};

export default DesignDashboard;
