import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
  } from "@mui/material";
  import React from "react";
  
  export default function UpcomingPlanDialog({ upcomingPlanDialog,upcomingPlan,handleFormClose }) {
    
    return (
      <Dialog
        open={upcomingPlanDialog}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Upcoming Plan Details
        </DialogTitle>
        <DialogContent>
            {upcomingPlan!=null?
          <Typography>
            Plan Name: {upcomingPlan.plan_name}
            <br />
            Plan Code: {upcomingPlan.plan_code} 
            {/* <br />
            Purchased At: {new Date(upcomingPlan.createdAt).toLocaleDateString('en-IN')}  */}
          </Typography>:<Typography>
            No Upcoming Plan bought.
          </Typography>}
        </DialogContent>
      </Dialog>
    );
  }
  