/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
  Chip,
} from "@mui/material";
import { Search as SearchIcon, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { getUserCompany } from "../../../../../api/user";
import { UserContext } from "../../../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "name", label: "Name" },
  { id: "phone_no", label: "Phone Number" },
  { id: "groups", label: "Groups", align: "center" },
  { id: "role", label: "Role", align: "left" },
  { id: "created_by", label: "Created by", align: "right" },
  { id: "updated_by", label: "Updated By", align: "right" },
  { id: "Action", label: "Action", align: "right" },
  { id: "remove", label: "Remove", align: "center" },
];

const ViewUsers = ({
  siteId,
  setSeverity,
  setMessage,
  setAlertOpen,
  setFormOpen,
  setUpdate,
  setUserId,
  change,
  setCommonData,
  commonData,
  setUserData,
  userData,
  groupData,
  roleData,
  setFormState,
  handleFormClose,
  setdeleteForm
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(UserContext).userData;

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    getUserCompany(
      siteId,
      setData,
      setLoading,
      page,
      rowsPerPage,
      "true",
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      "",
      "",
      searchQuery
    );
  }, [page, rowsPerPage, change]);

  useEffect(() => {
    setLoading(true);
    getUserCompany(
      siteId,
      "",
      setLoading,
      "",
      "",
      "false" < "",
      "",
      "",
      "",
      "",
      setCommonData,
      commonData,
      searchQuery
    );
  }, [change]);

  // useEffect(() => {
  //   // Filter data based on search query after a delay
  //   const timer = setTimeout(() => {
  //     const filtered = data.filter((row) =>
  //       row.full_name.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setFilteredData(filtered);
  //   }, 300); // Adjust the delay time as needed

  //   return () => clearTimeout(timer);
  // }, [searchQuery, data]);
  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getUserCompany(
        siteId,
        setData,
        setLoading,
        0,
        rowsPerPage,
        "true",
        setTotal,
        setSeverity,
        setAlertOpen,
        setMessage,
        "",
        "",
        event.target.value
      );
    }, 1000);
  };

  return (
    <>
      <Paper sx={{ width: "95%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Users</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Users"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {/* <Button onClick={fetchUsers} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
            {/* <IconButton>
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton> */}
            {((user?.isSuperAdmin === true || user?.role?.permissions.includes("ADD_SITE_USER"))) && <IconButton>
              <Tooltip title="Add User" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.userData._id}>
                      <TableCell component="th" scope="row" key={row.userData.full_name}>
                        {row.userData.full_name}
                      </TableCell>
                      <TableCell key={row.userData.phone_no}>{row.userData.phone_no}</TableCell>
                      <TableCell align="center">
                        {row.groupData?.length === 0 && "--"}
                        {row.groupData.map((ele) => {
                          return (
                            <Chip key={ele.group_name} label={ele.group_name} variant="outlined" />
                          );
                        })}
                      </TableCell>
                      <TableCell align="left">
                        {(roleData &&
                          roleData.roleData.find(
                            (role) => role._id === row.role_id
                          ) !== undefined &&
                          roleData.roleData.find(
                            (role) => role._id === row.role_id
                          ).role_name) ||
                          "--"}
                      </TableCell>
                      <TableCell align="right">{row.userData.created_by}</TableCell>
                      <TableCell
                        align={row.userData.last_updated_by ? "right" : "center"}
                      >
                        <Tooltip
                          arrow
                          placement="top"
                          title={new Date(row.userData.updatedAt).toLocaleString(
                            "en-IN",
                            {
                              timeZone: "Asia/Kolkata",
                            }
                          )}
                        >
                          <span>{row.userData.last_updated_by || "--"}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          disabled={!(user?.isSuperAdmin === true || user?.role?.permissions.includes("UPDATE_SITE_USER"))}
                          onClick={() => {
                            setUserData({
                              ...userData,
                              role_id: row.role_id,
                              phone_no: row.userData.phone_no,
                              full_name: row.userData.full_name,
                            });
                            setFormState((formState) => ({
                              ...formState,
                              groupArray: row.group_array,
                            }));
                            setUpdate(true);
                            setUserId(row.user_id);
                            setFormOpen(true);
                          }}
                        >
                          Update
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          disabled={!(user?.isSuperAdmin === true || user?.role?.permissions.includes("DELETE_SITE_USER"))}
                          color="error"
                          onClick={() => {
                            setdeleteForm(true);
                            setUserId(row.user_id);
                          }
                          }
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default ViewUsers;
