import React, { useState } from "react";
import { Alert, Breadcrumbs, Divider, Snackbar } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import UserTable from "./elements/UserTable";
import UserUpdateDialog from "./elements/UserUpdateDialog";
import UserFilter from "./elements/FilterUser";

const Users = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();

  const [reload, setReload] = useState(false);

  const handleClose = () => {
    setAlertOpen(false);
  };
  const [update, setUpdate] = useState(false);
  const [userid, setUserid] = useState("");
  const [userData, setUserData] = useState();
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    deleted: false,
  });

  const handleFormClose=()=>{
    setUpdate(false);
    setReload(!reload);
    setFilterOpen(false);
  }

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <PageTitle>Users</PageTitle>
      <Breadcrumbs aria-label="breadcrumb">
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Users</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      <UserTable
        setMessage={setMessage}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        reload={reload}
        setReload={setReload}
        setUpdate={setUpdate}
        setUserid={setUserid}
        setUserData={setUserData}
        setFilterOpen={setFilterOpen}
        filterData={filterData}
      />
      {update && <UserUpdateDialog 
      formOpen={update}
      handleFormClose={handleFormClose}
      setAlertOpen={setAlertOpen}
      setSeverity={setSeverity}
      setMessage={setMessage}
      userData={userData}
      setUserData={setUserData}
      userId={userid}
      />}
      <UserFilter 
      filterOpen={filterOpen}
      handleFilterClose={handleFormClose}
      filterData={filterData}
      setFilterData={setFilterData}
      />
    </>
  );
};

export default Users;
