// Testing Stats
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox
  } from "@mui/material";
  import React from "react";
  
  
  export default function TestingStats({
    TestingStatsOpen,
    handleTestingStatsClose,
    refresh,
    setRefresh,
    devices,
    selectedDevices,
    setselectedDevices,
    fetchdevicestats
  }) {
  const handledeviceselection=(e,device_id)=>{
    if(e.target.checked){
        setselectedDevices((prev)=>
          [...prev,device_id]
        )
        }
    else{
        setselectedDevices((prev) => prev.filter((id) => id !== device_id));
    }
  }
//   console.log(devices);
    return (
      <>
        <Dialog
          open={TestingStatsOpen}
          onClose={handleTestingStatsClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Device Testing Stats"}</DialogTitle>
          <DialogContent style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '8px' }}>
            {devices.map((device)=>{
                return <div key={device}>
                <Checkbox checked={selectedDevices.includes(device)} onChange={(e)=>handledeviceselection(e,device)}/><span>{device}</span>
                </div>
            })}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                fetchdevicestats();
                handleTestingStatsClose();
              }}
            >
              Get Stats
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  