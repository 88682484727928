import React, { useState } from "react";
import { Alert, Breadcrumbs, Divider, Snackbar } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import DesignTable from "./elements/DesignTable";
import CreateDesign from "./elements/CreateDesign";
import UpdateDesign from "./elements/UpdateDesign";
import DesignFilter from "./elements/FilterDesign";

const Designs = () => {
  const [reload, setReload] = React.useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const [attachment, setAttachment] = useState({
    gerber_file: null,
    code_file: null,
    schematics_file: null,
  });
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    deleted:false,
    createdAt:""
  });
  const [filterCount, setFilterCount] = useState(0);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setReload(!reload);
    setDesignData({
      design_id: "",
      description: "",
      title: "",
    });
    setAttachment({
      gerber_file: null,
      code_file: null,
      schematics_file: null,
    });
    setUpdateOpen(false);
    setFilterOpen(false);
    let count = 0;
    if (filterData.delete !== "") {
      count = count + 1;
    }
    if (filterData.createdAt !== "") {
      count = count + 1;
    }
    setFilterCount(count);
  };
  const [designData, setDesignData] = useState({
    design_id: "",
    description: "",
    _id: "",
    title: "",
  });

  const [updateData, setUpdateData] = useState({
    design_id: "",
    description: "",
    title: "",
    update_message: "",
    _id: "",
  });

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <PageTitle>Device Design</PageTitle>
      <Breadcrumbs aria-label="breadcrumb">
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Designs</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <DesignTable
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        reload={reload}
        setFormOpen={setFormOpen}
        updateData={updateData}
        setUpdateData={setUpdateData}
        setUpdateOpen={setUpdateOpen}
        setReload={setReload}
        filterCount={filterCount}
        setFilterOpen={setFilterOpen}
        filterData={filterData}
      />
      <CreateDesign
        dialog={formOpen}
        handleClose={handleFormClose}
        designData={designData}
        setDesignData={setDesignData}
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        attachment={attachment}
        setAttachment={setAttachment}
      />

      <UpdateDesign
        dialog={updateOpen}
        handleClose={handleFormClose}
        designData={updateData}
        setDesignData={setUpdateData}
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        attachment={attachment}
        setAttachment={setAttachment}
      />
      {filterOpen && (
        <DesignFilter
          filterOpen={filterOpen}
          handleFilterClose={handleFormClose}
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterCount={setFilterCount}
        />
      )}
    </>
  );
};

export default Designs;
