import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  color: rgb(33, 50, 64);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Typography)`
  margin: 0px;
  color: rgb(144, 174, 198);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

export { PageTitle, PathLink, ActiveLink };
