/*jshint esversion: 11 */
import React, { useState } from 'react';
import { Dialog, Button, Alert, TextField, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { CircularProgress, Snackbar } from '@mui/material';
import { createFeature } from '../../../api/feature';

const CreateModal = ({
    createModal,
    closeCreateModal,
    handleFormClose,
    features,
    setFeatures
}) => {
    const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [featureData, setFeatureData] = useState({
    name: '',
    description: '',
  });
  const [CreateRequestLoading, setCreateRequestLoading] =
    useState(false);

  // useEffect(() => {
  //     fetchAvailablePermissions();
  //   // eslint-disable-next-line
  // }, [createRoleModal]);

  // const fetchAvailablePermissions = async () => {
  //   setPermissionListLoading(true);
  //   fetchSuperAdminPermission(setAvailablePermissions,setPermissionListLoading,setSeverity,setAlertOpen,setMessage,);
  // };

//   function buildSelectionInput(permissionMap) {
//     const permissionInputMap = new Map();
//     Object.keys(permissionMap)
//       .flatMap((k) => permissionMap[k])
//       .forEach((t) => {
//         permissionInputMap.set(t, false);
//       });
//     return Object.fromEntries(permissionInputMap);
//   }

  async function handleFeatureCreation(event) {
    setCreateRequestLoading(true);
    await createFeature(
        featureData,
        setSeverity,
        setMessage,
        setAlertOpen,
        handleFormClose,
        setCreateRequestLoading
    );
    setFeatureData({
      name: '',
    description: '',
    })
  }
  const handleAlertClose=()=>{
    setAlertOpen(false);
  }
  return (
    <React.Fragment>
      <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
        <Dialog open={createModal} onClose={closeCreateModal} fullWidth maxWidth="md">
        <DialogTitle>Create New Feature</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            value={featureData.name}
            name="Name"
            onChange={(e) => {
              setFeatureData({ ...featureData, name: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            value={featureData.description}
            name="Description"
            onChange={(e) => {
              setFeatureData({ ...featureData, description: e.target.value });
            }}
          />

        </DialogContent>
        <DialogActions style={{ padding: "16px 28px" }}>
          <Button
            type="submit"
            variant="outlined"
            onClick={handleFeatureCreation}
            color="secondary"
          >
            {CreateRequestLoading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create Feature"}
          </Button>
        </DialogActions>
        
    </Dialog>
    </React.Fragment>
  );
};

export default CreateModal;
