/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Breadcrumbs,
  Divider,
} from "@mui/material";
import mqtt from "precompiled-mqtt";
import React, { useEffect } from "react";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import { useState } from "react";
import { getDeviceStats } from "../../api/device";
import PlanExpiry from "./elements/PlanExpiry/PlanExpiryTable";
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [clientLoad, setClientLoad] = React.useState(true);
  const [reconnect, setReconnect] = useState(false);
  const [client, setClient] = useState();
  const [idInterval, setIdInterval] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [registered,setregistered]=useState(false);
  // function connectClient() {
  //   const token = window.localStorage.getItem("auth-token");
  //   const options = {
  //     retain: false,
  //     qos: 1,
  //     username: "superAdmin",
  //     password: token,
  //   };
  //   const client = mqtt.connect(
  //     `wss:${process.env.REACT_APP_MQTT_BROKER_URL}`,
  //     options
  //   );
  //   setClient(client);
  //   return client;
  // }
  // useEffect(() => {
  //    getDeviceStats(
  //     setDevices,
  //     setLoading
  //   );

  //   connectClient();
        
  // }, []);
  // useEffect(() => {
  //   connectClient();
  // }, []);
  // function changestate(deviceId,status){
  //   setLoading(true);
  //         const device= devices.find((device)=>device.device_id===deviceId);
  //         // if(!device){setLoading(false); return;}
  //         // console.log(status);
  //         // console.log(device.status);
  //         if (status === "online") {
  //           // console.log("here offline");
            
  //           const updatedDevices = devices.map((d) => {
  //             if (d.device_id === deviceId) {return {_id:d._id, device_id:d.device_id, status: "online" };}
  //             return d;
  //         });
  //         // console.log(updatedDevices);
  //         setDevices(updatedDevices);
  //         // setonlinedevicescount(onlinedevicescount+1);
  //         //   setofflinedevicescount(offlinedevicescount-1);
  //         }
  //         if (status === "offline") {
  //           // console.log("here online");
            
  //           const updatedDevices = devices.map((d) => {
  //             if (d.device_id === deviceId) {return {_id:d._id,device_id:d.device_id, status: "offline" };}
  //             return d;
  //         });
  //         // console.log(updatedDevices);
  //         setDevices(updatedDevices);
  //         // setofflinedevicescount(offlinedevicescount+1);
  //         //   setonlinedevicescount(onlinedevicescount-1);
  //         }
  //         setLoading(false);
  // }
  // useEffect(() => {
  //   if (client !== undefined) {
  //     client.on("connect", function () {
  //       console.log("CLIENT CONNECTED");
  //       client.subscribe("+/status");
        
  //       setReconnect(false);
  //       setClientLoad(false);
  //       clearInterval(idInterval);
  //       setIsConnected(true);
  //     });

  //     client.on("error", (err) => {
  //       console.log("Connection error: ", err);
  //       console.log("NOT CONNECTED");
  //       // window.location.reload();
  //     });
  //     client.on("offline", () => {
  //       console.log("OFFLINE");
  //     });
  //   }
  // }, [client]);
  // useEffect(()=>{
  //   if(client!==undefined&&registered===false){
  //     client.on("message", async(topic, message) => {
  //       const deviceId = topic.split("/")[0];
  //       // console.log(deviceId);
  //       const parsed= JSON.parse(message.toString());
  //       const status = parsed.data;
  //         // setLoading(true);
  //       changestate(deviceId,status);
  //     });
  //     setregistered(true);
  //   }
  // },[devices])
  // useEffect(() => {
  //   if (client !== undefined) {
  //     client.on("reconnect", () => {
  //       if (clientLoad) {
  //         console.log("ATTEMPTING INITIAL RECONNECTION");
  //         connectClient();
  //       } else {
  //         console.log("RECONNECTING");
  //         setReconnect(true);
  //       }
  //     });
  //   }
  // }, [client]);
//   useEffect(()=>{
//     if(client){

// }
//   },[client]);
// const getofflinecount=()=>{
//   let offlinecount=0;
//   devices.map((device)=>{
//     if(device.status==="offline"){
//       offlinecount++;
//     }
//   })
//   return offlinecount;
// }
// const getonlinecount=()=>{
//   let onlinecount=0;
//   devices.map((device)=>{
//     if(device.status==="online"){
//       onlinecount++;
//     }
//   })
//   return onlinecount;
// }
  return (
    <div>
      <PageTitle>Dashboard</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" }, mt: 1 }}
      >
        <PathLink underline="hover" href="/">
          Dashboard
        </PathLink>
        <ActiveLink>Home</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      {/* {loading ? (
        <Loader />
      ) : (
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} display="flex" justifyContent="space-between"> */}
              {/* Device Mapping Here */}
              {/* <Group header="Offline Devices" count={getofflinecount()}>
                <ul>
                  {devices.map((device) => {
                    return device.status=="offline"?<li>{device.device_id}</li>:null;
                  })}
                </ul>
              </Group>
              <Group header="Online Devices" count={getonlinecount()}>
                <ul>
                  {devices.map((device) => {
                    return device.status=="online"?<li>{device.device_id}</li>:null;
                  })}
                </ul>
              </Group>
            </Grid>
          </Grid>
        </Box>
      )} */}
      <PlanExpiry 
      loading={loading}
      setLoading={setLoading}
      />
    </div>
  );
};

export default Dashboard;
