import axiosIntance from "../helper/axios";

const fetchNotifications = (user_id,page, setNotifications, setUnreadNotification,setHasMore) => {
  axiosIntance
    .get(`/notification/all/${user_id}?page=${page}&limit=5`)
    .then((res) => {
      setNotifications((prevData)=>[...prevData,...res.data.results]);
      setHasMore(res.data.metadata.page < res.data.metadata.total_pages);
      setUnreadNotification(res.data.metadata.unread_count);
      // marknotifications(user_id,page);
    })
    .catch((err) => {
      console.log(err.response.data.error);
    });
};

const marknotifications = (user_id,page) => {
  axiosIntance
    .put(`/notification/mark/${user_id}`)
    .then((res) => {})
    .catch((err) => {
      // console.log(err.response.data.error);
    });
};

export {
  fetchNotifications,
  marknotifications,
};
