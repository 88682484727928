import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { getDesingFile } from "../../../api/design";

const DownloadFile = ({
  designData,
  setAlertOpen,
  setSeverity,
  setMessage,
}) => {
  const [loading, setLoading] = useState(false);
  // console.log(designData);
  const downloadFile = (key, type) => {
    getDesingFile(key, type, setLoading, setAlertOpen, setSeverity, setMessage);
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h4" sx={{ color: "#8c8c8c" }}>
                {loading ? "Loading..." : "Gerber File"}
              </Typography>
            </Box>
            <Tooltip title="Download file">
              <IconButton
                onClick={(e) => {
                  console.log("Download file");
                  downloadFile(designData.gerber_file.key, "gerber");
                }}
                disabled={loading}
              >
                <Download
                  sx={{ fontSize: 40, color: "#90aec6", opacity: 0.6 }}
                />
              </IconButton>
            </Tooltip>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h4" sx={{ color: "#8c8c8c" }}>
                {loading ? "Loading..." : "Schematic File"}{" "}
              </Typography>
            </Box>
            <Tooltip title="Download file">
              <IconButton
                onClick={(e) => {
                  downloadFile(designData.schematic_file.key, "schematic");
                }}
                disabled={loading}
              >
                <Download
                  sx={{ fontSize: 40, color: "#90aec6", opacity: 0.6 }}
                />
              </IconButton>
            </Tooltip>{" "}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h4" sx={{ color: "#8c8c8c" }}>
                {loading ? "Loading..." : "Code File"}{" "}
              </Typography>
            </Box>
            <Tooltip title="Download file">
              <IconButton
                onClick={(e) => {
                  downloadFile(designData.code_file.key, "code");
                }}
                disabled={loading}
              >
                <Download
                  sx={{ fontSize: 40, color: "#90aec6", opacity: 0.6 }}
                />
              </IconButton>
            </Tooltip>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DownloadFile;
