/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Button,
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  MenuItem,
  DialogActions,
} from "@mui/material";
import { Search as SearchIcon, FilterList, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  getDeviceSignalStrength,
  getDevicesOfCompany,
  resetDevice,
  simDetails,
  updateDeviceState,
} from "../../../../../api/device";
import { UserContext } from "../../../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 230,
  },
});

const headers = [
  { id: "deviceID", label: "Device ID" },
  { id: "version", label: "Version" },
  // { id: "auto", label: "Auto Visible" },
  { id: "deviceState", label: "State" },
  { id: "action", label: "Action" },
  { id: "blocked", label: "Block/Unblock" },
  { id: "logs", label: "Logs" },
  { id: "metering", label: "Metering" },
  { id: "signal", label: "Signal Strength" },
  { id: "sim", label: "Sim Data" },
  { id: "remove", label: "Remove Device" },
];

const ViewDevices = ({
  siteId,
  setSeverity,
  setMessage,
  setAlertOpen,
  setCommonData,
  commonData,
  setFormOpen,
  setDeviceData,
  deviceData,
  setDeviceID,
  setUpdateForm,
  change,
  setBlock,
  setBlockForm,
  setRemoveForm,
  setVersionForm,
  handleFormClose,
  setAuto,
  setAutoForm,
  alertOpen,
  dialog,
  setDialog,
  setSignalDialog,
  signalDialog,
  filterOpen,
  setFilterOpen,
  filterData,
  setFilterData,
  filterCount,
  setFilterCount,
  versionData,
  setDesignId
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const { userData } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [deviceState, setDeviceState] = useState({
    timer: "",
    mode: "",
    switch: "",
    timerStatus: "",
    modeStatus: "",
    switchStatus: "",
    id: "",
    threshold: "",
    blocked: false,
  });
  // const [updatedDeviceState, setUpdatedDeviceState] = useState({});
  const [stateOpen, setStateOpen] = useState(false);
  const [simData, setSimData] = useState();
  const [dialogLoad, setDialogLoad] = useState(true);
  const [signal, setSignal] = useState();

  const showDeviceState = (deviceData, deviceId, blocked) => {
    setDeviceState({
      switch: deviceData.switch,
      switchStatus:
        deviceData.switchStatus === "1"
          ? "Switch Status: ON"
          : "Switch Status: OFF",
      mode: deviceData.mode,
      modeStatus:
        deviceData.modeStatus === "1" ? "Status: MANUAL" : "Status: AUTO",
      timer: deviceData.timer === "9999,9999" ? "NOT SET" : deviceData.timer,
      timerStatus:
        deviceData.timerStatus === "9999,9999"
          ? "Status: NOT SET"
          : "Status: " + deviceData.timerStatus,
      timer2: deviceData.timer2 === "9999,9999" ? "NOT SET" : deviceData.timer2,
      timerStatus2:
        deviceData.timerStatus2 === "9999,9999"
          ? "Status: NOT SET"
          : "Status: " + deviceData.timerStatus2,
      id: deviceId,
      threshold: deviceData.threshold ? deviceData.threshold : "0",
      blocked: blocked,
      auto: deviceData.show_auto
    });
    setStateOpen(true);
  };

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getDevicesOfCompany(
        siteId,
        setData,
        setLoading,
        0,
        rowsPerPage,
        "true",
        setTotal,
        setSeverity,
        setAlertOpen,
        setMessage,
        "",
        "",
        event.target.value,
        filterData
      );
    }, 1000);

  };

  useEffect(() => {
    setLoading(true);
    getDevicesOfCompany(
      siteId,
      setData,
      setLoading,
      page,
      rowsPerPage,
      "true",
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      "",
      "",
      searchQuery,
      filterData
    );
  }, [page, rowsPerPage, change, siteId]);
  // useEffect(() => {
  //   // Filter data based on search query after a delay
  //   const timer = setTimeout(() => {
  //     const filtered = data.filter((row) =>
  //       row.device_id.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setFilteredData(filtered);
  //   }, 300); // Adjust the delay time as needed

  //   return () => clearTimeout(timer);
  // }, [searchQuery, data]);
  useEffect(() => {
    setLoading(true);
    getDevicesOfCompany(
      siteId,
      "",
      setLoading,
      "",
      "",
      "false",
      "",
      "",
      "",
      "",
      setCommonData,
      commonData,
      searchQuery,
      filterData
    );
  }, []);

  const validateTimer2 = (timer, timer2) => {
    if (timer2 === "9999,9999" || timer2 === "NOT SET") return true;
    const [st, et] = timer.split(',').map(Number);
    const startTime = Math.floor(st / 100) * 60 + (st % 100);
    const [st2, et2] = timer2.split(',').map(Number);
    const startTime2 = Math.floor(st2 / 100) * 60 + (st2 % 100);

    const endTime = Math.floor(et / 100) * 60 + (et % 100);
    const endTime2 = Math.floor(et2 / 100) * 60 + (et2 % 100);

    if ((startTime2 >= startTime && startTime2 <= endTime) || (endTime2 >= startTime && endTime2 <= endTime) || (startTime2 >= endTime && endTime2 >= startTime && startTime2 > endTime2) || (startTime2 <= startTime && (endTime2 >= startTime || endTime2 <= startTime2))
    ) {
      setSeverity("error");
      setMessage("Timer-2 should not be in range of Timer-1");
      setAlertOpen(true);
      return false;
    }
    return true;
  }
  const validateTimer = (timer, timer2) => {
    if (timer2 === "9999,9999" || timer2 === "NOT SET") return true;
    const [st, et] = timer2.split(',').map(Number);
    const startTime = Math.floor(st / 100) * 60 + (st % 100);
    const [st2, et2] = timer.split(',').map(Number);
    const startTime2 = Math.floor(st2 / 100) * 60 + (st2 % 100);

    const endTime = Math.floor(et / 100) * 60 + (et % 100);
    const endTime2 = Math.floor(et2 / 100) * 60 + (et2 % 100);

    if ((startTime2 >= startTime && startTime2 <= endTime) || (endTime2 >= startTime && endTime2 <= endTime) || (startTime2 >= endTime && endTime2 >= startTime && startTime2 > endTime2) || (startTime2 <= startTime && (endTime2 >= startTime || endTime2 <= startTime2))) {
      setSeverity("error");
      setMessage("Timer-1 should not be in range of Timer-2");
      setAlertOpen(true);
      return false;
    }
    return true;
  }

  const handleStateUpdate = () => {
    let data = {
      switch_val: deviceState.switch,
      deviceId: deviceState.id,
      mode: deviceState.mode,
      threshold: deviceState.threshold,
      timer:
        deviceState.timer === "NOT SET" || deviceState.timer === ""
          ? "9999,9999"
          : deviceState.timer,
      timer2:
        deviceState.timer2 === "NOT SET" || deviceState.timer2 === "" || deviceState.timer2 === undefined
          ? "9999,9999"
          : deviceState.timer2,
    };
    if (data.timer === "9999,9999" && data.timer2 !== "9999,9999") {
      setSeverity("error");
      setMessage("Please Clear timer2 first");
      setAlertOpen(true);
      return;
    }
    // console.log(data.timer2);
    const t1 = validateTimer(data.timer, data.timer2);
    let t2 = false;
    if (t1 === true) t2 = validateTimer2(data.timer, data.timer2);
    if (t1 && t2) {
      updateDeviceState(
        data,
        setSeverity,
        setAlertOpen,
        setMessage,
        handleFormClose,
        setStateOpen
      );
    }
  };

  const handleResetDevice = () => {
    resetDevice(
      setSeverity,
      setAlertOpen,
      setMessage,
      handleFormClose,
      setStateOpen,
      { deviceId: deviceState.id }
    );
  };

  const getSimData = (mobile_no) => {
    simDetails(
      mobile_no,
      setSimData,
      setDialogLoad,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  };

  const getSignalStrength = (device_code) => {
    getDeviceSignalStrength(
      device_code,
      setSignal,
      setDialogLoad,
      setSeverity,
      setAlertOpen,
      setMessage
    );
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Devices</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Devices"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {/* <Button onClick={fetchDevices} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
            <IconButton onClick={(e) => {
              setFilterOpen(true);
            }}>
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton>
            {((userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("ADD_SITE_DEVICE"))) && <IconButton>
              <Tooltip title="Add Device" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, padding: "12px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{
                        backgroundColor: row.has_metering === true && "#E3F2FD",
                      }}
                    >
                      <TableCell component="th" scope="row" style={{ padding: "12px" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            style={
                              row.status === "online"
                                ? {
                                  backgroundColor: "#3cb043",
                                  height: 10,
                                  width: 10,
                                  boxShadow: "0 0 10px 1px green",
                                }
                                : row.status === "updating"
                                  ? {
                                    backgroundColor: "#FFC300",
                                    height: 10,
                                    width: 10,
                                    boxShadow: "0 0 10px 1px yellow",
                                  }
                                  : {
                                    backgroundColor: "#d0312d",
                                    height: 10,
                                    width: 10,
                                    boxShadow: "0 0 10px 1px red",
                                  }
                            }
                          >
                            &nbsp;
                          </Avatar>
                          <CustomTooltip
                            arrow
                            placement="top"
                            title={
                              "Created At: " +
                              new Date(row.createdAt).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                              }) +
                              "\nUpdated At: " +
                              new Date(row.updatedAt).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                              })
                            }
                          >
                            <span style={{ marginLeft: 12 }}>
                              {row.device_id}
                            </span>
                          </CustomTooltip>
                        </Box>
                      </TableCell>

                      <TableCell style={{ padding: "12px" }}>
                        <Button
                          color="warning"
                          disabled={row.blocked || !(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("UPDATE_SITE_DEVICE_VERSION"))}
                          onClick={(e) => {
                            setDeviceID(row._id);
                            setDesignId(row.design_id)
                            setVersionForm(true);
                            setDesignId(row.design_id);
                          }}
                        >
                          {row.version_data.version_id}
                        </Button>
                      </TableCell>
                      {/* <TableCell>
                        <Button
                          onClick={(e) => {
                            setDeviceID(row._id);
                            setAuto(row.show_auto);
                            setAutoForm(true);
                          }}
                        >
                          {row.show_auto ? "YES" : "NO"}
                        </Button>
                      </TableCell> */}

                      <TableCell style={{ padding: "12px" }}>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("SET_SITE_DEVICE_STATE"))}
                          onClick={(e) =>
                            showDeviceState(
                              row.device_data,
                              row.device_id,
                              row.blocked
                            )
                          }
                        >
                          State
                        </Button>
                      </TableCell>

                      <TableCell style={{ padding: "12px" }}>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("UPDATE_DEVICE"))}
                          onClick={(e) => {
                            setUpdateForm(true);
                            setDeviceID(row._id);
                            setDeviceData({
                              ...deviceData,
                              device_description: row.device_description,
                              imi_no: row.imi_no,
                              sim_no: row.sim_no,
                              mobile_no: row.mobile_no,
                              password: row.password,
                              blocked: row.blocked,
                              has_metering:
                                row.has_metering === undefined
                                  ? false
                                  : row.has_metering,
                              show_auto: row.show_auto
                            });
                          }}
                        >
                          Update
                        </Button>
                      </TableCell>

                      <TableCell style={{ padding: "12px" }}>
                        {row.blocked ? (
                          <Button
                            disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("BLOCK_SITE_DEVICE"))}
                            color="success"
                            onClick={(e) => {
                              setBlockForm(true);
                              setBlock(false);
                              setDeviceID(row._id);
                            }}
                          >
                            UnBlock
                          </Button>
                        ) : (
                          <Button
                            color="error"
                            disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("BLOCK_SITE_DEVICE"))}
                            onClick={(e) => {
                              setBlockForm(true);
                              setBlock(true);
                              setDeviceID(row._id);
                            }}
                          >
                            Block
                          </Button>
                        )}
                      </TableCell>
                      <TableCell style={{ padding: "12px" }}>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("FETCH_DEVICE_LOGS"))}
                          onClick={(e) => {
                            window.open(`/dashboard/logs/${row._id}`);
                          }}
                        >
                          Get Logs
                        </Button>
                      </TableCell>
                      <TableCell style={{ padding: "12px" }}>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("UPDATE_METERING"))}
                          onClick={(e) => {
                            window.open(`/dashboard/device/${row._id}`);
                          }}
                        >
                          Metering
                        </Button>
                      </TableCell>
                      <TableCell style={{ padding: "12px" }}>
                        <Button
                          variant="outlined"
                          color="info"
                          disabled={row.blocked || !(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("SITE_DEVICE_SIGNAL"))}
                          onClick={() => {
                            setSignalDialog(true);
                            getSignalStrength(row.device_id);
                          }}
                        >
                          get Signal
                        </Button>
                      </TableCell>
                      <TableCell style={{ padding: "12px" }}>
                        <Button
                          variant="outlined"
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("SITE_DEVICE_SIM_DATA"))}
                          color="secondary"
                          onClick={() => {
                            setDialog(true);
                            getSimData(row.mobile_no);
                          }}
                        >
                          Sim Data
                        </Button>
                      </TableCell>
                      <TableCell style={{ padding: "12px" }}>
                        <Button
                          variant="outlined"
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("REMOVE_SITE_DEVICE"))}
                          color="error"
                          onClick={(e) => {
                            setRemoveForm(true);
                            setDeviceID(row._id);
                          }}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* -------------------- Update device state --------------------*/}
      <Dialog
        open={stateOpen}
        onClose={(e) => {
          setStateOpen(false);
        }}
      >
        <DialogTitle>State of Device {deviceState.id}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Tooltip arrow placement="top" title={deviceState.switchStatus}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  id="switch"
                  label="Switch"
                  value={deviceState.switch}
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      switch: e.target.value,
                    });
                    // setUpdatedDeviceState({
                    //   ...updateDeviceState,
                    //   switch: e.target.value,
                    // });
                  }}
                >
                  <MenuItem value={"0"}>OFF</MenuItem>
                  <MenuItem value={"1"}>ON</MenuItem>
                </TextField>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip arrow placement="top" title={deviceState.modeStatus}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  id="switch"
                  label="Mode"
                  name="switch"
                  value={deviceState.mode}
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      mode: e.target.value,
                    });
                    // setUpdatedDeviceState({
                    //   ...updatedDeviceState,
                    //   mode: e.target.value,
                    // });
                  }}
                >
                  <MenuItem value={"0"}>AUTO</MenuItem>
                  <MenuItem value={"1"}>MANUAL</MenuItem>
                </TextField>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip arrow placement="top" title={deviceState.timerStatus}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="time"
                  label="Timer"
                  name="time"
                  value={deviceState.timer}
                  placeholder="start time,end time"
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      timer: e.target.value,
                    });
                    // setUpdatedDeviceState({
                    //   ...updatedDeviceState,
                    //   timer: e.target.value,
                    // });
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip arrow placement="top" title={deviceState.timerStatus2}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="time2"
                  label="Timer 2"
                  name="time2"
                  value={deviceState.timer2}
                  disabled={(deviceState.timer === "NOT SET" || deviceState.timer === "9999,9999") || !deviceState.timer}
                  placeholder="start time,end time"
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      timer2: e.target.value,
                    });
                    // setUpdatedDeviceState({
                    //   ...updatedDeviceState,
                    //   timer: e.target.value,
                    // });
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                id="switch"
                label="Threshold"
                name="switch"
                value={deviceState.threshold}
                onChange={(e) => {
                  setDeviceState({
                    ...deviceState,
                    threshold: e.target.value,
                  });
                  // setUpdatedDeviceState({
                  //   ...updatedDeviceState,
                  //   threshold: e.target.value,
                  // });
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <Tooltip arrow placement="top" title={deviceState.show_auto}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  id="autoVisible"
                  label="Auto Visible"
                  value={deviceState.show_auto}
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      show_auto: e.target.value,
                    });
                    // setUpdatedDeviceState({
                    //   ...updatedDeviceState,
                    //   auto: e.target.value,
                    // });
                  }}
                >
                  <MenuItem value={"false"}>NO</MenuItem>
                  <MenuItem value={"true"}>YES</MenuItem>
                </TextField>
              </Tooltip>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="warning"
            disabled={deviceState.blocked}
            onClick={handleResetDevice}
          >
            Reset Device
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            disabled={deviceState.blocked}
            onClick={handleStateUpdate}
          >
            Update State
          </Button>
        </DialogActions>
      </Dialog>

      {/* ---------------------- Sim Data Dialog ------------------- */}
      <Dialog
        open={dialog}
        onClose={() => {
          setDialog(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Sim Data</DialogTitle>

        {dialogLoad ? (
          <DialogContent>
            <p>Loading Data....</p>
          </DialogContent>
        ) : (
          <>
            {alertOpen ? (
              <DialogContent>Error</DialogContent>
            ) : (
              <DialogContent>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="simno"
                  label="Sim No"
                  value={simData?.simNo}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="planname"
                  label="Plan Name"
                  value={simData?.planName}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="usedData"
                  label="Used Data"
                  value={simData?.usedDataUsage + " MB"}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="DataLeft"
                  label="Data Left"
                  value={simData?.remainingDataUsage + " MB"}
                />
              </DialogContent>
            )}
          </>
        )}
      </Dialog>

      {/* Signal Strength Data dialog */}

      <Dialog
        open={signalDialog}
        onClose={() => {
          setSignalDialog(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Signal Strength</DialogTitle>

        {dialogLoad ? (
          <DialogContent>
            <p>Loading Data....</p>
          </DialogContent>
        ) : (
          <>
            {alertOpen ? (
              <DialogContent>Error</DialogContent>
            ) : (
              <DialogContent>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="signal"
                  label="Signal Strength"
                  value={signal}
                />
              </DialogContent>
            )}
          </>
        )}
      </Dialog>

      {/* Filter Devices dialog */}

      <Dialog
        open={filterOpen}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Filter Devices"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Has Metering"
            name="Has Metering"
            select
            fullWidth
            margin="normal"
            value={filterData.has_metering}
            onChange={(e) => {
              setFilterData({ ...filterData, has_metering: e.target.value });
            }}
          >
            <MenuItem key={"false"} value={false}>
              NO
            </MenuItem>
            <MenuItem key={"true"} value={true}>
              YES
            </MenuItem>
          </TextField>
          <TextField
            select
            margin="normal"
            fullWidth
            label="Version"
            value={filterData.version}
            onChange={(e) => {
              setFilterData({ ...filterData, version: e.target.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {versionData &&
              versionData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.version_id}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={(e) => {
              setFilterData({
                has_metering: "",
                version: "",
              });
              handleFormClose();
              setFilterCount("");
            }}
          >
            Reset Filter
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              handleFormClose();
            }}
          >
            Set filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewDevices;
