import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { FileUploadOutlined } from "@mui/icons-material";
import { updateDesign } from "../../../api/design";

export default function UpdateDesign({
  dialog,
  handleClose,
  designData,
  setDesignData,
  setSeverity,
  setMessage,
  setAlertOpen,
  attachment,
  setAttachment,
}) {
  const handleUpdateDesign = () => {
    const form = new FormData();
    form.append("design_id", designData.design_id);
    form.append("description", designData.description);
    form.append("title", designData.title);
    form.append("update_message", designData.update_message);

    if (attachment.gerber_file) {
      form.append("gerber_file", attachment.gerber_file);
    }

    if (attachment.code_file) {
      form.append("code_file", attachment.code_file);
    }

    if (attachment.schematics_file) {
      form.append("schematics_file", attachment.schematics_file);
    }

    updateDesign(
      designData._id,
      form,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleClose
    );
  };

  return (
    <>
      {/* Dialog to create new Batch */}

      <Dialog open={dialog} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Update Design</DialogTitle>

        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="design_code"
            label="Design Code"
            disabled={true}
            value={designData?.design_id}
            onChange={(e) => {
              setDesignData({ ...designData, design_id: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Design Title"
            value={designData.title}
            onChange={(e) => {
              setDesignData({ ...designData, title: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            value={designData.description}
            onChange={(e) => {
              setDesignData({ ...designData, description: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="update_message"
            label="Update Message"
            value={designData.update_message}
            onChange={(e) => {
              setDesignData({ ...designData, update_message: e.target.value });
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            label="Gerber file (zip)"
            value={
              attachment.gerber_file
                ? attachment.gerber_file.name +
                  " - " +
                  (attachment.gerber_file.size / 1000).toString() +
                  " Kb"
                : ""
            }
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton component="label">
                  <FileUploadOutlined />
                  <input
                    styles={{ display: "none" }}
                    type="file"
                    hidden
                    onChange={(e) => {
                      setAttachment({
                        ...attachment,
                        gerber_file: e.target.files[0],
                      });
                    }}
                    name="gerber_file"
                  />
                </IconButton>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            label="Code file (zip)"
            value={
              attachment.code_file
                ? attachment.code_file.name +
                  " - " +
                  (attachment.code_file.size / 1000).toString() +
                  " Kb"
                : ""
            }
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton component="label">
                  <FileUploadOutlined />
                  <input
                    styles={{ display: "none" }}
                    type="file"
                    hidden
                    onChange={(e) => {
                      setAttachment({
                        ...attachment,
                        code_file: e.target.files[0],
                      });
                    }}
                    name="code_File"
                  />
                </IconButton>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            label="Schematic file (zip)"
            value={
              attachment.schematics_file
                ? attachment.schematics_file.name +
                  " - " +
                  (attachment.schematics_file.size / 1000).toString() +
                  " Kb"
                : ""
            }
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton component="label">
                  <FileUploadOutlined />
                  <input
                    styles={{ display: "none" }}
                    type="file"
                    hidden
                    onChange={(e) => {
                      setAttachment({
                        ...attachment,
                        schematics_file: e.target.files[0],
                      });
                    }}
                    name="schematics_file"
                  />
                </IconButton>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleUpdateDesign}
          >
            Update Design
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
