import axiosIntance from "../helper/axios";

const createKtUser = (
  userData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/ktuser/create", userData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      handleFormClose();
      setLoading(false);
    });
};

const updateKtUser = (
  userId,
  userData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .put(`/ktuser/update/${userId}`, userData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const deleteKtUser = (
  userId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setDeleteLoading
) => {
  axiosIntance
    .delete(`/ktuser/delete/${userId}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setDeleteLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setDeleteLoading(false);
    });
};

const getKtUsers = (
  setData,
  setLoading,
  page,
  limit,
  table,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  searchQuery
) => {

  if (table === "true") {
    axiosIntance
      .get(
        `/ktuser/all?page=${page + 1}&limit=${limit}&table=${table}&searchTerm=${searchQuery}`
      )
      .then((res) => {
        setData(res.data.data.data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.error);
        setAlertOpen(true);
        setSeverity("error");
        setMessage(err.response.data.error);
      });
  } else {
    setLoading(true);
    axiosIntance
      .get(`/ktuser/all/?table=${table}`)
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.error);
        setData({});
      });
  }
};

export { getKtUsers,createKtUser,updateKtUser,deleteKtUser };
