
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
  } from "@mui/material";
  import React, {  } from "react";
  
  
  export default function DeleteFeature({
    setSeverity,
    setMessage,
    setAlertOpen,
    deleteOpen,
    deleteLoading,
    handleDeleteConfirm,
    handleDeleteCancel
  }) {
    return (
      <>
        <Dialog
          open={deleteOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Feature"}</DialogTitle>
          <DialogContent>
          <Typography>Are You Sure You want to delete the Feature</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="success"
              onClick={(e) => {
                handleDeleteConfirm();
              }}
            >
              {deleteLoading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Yes"}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={(e) => {
                handleDeleteCancel();
              }}
            >
              {deleteLoading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"No"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  