import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { Workspaces, PlaylistAdd, PlaylistAddCheck } from "@mui/icons-material";
import { getCentralStats } from "../../../api/device";

const DeviceStats = () => {
  const [loading, setLoading] = useState(true);
  const [statData, setStatData] = useState({
    assigned: 0,
    unassigned: 0,
  });
  useEffect(() => {
    getCentralStats(setStatData, setLoading);
  }, []);
  return (
    <React.Fragment>
      <Grid container spacing={4} style={{ marginBottom: 16 }}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Unassigned
              </Typography>
              <Typography variant="h4" color="primary">
                {loading ? "Loading.." : statData.unassigned}
              </Typography>
            </Box>
            <PlaylistAdd
              sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Active
              </Typography>
              <Typography variant="h4" color="primary">
                {loading ? "Loading.." : statData.assigned}
              </Typography>
            </Box>
            <PlaylistAddCheck
              sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Total
              </Typography>
              <Typography variant="h4" color="primary">
                {loading
                  ? "Loading.."
                  : statData.unassigned + statData.assigned}
              </Typography>
            </Box>
            <Workspaces sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DeviceStats;
