import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { createPlan, updatePlanbySuperAdmin } from "../../../api/plans";

export default function CrudSitePlan({
  dialog,
  handleClose,
  newPlan,
  setNewPlan,
  setSeverity,
  setMessage,
  setAlertOpen,
  companyId,
  planUpdate,
  planId,
}) {
  const [loading,setLoading]=useState(false);
  const createNewPlan = () => {
    setLoading(true);
    createPlan(
      newPlan,
      companyId,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleClose,
      setLoading,
      setNewPlan
    );
  };

  const updatePlan = () => {
    setLoading(true);
    updatePlanbySuperAdmin(
      newPlan,
      planId,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleClose,
      setLoading
    );
  };

  return (
    <>
      <Dialog open={dialog} onClose={handleClose} maxWidth="xs">
        {planUpdate ? (
          <DialogTitle>Update Plan</DialogTitle>
        ) : (
          <DialogTitle>Create New Plan</DialogTitle>
        )}
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="plan_code"
            label="Plan Code"
            value={newPlan.plan_code}
            onChange={(e) => {
              setNewPlan({ ...newPlan, plan_code: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="planname"
            label="Plan Name"
            value={newPlan.plan_name}
            onChange={(e) => {
              setNewPlan({ ...newPlan, plan_name: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            id="users"
            label="Users Allowed"
            value={newPlan.users_allowed}
            onChange={(e) => {
              setNewPlan({ ...newPlan, users_allowed: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id="groups"
            label="Groups Allowed"
            value={newPlan.groups_allowed}
            onChange={(e) => {
              setNewPlan({ ...newPlan, groups_allowed: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="price"
            label="Price (in Rs)"
            value={newPlan.price}
            onChange={(e) => {
              setNewPlan({ ...newPlan, price: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="time_period"
            label="Time Period (in days)"
            value={newPlan.time_period}
            onChange={(e) => {
              setNewPlan({ ...newPlan, time_period: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          {planUpdate ? (
            <Button variant="outlined" color="secondary" onClick={updatePlan}>
              {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Update Plan"}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              onClick={createNewPlan}
            >
              {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create Plan"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
