import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { Search as SearchIcon, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { fetchGroupsCompany } from "../../../../../api/group";
import { UserContext } from "../../../../../context/UserContext";
// import { createGroup, updateGroup } from "../../../api/group";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "name", label: "Group Name" },
  { id: "code", label: "Group Code" },
  { id: "devices", label: "Devices", align: "right" },
  { id: "created_at", label: "Created At", align: "right" },
  { id: "created_by", label: "Created By", align: "right" },
  { id: "updated_at", label: "Updated At", align: "right" },
  { id: "updated_by", label: "Updated By", align: "right" },
  { id: "update", label: "Update", align: "center" },
  { id: "delete", label: "Delete", align: "center" },
];

const ViewGroups = ({
  siteId,
  setSeverity,
  setMessage,
  setAlertOpen,
  setFormOpen,
  setUpdate,
  setGroupId,
  change,
  setGroupName,
  setFormState,
  setCommonData,
  commonData,
  setDeleteForm,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [groupData, setGroupData] = useState([]);
  const [total, setTotal] = useState(0);
  const { userData } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    fetchGroupsCompany(
      siteId,
      setGroupData,
      setLoading,
      page,
      rowsPerPage,
      "true",
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      "",
      "",
      searchQuery
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, page, rowsPerPage, change]);

  useEffect(() => {
    setLoading(true);
    fetchGroupsCompany(
      siteId,
      "",
      setLoading,
      "",
      "",
      "false",
      "",
      "",
      "",
      "",
      setCommonData,
      commonData,
      searchQuery
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  // useEffect(() => {
  //   // Filter data based on search query after a delay
  //   const timer = setTimeout(() => {
  //     const filtered = groupData.filter((row) =>
  //       row.group_name.toLowerCase().includes(searchQuery.toLowerCase())||       
  //       row.group_code.toLowerCase().includes(searchQuery.toLowerCase())

  //     );
  //     setFilteredData(filtered);
  //   }, 300); // Adjust the delay time as needed

  //   return () => clearTimeout(timer);
  // }, [searchQuery, groupData]);
  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      fetchGroupsCompany(
        siteId,
        setGroupData,
        setLoading,
        0,
        rowsPerPage,
        "true",
        setTotal,
        setSeverity,
        setAlertOpen,
        setMessage,
        "",
        "",
        event.target.value
      );
    }, 1000);

  };
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Groups</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Groups"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {/* <Button onClick={fetchGroups} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
            {/* <IconButton>
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton> */}
            {(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("CREATE_SITE_GROUP")) && <IconButton>
              <Tooltip title="Add Group" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <>
                <TableBody>
                  {groupData.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell component="th" scope="row">
                          {row.group_name}
                        </TableCell>
                        <TableCell>{row.group_code}</TableCell>
                        <TableCell align="right">
                          {row.device_array.length}
                        </TableCell>
                        <TableCell align="right">
                          {new Date(row.createdAt).toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })}
                        </TableCell>
                        <TableCell align="right">{row.created_by}</TableCell>
                        <TableCell align="right">
                          {new Date(row.updatedAt).toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })}
                        </TableCell>
                        <TableCell align="right">
                          {row.updated_by || "--"}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            disabled={row.group_name === "Admin Group" || !(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("UPDATE_SITE_GROUP"))}
                            onClick={() => {
                              setGroupName(row.group_name);
                              setFormState((formState) => ({
                                ...formState,
                                deviceArray: row.device_array,
                              }));
                              setUpdate(true);
                              setGroupId(row._id);
                              setFormOpen(true);
                            }}
                          >
                            Update
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            disabled={row.group_name === "Admin Group" || !(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("DELETE_SITE_GROUP"))}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              setGroupId(row._id);
                              setDeleteForm(true);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default ViewGroups;
