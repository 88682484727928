// all the apis for version control

import axiosIntance from "../helper/axios";

const getAllVersionList = (
  setVersionData,
  table,
  designId,
  page,
  limit,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading
) => {
  if (table === "true") {
    setLoading(true);
    axiosIntance
      .get(
        `/version/all?table=${table}&page=${page + 1
        }&limit=${limit}&designId=${designId}`
      )
      .then((res) => {
        setVersionData(res.data.data.version_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setMessage("Error in fetching device list");
        setAlertOpen(true);
        setLoading(false);
      });
  } else {
    axiosIntance
      .get(`/version/all?designId=${designId}`)
      .then((res) => {
        setVersionData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const createNewVersion = (
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/version/create", data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      setLoading(false);
      handleFormClose();
    })
    .catch((err) => {
      setSeverity("error");
      setMessage(err.response.data.error);
      setAlertOpen(true);
      setLoading(false);
      handleFormClose()
    });
};

const updateVersion = (
  version_id,
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .put(`/version/update/${version_id}`, data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      if (setLoading) setLoading(false);
    })
    .catch((err) => {
      setSeverity("error");
      setMessage(err.response.data.error);
      setAlertOpen(true);
      if (setLoading) setLoading(false);
    });
};

const pullVersionLocal = (
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading,
  version_id
) => {
  axiosIntance
    .get(`/version/local/${version_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage("Version Pulled locally");
      setLoading(false);
    })
    .catch((err) => {
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
    });
};

const deleteVersionById = (
  version_id,
  setSeverity,
  setAlertOpen,
  setMessage,
  setLoading,
  setReload,
  reload
) => {
  setLoading(true);
  axiosIntance
    .delete(`/version/delete/${version_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setLoading(false);
      setReload(!reload);
    })
    .catch((err) => {
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

export {
  getAllVersionList,
  createNewVersion,
  updateVersion,
  pullVersionLocal,
  deleteVersionById,
};
