/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Snackbar, Alert, Breadcrumbs, Divider } from "@mui/material";
import { getDeviceMeteringData } from "../../../../api/device";

import { ActiveLink, PageTitle, PathLink } from "../../../../helper/style";
import DeviceState from "./DeviceState";
import Loader from "../../../../components/Loader";
import DeviceEnergyLogs from "./EnergyLogs";
import MeteringForm from "./MeteringData";
import { UserContext } from "../../../../context/UserContext";

export default function DeviceMetering() {
  const [deviceData, setDeviceData] = useState({});
  const [deviceState, setDeviceState] = useState({});
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [deviceId, setDeviceId] = useState();
  const { userData } = useContext(UserContext);
  const { device_id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (device_id) {
      setDeviceId(device_id);
    }
  }, [device_id]);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleFormClose = () => {
    setReload(!reload);
  };
  useEffect(() => {
    getDeviceMeteringData(
      device_id,
      setDeviceData,
      setDeviceState,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading
    );
  }, [reload]);
  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <PageTitle>Device Metering</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>
            <PathLink
              underline="hover"
              onClick={() => navigate("/dashboard/device")}
            >
              Device
            </PathLink>

            <ActiveLink>Device Metering</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />

          {(userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes(
              "SET_SITE_DEVICE_STATE"
            )) && (
              <DeviceState
                deviceData={deviceData}
                deviceState={deviceState}
                setDeviceState={setDeviceState}
                setSeverity={setSeverity}
                setAlertOpen={setAlertOpen}
                setMessage={setMessage}
                handleFormClose={handleFormClose}
              />
            )}

          {(userData?.user?.isSuperAdmin === true ||
            userData?.user?.role?.permissions.includes("FETCH_ENERGY")) && (
              <DeviceEnergyLogs
                device_id={device_id}
                logData={logData}
                setLogData={setLogData}
                setAlertOpen={setAlertOpen}
                setSeverity={setSeverity}
                setMessage={setMessage}
              />
            )}

          <MeteringForm
            device_id={device_id}
            deviceData={deviceData}
            setDeviceData={setDeviceData}
            setAlertOpen={setAlertOpen}
            setSeverity={setSeverity}
            setMessage={setMessage}
            handleFormClose={handleFormClose}
          />
        </>
      )}
    </>
  );
}
