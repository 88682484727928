/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Button,
  Collapse,
  Box,
  Tooltip,
} from "@mui/material";
import { FilterList, Search as SearchIcon} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { getuserdata, removeuser } from "../../../api/user";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function Row(props) {
  const {
    row,
    setLoading,
    setSeverity,
    setMessage,
    setAlertOpen,
    setReload,
    setUpdate,
    setUserid,
    setUserData
  } = props;

  const [open, setOpen] = React.useState(false);
  const {userData}= useContext(UserContext);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row._id}
      >
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell
          component="th"
          scope="row"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/dashboard/site/${row._id}`)}
        >
          {row.company_code}
        </TableCell> */}
        <TableCell>{row.full_name}</TableCell>
        <TableCell>{row.phone_no}</TableCell>
        <TableCell>{row.companies.length}</TableCell>
        <TableCell>{row.created_by}</TableCell>
        {/* <TableCell>{row.no_of_roles}</TableCell> */}
        {/* <TableCell align="center">
          {new Date(row.created_at).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </TableCell> */}
        <TableCell align="center">
          <Button
            variant="outlined"
            color="error"
            size="small"
            disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_USER"))}
            onClick={() =>{
              setLoading(true);
              removeuser(row._id,setReload,setSeverity,
                setMessage,
                setAlertOpen);
            }}
          >
            Delete User
          </Button>
        </TableCell>
        <TableCell align="center">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_SITE_USER"))}
            onClick={() =>{
              setUpdate(true);
              setUserid(row._id);
              setUserData(row);
            }}
          >
            Update User
          </Button>
        </TableCell>
      </TableRow>
      <TableRow key={row._id+'sites'}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginBottom: 5 }}>
              <div
                style={{
                  margin: "16px 8px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Sites Accessible</Typography>
                {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      setDialog(true);
                      setCompanyId(row._id);
                    }}
                  >
                    Create Site Plan
                  </Button>
                </div> */}
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Site Code</TableCell>
                    <TableCell>Site Name</TableCell>
                    <TableCell>Date Joined</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.companies.map((company) => (
                    
                    <TableRow key={company}>
                      <TableCell component="th" scope="row">
                        {company.company_code}
                      </TableCell>
                      <TableCell>{company.company_name}</TableCell>
                      <TableCell>{new Date(company.date_joined).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const headers = [
  { id: "collapse", label: "" },
  { id: "name", label: "Name" },
  { id: "phone", label: "Phone No." },
  { id: "No. Of Sites", label: "Site Accessible" },
  { id: "created_by", label: "Created By" },
  { id: "delete", label: "Delete", align: "center" },
  { id: "update", label: "Update", align: "center" },
];

const UserTable = ({
  setAlertOpen,
  setSeverity,
  setMessage,
  reload,
  setReload,
  setUpdate,
  setUserid,
  setUserData,
  setFilterOpen,
  filterData
}) => {
  const [loading, setLoading] = useState(true);

  const [UserList, setUserList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  React.useEffect(() => {
    setLoading(true);
    getuserdata(
      setUserList,"true",page,rowsPerPage,setLoading,searchQuery,setTotal,filterData
    );
  }, [page, rowsPerPage, reload]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getuserdata(
        setUserList,"true",page,rowsPerPage,setLoading,event.target.value,setTotal,filterData
      );
    }, 1000);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Users Table</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
              <IconButton
                onClick={(e) => {
                  setFilterOpen(true);
                }}
              >
                <Tooltip title="Filters" arrow placement="top">
                  <FilterList />
                </Tooltip>
              </IconButton>
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell key="loader">Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {UserList.map((row) => {
                  return (
                    <Row
                      row={row}
                      setLoading={setLoading}
                      setSeverity={setSeverity}
                      setMessage={setMessage}
                      setAlertOpen={setAlertOpen}
                      setReload={setReload}
                      setUpdate={setUpdate}
                      setUserid={setUserid}
                      setUserData={setUserData}
                      key={row._id}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default UserTable;
