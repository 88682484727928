// All the api request of company

import axiosIntance from "../helper/axios";

const getCompanyData = (companyId, setCompanyData, setLoading) => {
  axiosIntance
    .get(`/company/data/${companyId}`)
    .then((res) => {
      setCompanyData(res.data.data[0]);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err, "error");
    });
};

const planDetails = (
  companyId,
  setRows,
  page,
  limit,
  setTotal,
  searchQuery
) => {
  axiosIntance
    .get(
      `/company/plans/all/${companyId}?page=${
        page + 1
      }&limit=${limit}&searchTerm=${searchQuery}`
    )
    .then((res) => {
      setRows(res.data.data.plan_data);
      setTotal(res.data.data.metadata.total_results);
    })
    .catch((err) => {
      console.log(err, "error in fetching data");
    });
};

const activatePlansBySuperAdmin = (
  companyPlanId,
  setAlertOpen,
  setSeverity,
  setMessage,
  handleFormClose
) => {
  axiosIntance
    .put(`/company/plans/activate/${companyPlanId}`)
    .then((res) => {
      setAlertOpen(true);
      setSeverity("success");
      setMessage("Plan Activated successfully");
      handleFormClose();
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const expirePlanBySuperAdmin = (
  companyPlanId,
  setAlertOpen,
  setSeverity,
  setMessage,
  start_next,
  handleFormClose
) => {
  axiosIntance
    .put(`/company/plans/expire/${companyPlanId}?start_upcoming=${start_next}`)
    .then((res) => {
      setAlertOpen(true);
      setSeverity("success");
      setMessage("Plan Expired successfully");
      handleFormClose();
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const getAllCompanies = (
  setCompanyData,
  setLoading,
  table,
  setOpen,
  setSeverity,
  setMessage,
  page,
  limit,
  setTotal,
  searchTerm,
  filterData
) => {
  if (table === "true") {
    // setLoading(true);
    axiosIntance
      .get(`/company/all?table=${table}&page=${page + 1}&limit=${limit}&isDeleted=${filterData.deleted}&createdAt=${
          filterData.createdAt
        }&searchTerm=${searchTerm}`)
      .then((res) => {
        setCompanyData(res.data.data.company_data);
        // console.log(res.data.data.company_data);
        setLoading(false);
        setTotal(res.data.data.metadata.total_results);
      })
      .catch((err) => {
        setOpen(true);
        setSeverity("error");
        setMessage("Error in fetching data");
        console.log(err, "error");
        setLoading(false);
      });
  } else {
    setLoading(true);
    axiosIntance
      .get(`/company/all`)
      .then((res) => {
        setCompanyData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        // setOpen(true);
        // setSeverity("error");
        // setMessage("Error in fetching data");
        console.log(err, "error");
        setLoading(false);
      });
  }
};

const createNewCompany = (
  companyData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/company/register", companyData)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const updateExpiryDate = (
  expiry,
  setSeverity,
  setMessage,
  setOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .put(`/company/plans/update/expiry/${expiry.plan_id}?add=${expiry.add}`, {
      days: expiry.days,
    })
    .then((res) => {
      setSeverity("success");
      setMessage("Expiry Date Updated Successfully");
      setOpen(true);
      handleFormClose();
      setLoading(false);
      // setExpiry({ open: false, dasy: 1, add: false, plan_id: "" });
    })
    .catch((err) => {
      setSeverity("error");
      setMessage(err.response.data.error);
      setOpen(true);
      setLoading(false);
    });
};

const updateCompany = (
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  companyId,
  companyData
) => {
  axiosIntance
    .put(`/company/data/update/${companyId}`, companyData)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
    });
};

const deleteCompany = (
  setSeverity,
  setMessage,
  setAlertOpen,
  setDeleteLoading,
  setDeleteOpen,
  companyId,
  handleFormClose
) => {
  axiosIntance
    .delete(`/company/delete/${companyId}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setDeleteLoading(false);
      setDeleteOpen(false);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setDeleteLoading(false);
      setDeleteOpen(false);
    });
};

const sendNotification = (
  setSeverity,
  setMessage,
  setAlertOpen,
  handleNotificationClose,
  NotificationData,
  setLoading
) => {
  axiosIntance
    .post(`/company/send/notification`, NotificationData)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleNotificationClose();
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const restoreCompany = (
  setSeverity,
  setMessage,
  setAlertOpen,
  setDeleteLoading,
  setDeleteOpen,
  companyId,
  handleFormClose
) => {
  axiosIntance
    .put(`/company/restore/${companyId}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setDeleteLoading(false);
      setDeleteOpen(false);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setDeleteLoading(false);
      setDeleteOpen(false);
    });
};
const RemoveCompanyUser = (
  user_id,
  company_id,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .delete(`/company/user/delete/${company_id}/${user_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
    });
};

export {
  getCompanyData,
  planDetails,
  activatePlansBySuperAdmin,
  expirePlanBySuperAdmin,
  getAllCompanies,
  createNewCompany,
  updateExpiryDate,
  updateCompany,
  sendNotification,
  deleteCompany,
  restoreCompany,
  RemoveCompanyUser
};
