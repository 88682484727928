// Plans APIs

import axiosIntance from "../helper/axios";

const getAllPlans = (table, setAllPlans, siteId) => {
  axiosIntance
    .get(`/plan/get/all/${siteId}?table=${table}`)
    .then((res) => {
      setAllPlans(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getPlansByType = (
  setLoading,
  setPlanData,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  axiosIntance
    .get("/plan/get/type")
    .then((res) => {
      setPlanData(res.data.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log("Error in fetching plans");
      setLoading(false);
      setMessage("Error in fetching plans");
      setSeverity("error");
      setAlertOpen(true);
    });
};

const getUpcomingExpiredPlans = (
  setLoading,
  page,
  limit,
  setTotal,
  setPlanData,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  axiosIntance
    .get(`/plan/expiry?page=${page + 1}&limit=${limit}`)
    .then((res) => {
      setPlanData(res.data.data.plan_data);
      setTotal(res.data.data.metadata.total_results);
      setLoading(false);
    })
    .catch((err) => {
      console.log("Error in fetching plans");
      setLoading(false);
      setMessage("Error in fetching plans");
      setSeverity("error");
      setAlertOpen(true);
    });
};

const createPlan = (
  data,
  companyId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleClose,
  setLoading,
  setNewPlan
) => {
  axiosIntance
    .post(`/plan/create/${companyId}`, data)
    .then((res) => {
      setAlertOpen(true);
      setSeverity("success");
      setMessage("Plan Created Successfully ");
      handleClose();
      setLoading(false);
      setNewPlan({
        plan_code: "",
    plan_name: "",
    users_allowed: 1,
    groups_allowed: 1,
    price: "",
    time_period: "",
      })
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const updatePlanbySuperAdmin = (
  data,
  planId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleClose,
  setLoading
) => {
  axiosIntance
    .put(`/plan/update/${planId}`, data)
    .then((res) => {
      setAlertOpen(true);
      setSeverity("success");
      setMessage(res.data.message);
      handleClose();
      setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};
export { getAllPlans, getPlansByType, createPlan, updatePlanbySuperAdmin,getUpcomingExpiredPlans };
