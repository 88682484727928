import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { removeDeviceFromCompany } from "../../../../../api/device";

export default function RemoveDevice({
  removeForm,
  handleFormClose,
  deviceId,
  siteId,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {
  const handleRemoveDevice = () => {
    removeDeviceFromCompany(
      deviceId,
      siteId,
      setSeverity,
      setAlertOpen,
      setMessage,
      handleFormClose
    );
  };
  return (
    <>
      {/* Remove device confirmation dialog */}

      <Dialog
        open={removeForm}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove Device"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove the device
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>No</Button>
          <Button color="error" onClick={handleRemoveDevice}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
