import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    IconButton
  } from "@mui/material";
  import React from "react";
  import { useState } from "react";
import { updateUserDetails } from "../../../api/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from '@mui/material/InputAdornment';

  export default function UserUpdateDialog({
    formOpen,
    handleFormClose,
    setAlertOpen,
    setSeverity,
    setMessage,
    userData,
    setUserData,
    userId,
  }) {
    const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    const handleUpdate = () => {
      updateUserDetails(
        userId,
        userData,
        setSeverity,
        setMessage,
        setAlertOpen,
        handleFormClose
      );
    };
    return (
      <>
        <Dialog open={formOpen} onClose={handleFormClose}>
            <DialogTitle>Update User</DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              required
              fullWidth
              id="fullname"
              label="Full Name"
              name="fullname"
              value={userData.full_name}
              autoFocus
              onChange={(e) => {
                setUserData({ ...userData, full_name: e.target.value });
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="Phone Number"
              label="Phone Number"
              name="Phone Number"
              value={userData.phone_no}
              disabled={true}
              autoFocus
              onChange={(e) => {
                setUserData({ ...userData, phone_no: e.target.value });
              }}
            />
            <TextField
      type={showPassword ? 'text' : 'password'}
      label="Password"
      required
      fullWidth
      value={userData.password}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        setUserData({ ...userData, password: e.target.value });
      }}
    />
          </DialogContent>
          <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                // sx={{ mt: 3, mb: 2 }}
                onClick={handleUpdate}
              >
                Update User
              </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  