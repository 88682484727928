import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  Switch,
  IconButton,
  Autocomplete
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { createUser, updateUser, getuserdata, createexistingUser } from "../../../../../api/user";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from '@mui/material/InputAdornment';
import { UserContext } from "../../../../../context/UserContext";

export default function CrudUser({
  formOpen,
  handleFormClose,
  setAlertOpen,
  setSeverity,
  setMessage,
  update,
  userData,
  setUserData,
  groupData,
  userId,
  formState,
  setFormState,
  roleData,
  siteId
}) {
  const [existinguser, setexistinguser] = useState(false);
  const [userid, setuserid] = useState();
  const [useroptions, setuseroptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useContext(UserContext).userData;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (user &&
      (user?.isSuperAdmin === true ||
        user?.role?.permissions.includes(
          "FETCH_USERS"
        ))) {
      getuserdata(setuseroptions);
    }
  }, [existinguser, userData])
  const handleSubmit = () => {
    // code to register user
    if (existinguser === true) {
      let user_data = { user_id: userid._id, company_id: userData.company_id, group_array: formState.groupArray, role_id: userData.role_id };
      createexistingUser(
        user_data,
        setSeverity,
        setMessage,
        setAlertOpen,
        handleFormClose
      );
    } else {
      let user_data = { ...userData, group_array: formState.groupArray };
      createUser(
        user_data,
        setSeverity,
        setMessage,
        setAlertOpen,
        handleFormClose
      );
    }
  };

  const handleUpdate = () => {
    let user_data = { ...userData, group_array: formState.groupArray, company_id: siteId };
    updateUser(
      userId,
      user_data,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose
    );
  };

  const handleFieldChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };
  const handleChange = (e) => {
    setexistinguser(e.target.checked);
  };
  const handleuserchange = (e, value) => {
    console.log(value, 'value')
    setuserid(value);
  }
  return (
    <>
      <Dialog open={formOpen} onClose={handleFormClose}>
        {update ? (
          <DialogTitle>Update User</DialogTitle>
        ) : (
          <DialogTitle>Create new User</DialogTitle>
        )}
        <DialogContent style={{ minWidth: "400px" }}>
          {!update && user &&
            (user?.isSuperAdmin === true ||
              user?.role?.permissions.includes(
                "FETCH_USERS"
              )) &&
            <>
              <label>Choose Existing User</label>
              <Switch
                onChange={handleChange}
                color="primary"
                inputProps={{ "aria-label": "toggle switch" }}
              />
            </>
          }
          {existinguser ?
            <>
              <Autocomplete
                select
                margin="normal"
                fullWidth
                options={useroptions}
                getOptionLabel={(option) => option.full_name + " - " + option.phone_no}
                label="Select User"
                onChange={handleuserchange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User Name"
                    variant="outlined"
                  />
                )}

              />

              <TextField
                margin="normal"
                disabled
                fullWidth
                id="fullname"
                label="Full Name"
                name="fullname"
                value={userid?.full_name || ""}
                autoFocus
                onChange={(e) => {
                  setUserData({ ...userData, full_name: e.target.value });
                }}
              />
              <TextField
                margin="normal"
                disabled
                fullWidth
                id="Phone Number"
                label="Phone Number"
                name="Phone Number"
                value={userid?.phone_no || ""}
                autoFocus
                onChange={(e) => {
                  setUserData({ ...userData, phone_no: e.target.value });
                }}
              />
            </>
            : <>
              {!update &&
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="fullname"
                    label="Full Name"
                    name="fullname"
                    value={userData.full_name}
                    autoFocus
                    onChange={(e) => {
                      setUserData({ ...userData, full_name: e.target.value });
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="Phone Number"
                    label="Phone Number"
                    name="Phone Number"
                    value={userData.phone_no}
                    disabled={update}
                    autoFocus
                    onChange={(e) => {
                      setUserData({ ...userData, phone_no: e.target.value });
                    }}
                  />

                </>
              }
            </>
          }
          {!existinguser && (
            <TextField
              type={showPassword ? 'text' : 'password'}
              label="Password"
              required
              fullWidth
              value={userData.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setUserData({ ...userData, password: e.target.value });
              }}

            />
          )}
          <TextField
            select
            margin="normal"
            fullWidth
            label="Group Name"
            name="groupArray"
            id="groupArray"
            SelectProps={{
              multiple: true,
              value: formState.groupArray,
              onChange: handleFieldChange,
            }}
          >
            {groupData &&
              groupData.groupData.map((data) => {
                return <MenuItem value={data._id} key={data._id}>{data.group_name}</MenuItem>;
              })}
          </TextField>

          <TextField
            select
            margin="normal"
            fullWidth
            label="Role Name"
            value={userData.role_id ? userData.role_id : ""}
            onChange={(e) => {
              setUserData({ ...userData, role_id: e.target.value });
            }}
          >
            <MenuItem value={null} style={{ color: "red" }}>
              Remove user's Role
            </MenuItem>
            {roleData &&
              roleData.roleData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.role_name}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          {update ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // sx={{ mt: 3, mb: 2 }}
              onClick={handleUpdate}
            >
              Update User
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {existinguser ? "Add existing user" : " Create New User"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}