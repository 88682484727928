/* eslint-disable no-unused-vars */
// Log filters

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { getAllVersionList } from "../../../api/version";
import { getAllBatchList } from "../../../api/batch";
import { getAllDesignList } from "../../../api/design";

export default function BactchFilter({
  filterOpen,
  handleFilterClose,
  filterData,
  setFilterData,
  setFilterCount,
}) {
  const [batchData, setBatchData] = useState([]);
  const [designData, setDesignData] = useState([]);

  useEffect(() => {
    getAllDesignList(setDesignData, "false");
    getAllBatchList(setBatchData, "false");
  }, []);

  return (
    <>
      <Dialog
        open={filterOpen}
        onClose={handleFilterClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Filter Devices"}</DialogTitle>
        <DialogContent>
        <TextField
            label="Is Deleted"
            name="Is Deleted"
            select
            fullWidth
            margin="normal"
            value={filterData.deleted}
            onChange={(e) => {
              setFilterData({ ...filterData, deleted: e.target.value });
            }}
          >
            <MenuItem key={"false"} value={false}>
              NO
            </MenuItem>
            <MenuItem key={"true"} value={true}>
              YES
            </MenuItem>
          </TextField>
          <TextField
  label="Created At"
  name="Created At"
  type="date"
  fullWidth
  margin="normal"
  value={filterData.createdAt}
  onChange={(e) => {
    setFilterData({ ...filterData, createdAt: e.target.value });
  }}
  InputLabelProps={{
    shrink: true,
  }}
/>

        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={(e) => {
              setFilterData({
                version: "",
                assigned: "",
                batch: "",
                design: "",
              });
              handleFilterClose();
              setFilterCount(0);
            }}
          >
            Reset Filter
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              handleFilterClose();
            }}
          >
            Set filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
