import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
  import React from "react";
import { deleteRole } from "../../../../../api/role";
  
  export default function DeleteRole({
    deleteForm,
          roleId,
          siteId,
          setAlertOpen,
          setSeverity,
          setMessage,
          handleFormClose
  }) {
    const handleDeleteRole = () => {
        deleteRole(roleId,
            siteId,
            setSeverity,
            setMessage,
            setAlertOpen,
            handleFormClose,
            );
    };
    return (
      <>
        {/* Remove device confirmation dialog */}
  
        <Dialog
          open={deleteForm}
          onClose={handleFormClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Remove Role"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the Role
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFormClose}>No</Button>
            <Button color="error" onClick={handleDeleteRole}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  