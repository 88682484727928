/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import {
  Search as SearchIcon,
  FilterList,
  AddBox,
  CheckCircle,
  ArrowCircleDown,
  Cancel,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import {
  deleteVersionById,
  getAllVersionList,
  pullVersionLocal,
} from "../../../../../api/version";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "code", label: "Version Code" },
  { id: "updates", label: " Updates Pushed" },
  { id: "updated_at", label: "Updated At" },
  { id: "available", label: "Local Availability" },
  { id: "update", label: "Update Version" },
  { id: "delete", label: "Delete Version" },
];

const VersionTable = ({
  setSeverity,
  setMessage,
  setAlertOpen,
  reload,
  setFormOpen,
  setUpdateData,
  setUpdateOpen,
  setReload,
  setFeatureData,
}) => {
  const { designId } = useParams();
  const [loading, setLoading] = useState(true);

  const [versionTableData, setVersionTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const { userData } = useContext(UserContext);

  React.useEffect(() => {
    getAllVersionList(
      setVersionTableData,
      "true",
      designId,
      page,
      rowsPerPage,
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading
    );
  }, [reload, page, rowsPerPage]);

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getLocalVersion = (id) => {
    pullVersionLocal(setSeverity, setAlertOpen, setMessage, setLoading, id);
  };

  const deleteVersion = (id) => {
    deleteVersionById(
      id,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading,
      setReload,
      reload
    );
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Version Table</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            <IconButton>
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton>
            {(userData?.user?.isSuperAdmin===true||(userData?.user?.role?.permissions.includes("CREATE_VERSION")&&userData?.user?.role?.permissions.includes("FETCH_FEATURES")))&&<IconButton>
              <Tooltip title="Create New Version" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {versionTableData.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        {row.version_id}
                      </TableCell>
                      <TableCell>{row.updates}</TableCell>

                      <TableCell>
                        {new Date(row.updatedAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {row.is_local === true ? (
                          <>
                            <CheckCircle color="success" />
                          </>
                        ) : (
                          <>
                            {row.file_data === undefined ? (
                              <Tooltip title="No version file available">
                                <IconButton>
                                  <Cancel
                                    sx={{
                                      color: "red",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <IconButton
                                aria-label="pull"
                                onClick={() => {
                                  getLocalVersion(row._id);
                                }}
                              >
                                <ArrowCircleDown
                                  sx={{
                                    color: "blue",
                                  }}
                                />
                              </IconButton>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin===true||(userData?.user?.role?.permissions.includes("UPDATE_VERSION")&&
                              userData?.user?.role?.permissions.includes(
                                "FETCH_FEATURES"
                              )))}
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            setUpdateData({
                              _id: row._id,
                              version_id: row.version_id,
                              description: row.updates,
                            });
                            setFeatureData(row.features||[]);
                            setUpdateOpen(true);
                          }}
                        >
                          Update Version
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_VERSION"))}
                          color="error"
                          variant="outlined"
                          onClick={() => {
                            deleteVersion(row._id);
                          }}
                        >
                          Delete Version
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default VersionTable;
