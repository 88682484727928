import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { createPlan } from "../../../api/plans";

export default function CreatePlan({
  dialog,
  handleClose,
  newPlan,
  setNewPlan,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {
  const [loading,setLoading]=useState(false);
  const createNewPlan = () => {
    setLoading(true);
    createPlan(newPlan, setSeverity, setMessage, setAlertOpen, handleClose,setLoading);
  };
  return (
    <>
      <Dialog open={dialog} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Create New Plan</DialogTitle>

        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="plan_code"
            label="Plan Code"
            value={newPlan.plan_code}
            onChange={(e) => {
              setNewPlan({ ...newPlan, plan_code: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="planname"
            label="Plan Name"
            value={newPlan.plan_name}
            onChange={(e) => {
              setNewPlan({ ...newPlan, plan_name: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            id="users"
            label="Users Allowed"
            value={newPlan.users_allowed}
            onChange={(e) => {
              setNewPlan({ ...newPlan, users_allowed: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            id="groups"
            label="Groups Allowed"
            value={newPlan.groups_allowed}
            onChange={(e) => {
              setNewPlan({ ...newPlan, groups_allowed: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="price"
            label="Price (in Rs)"
            value={newPlan.price}
            onChange={(e) => {
              setNewPlan({ ...newPlan, price: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="time_period"
            label="Time Period (in days)"
            value={newPlan.time_period}
            onChange={(e) => {
              setNewPlan({ ...newPlan, time_period: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="plan_type"
            label="Plan Type"
            select
            value={newPlan.plan_type}
            onChange={(e) => {
              setNewPlan({ ...newPlan, plan_type: e.target.value });
            }}
          >
            <MenuItem value="basic">Basic</MenuItem>
            <MenuItem value="advanced">Advance</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={createNewPlan}>
            {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create Plan"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
