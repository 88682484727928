/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  Snackbar,
  Alert,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { getAllTransactions } from "../../../api/transaction";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "id", label: "Transaction Id" },
  { id: "amount", label: "Amount Paid (in Rs)" },
  { id: "paid_by", label: "Paid By" },

  { id: "plan", label: "Plan Details" },
  { id: "date", label: "Paid Date" },
];

const TransactionTable = ({ siteId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    getAllTransactions(
      setRows,
      setLoading,
      siteId,
      page,
      rowsPerPage,
      setTotal,
      searchQuery
    );
  }, [rowsPerPage, page]);

  // useEffect(() => {
  //   // Filter data based on search query after a delay
  //   const timer = setTimeout(() => {
  //     const filtered = rows.filter((row) =>
  //       row.transaction_id.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setFilteredData(filtered);
  //   }, 300); // Adjust the delay time as needed

  //   return () => clearTimeout(timer);
  // }, [searchQuery, rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getAllTransactions(
        setRows,
        setLoading,
        siteId,
        0,
        rowsPerPage,
        setTotal,
        event.target.value
      );
    }, 1000);
    
  };
  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={"error"}
          sx={{ width: "100%" }}
        >
          Error in fetching transactions. Please try after some time
        </Alert>
      </Snackbar>

      {loading ? (
        "loading table..."
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <div
            style={{
              margin: "16px 8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Company Transactions</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Transactions"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                onChange={handleSearchChange}
                />
              </Search>
              {/* <Button onClick={fetchTransactions} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
              {/* <IconButton>
                <Tooltip title="Filters" arrow placement="top">
                  <FilterList />
                </Tooltip>
              </IconButton> */}
              {/* <IconButton>
          <Tooltip title="Add Plans" arrow placement="top">
            <AddBox onClick={handleFormOpen} />
          </Tooltip>
        </IconButton> */}
            </div>
          </div>

          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.transaction_id}
                      </TableCell>
                      <TableCell>{row.amount}</TableCell>
                      <TableCell>{row.paid_by.name}</TableCell>
                      <TableCell>
                        {row.plan_details.plan_name +
                          "/" +
                          row.plan_details.plan_code}
                      </TableCell>
                      <TableCell>
                        {new Date(row.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
};

export default TransactionTable;
