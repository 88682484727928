import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { createNewVersion } from "../../../api/version";
import { FileUploadOutlined } from "@mui/icons-material";

export default function CreateVersion({
  dialog,
  handleClose,
  versionData,
  setVersionData,
  setSeverity,
  setMessage,
  setAlertOpen,
  attachment,
  setAttachment,
}) {
  const [loading,setLoading]=useState(false);
  const handleCreateVersion = () => {
    setLoading(true);
    const form = new FormData();
    form.append("version_id", versionData.version_id);
    form.append("description", versionData.description);
    form.append("file", attachment);
    createNewVersion(form, setSeverity, setMessage, setAlertOpen, handleClose,setLoading);
  };

  return (
    <>
      {/* Dialog to create new Batch */}

      <Dialog open={dialog} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Create New Version</DialogTitle>

        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="version_code"
            label="Version Code"
            value={versionData.version_id}
            onChange={(e) => {
              setVersionData({ ...versionData, version_id: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            value={versionData.description}
            onChange={(e) => {
              setVersionData({ ...versionData, description: e.target.value });
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Version bin file"
            value={
              attachment
                ? attachment.name +
                  " - " +
                  (attachment.size / 1000).toString() +
                  " Kb"
                : ""
            }
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton component="label">
                  <FileUploadOutlined />
                  <input
                    styles={{ display: "none" }}
                    type="file"
                    hidden
                    onChange={(e) => {
                      setAttachment(e.target.files[0]);
                    }}
                    name="files"
                  />
                </IconButton>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCreateVersion}
          >
            {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create Version"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
