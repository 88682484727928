import React, { useContext } from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import NotificationDropdown from "../../pages/Notification/NotificationDropdown";

const Navbar = ({ drawerWidth, handleDrawerToggle }) => {
  let navigate = useNavigate();
  const { setUserData } = useContext(UserContext);

  const handleLogout = () => {
    localStorage.clear();

    setUserData("");

    navigate("/login");
  };
  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={0}
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar style={{backgroundColor:`${window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(max-width: 767px)").matches?"rgb(33, 50, 64)":"none"}`}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <img
            src="/assets/logo/super-admin-logo-white-2.svg"
            alt="Luminox"
            style={{
              width: "auto",
              height: 44,
            }}
          />
        </IconButton>
        <Typography sx={{ flexGrow: 1 }} />
        <NotificationDropdown />
        <IconButton color="inherit" onClick={handleLogout}>
          <Logout style={{"color":`${window.innerWidth < 720?"white":"#000000"}`}}/>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
