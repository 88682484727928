import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { FileUploadOutlined } from "@mui/icons-material";
import { createNewVersion } from "../../../../../api/version";
import { useParams } from "react-router-dom";

export default function CreateVersion({
  dialog,
  handleClose,
  versionData,
  setVersionData,
  setSeverity,
  setMessage,
  setAlertOpen,
  attachment,
  setAttachment,
  features,
  featureData,
  setFeatureData,
}) {
  const { designId } = useParams();
  const [loading, setLoading] = useState(false)

  const handleCreateVersion = () => {
    setLoading(true)
    const form = new FormData();
    form.append("version_id", versionData.version_id);
    form.append("design_id", designId);
    form.append("description", versionData.description);
    form.append("file", attachment);
    featureData.map((fd) => {
      form.append("features", fd);
    })
    createNewVersion(form, setSeverity, setMessage, setAlertOpen, handleClose, setLoading);
  };
  const handleChange = (event, value) => {
    setFeatureData(value.map(option => option._id));
  };
  return (
    <>
      {/* Dialog to create new Batch */}

      <Dialog open={dialog} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Create New Version</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="version_code"
            label="Version Code"
            value={versionData.version_id}
            onChange={(e) => {
              setVersionData({ ...versionData, version_id: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            value={versionData.description}
            onChange={(e) => {
              setVersionData({ ...versionData, description: e.target.value });
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Version bin file"
            value={
              attachment
                ? attachment.name +
                " - " +
                (attachment.size / 1000).toString() +
                " Kb"
                : ""
            }
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton component="label">
                  <FileUploadOutlined />
                  <input
                    styles={{ display: "none" }}
                    type="file"
                    hidden
                    onChange={(e) => {
                      setAttachment(e.target.files[0]);
                    }}
                    name="files"
                  />
                </IconButton>
              ),
            }}
          />

          <Autocomplete
            fullWidth
            style={{ paddingTop: "10px" }}
            multiple
            options={features}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Features"
                variant="outlined"
              />
            )}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCreateVersion}
          >
            {loading ? "Creating.." : "Create Version"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
