import axiosIntance from "../helper/axios";

const createFeature = (
  featureData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/feature/create", featureData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      handleFormClose();
      setLoading(false);
    });
};

const updateFeature = (
  featureId,
  featureData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .put(`/feature/update/${featureId}`, featureData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      // console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const deletefeature = (
  featureId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setDeleteLoading
) => {
  axiosIntance
    .delete(`/feature/delete/${featureId}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setDeleteLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setDeleteLoading(false);
    });
};

const getFeatures = (
  setData,
  setLoading,
  page,
  limit,
  table,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  searchQuery,
  filterData
) => {

  if (table === "true") {
    axiosIntance
      .get(
        `/feature/all?page=${page + 1}&limit=${limit}&table=${table}&searchTerm=${searchQuery}&isDeleted=${filterData.deleted}`
      )
      .then((res) => {
        setData(res.data.data.feature_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.error);
        setAlertOpen(true);
        setSeverity("error");
        setMessage(err.response.data.error);
      });
  } else {
    if(setLoading)setLoading(true);
    axiosIntance
      .get(`/feature/all/?table=${table}`)
      .then((res) => {
        if(setLoading)setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        if(setLoading)setLoading(false);
        console.log(err.response.data.error);
        setData({});
      });
  }
};

export { getFeatures,createFeature,updateFeature,deletefeature };
