/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
  Badge,
} from "@mui/material";
import { Search as SearchIcon, FilterList, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { deleteBatchById, getAllBatchList, restoreBatchById } from "../../../api/batch";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "code", label: "Batch Code" },
  { id: "name", label: " Batch Name" },
  { id: "description", label: "Description" },
  { id: "created_at", label: "Created At" },
  { id: "action", label: "Action" },
];

const BatchTable = ({ setSeverity, setMessage, setAlertOpen,reload,setFormOpen,filterCount,setFilterOpen,filterData,setReload }) => {
  const [loading, setLoading] = useState(true);

  const [batchData, setBatchData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const { userData } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    getAllBatchList(
      setBatchData,
      searchQuery,
      "true",
      page,
      rowsPerPage,
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading,
      filterData
    );
  }, [reload, page, rowsPerPage]);

  const deleteBatch = (id) => {
    deleteBatchById(
      id,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading,
      setReload,
      reload
    );
  };
  const restoreBatch = (id) => {
    restoreBatchById(
      id,
      setSeverity,
      setAlertOpen,
      setMessage,
      setLoading,
      setReload,
      reload
    );
  };

  const handleFormOpen = () => {
    setFormOpen(true);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // useEffect(() => {
  //   // Filter data based on search query after a delay
  //   const timer = setTimeout(() => {
  //     const filtered = batchData.filter((row) =>
  //       row.batch_name.toLowerCase().includes(searchQuery.toLowerCase())||
  //       row.batch_code.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setFilteredData(filtered);
  //   }, 300); // Adjust the delay time as needed

  //   return () => clearTimeout(timer);
  // }, [searchQuery, batchData]);
  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getAllBatchList(
        setBatchData,
        event.target.value,
        "true",
        0,
        rowsPerPage,
        setTotal,
        setSeverity,
        setAlertOpen,
        setMessage,
        setLoading,
        filterData
      );
    }, 1000);
  };
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Batch Table</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {/* <Button onClick={fetchBatches} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
             <Badge badgeContent={filterCount} color="primary">
              <IconButton
                onClick={(e) => {
                  setFilterOpen(true);
                }}
              >
                <Tooltip title="Filters" arrow placement="top">
                  <FilterList />
                </Tooltip>
              </IconButton>
            </Badge>
            {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("CREATE_BATCH"))&&<IconButton>
              <Tooltip title="Create New Batch" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {batchData.map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        {row.batch_code}
                      </TableCell>
                      <TableCell>{row.batch_name}</TableCell>
                      <TableCell>
                        {row.batch_description === ""
                          ? "--"
                          : row.batch_description}
                      </TableCell>

                      <TableCell>
                        {new Date(row.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>
                      {row.is_deleted===false?<TableCell>
                        <Button
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_BATCH"))}
                          color="error"
                          variant="outlined"
                          onClick={() => {
                            deleteBatch(row._id);
                          }}
                        >
                          Delete Batch
                        </Button>
                      </TableCell>:<TableCell>
                        <Button
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_BATCH"))}
                          color="error"
                          variant="outlined"
                          onClick={() => {
                            restoreBatch(row._id);
                          }}
                        >
                          Restore Batch
                        </Button>
                      </TableCell>}
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </>
  );
};

export default BatchTable;
