// Device APIs

import axiosIntance from "../helper/axios";

const getDeviceCompanyWise = (setDeviceData, setLoading) => {
  axiosIntance
    .get("/device/companyWise")
    .then((res) => {
      setDeviceData(res.data.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err, "error");
    });
};

const simDetails = (
  mobile_no,
  setSimData,
  setDialogLoad,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  setDialogLoad(true);
  axiosIntance
    .get(`/sim/getDetails/${mobile_no}`)
    .then((res) => {
      setSimData(res.data.data.data);
      setDialogLoad(false);
    })
    .catch((err) => {
      console.log("error in fetching data", err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setDialogLoad(false);
    });
};

const getDeviceLogs = async (
  deviceId,
  page,
  rowsPerPage,
  filter,
  setLogData,
  setTotal,
  setTableLoading,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  try {
    const { data } = await axiosIntance.get(
      `/logs/device/${deviceId}?page=${page + 1}&limit=${rowsPerPage}&action=${filter.action
      }&start_time=${filter.start_time}&end_time=${filter.end_time}`
    );
    setLogData(data.data.logs);
    // console.log(data.data.logs);
    setTotal(data.data.metadata.total_results);
    setTableLoading(false);
  } catch (error) {
    setAlertOpen(true);
    setSeverity("error");
    setMessage(error.response.data.error);
    setTableLoading(false);
  }
};

const getEnergyLogs = async (
  deviceId,
  page,
  rowsPerPage,
  filter,
  setLogData,
  setTotal,
  setTableLoading,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  try {
    const { data } = await axiosIntance.get(
      `/energy/device/${deviceId}?page=${page + 1
      }&limit=${rowsPerPage}&action=${filter.action}&start_time=${filter.start_time
      }&end_time=${filter.end_time}`
    );
    setLogData(data.data.logs);
    if (setTotal) setTotal(data.data.metadata.total_results);
    setTableLoading(false);
  } catch (error) {
    setAlertOpen(true);
    setSeverity("error");
    setMessage(error.response.data.error);
    setTableLoading(false);
  }
};

const registerDevice = (
  deviceData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/device/register", deviceData)
    .then((res) => {
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const registerbulkDevice = (
  deviceData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handlebulkFormClose,
  setLoading
) => {
  axiosIntance
    .post("/device/register/bulk", deviceData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handlebulkFormClose();
      setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};

const updateDevice = (
  deviceId,
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .put(`/device/update/${deviceId}`, data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const updateDeviceMetering = (
  deviceId,
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  setButtonLoading,
  handleFormClose
) => {
  axiosIntance
    .put(`/device/update/metering/${deviceId}`, data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setButtonLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setButtonLoading(false);
    });
};

const blockDevice = (
  deviceId,
  block,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .put(`/device/block/${deviceId}?block=${block}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      setTimeout(() => {
        handleFormClose();
      }, 500);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const autoModeVisible = (
  deviceId,
  auto,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .put(`/device/${deviceId}/show?auto=${auto}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const publishInitialData = (
  deviceId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .post(`/device/publish/initial/${deviceId}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};


const getSimLocation = (
  deviceId,
  mobileNo,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .get(`/sim/getLocation/${mobileNo}/${deviceId}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};


const getDevicesOfCompany = (
  companyId,
  setDeviceData,
  setLoading,
  page,
  limit,
  table,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  setCommonData,
  commonData,
  searchQuery,
  filterData
) => {
  // setLoading(true);
  if (table === "true") {
    axiosIntance
      .get(
        `/device/company/${companyId}?page=${page + 1
        }&limit=${limit}&table=${table}&searchTerm=${searchQuery}&has_metering=${filterData?.has_metering}&version=${filterData?.version}`
      )
      .then((res) => {
        setDeviceData(res.data.data.device_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.error);
        setAlertOpen(true);
        setSeverity("error");
        setMessage(err.response.data.error);
      });
  } else {
    setLoading(true);
    axiosIntance
      .get(`/device/company/${companyId}?table=${table}`)
      .then((res) => {
        setLoading(false);
        setCommonData({ ...commonData, deviceData: res.data.data });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.error);
        setCommonData({ ...commonData, deviceData: 0 });
      });
  }
};

const getDeviceList = (
  setDeviceData,
  setLoading,
  table,
  page,
  limit,
  filterData,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  selectedCompanies,
  searchQuery
) => {
  // console.log(searchQuery);
  if (table === "true") {
    // setLoading(true);
    axiosIntance
      .get(
        `/device/list?table=${table}&page=${page + 1}&limit=${limit}&version=${filterData.version
        }&assigned=${filterData.assigned}&batch=${filterData.batch}&design=${filterData.design}&createdAt=${filterData.createdAt}&searchTerm=${searchQuery}`
      )
      .then((res) => {
        setDeviceData(res.data.data.device_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setMessage("Error in fetching device list");
        setAlertOpen(true);
      });
  } else {
    axiosIntance
      .get(`/device/list?selectedCompanies=${selectedCompanies}`)
      .then((res) => {
        setDeviceData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
const getDeviceStats = (setDevices, setLoading) => {
  axiosIntance
    .get(`/device/list`)
    .then((res) => {
      setDevices(res.data.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });
};
const getDeviceMeteringData = (device_id, setDeviceData, setDeviceState, setSeverity, setAlertOpen, setMessage, setLoading) => {
  axiosIntance
    .get(`/device/metering/${device_id}`)
    .then((res) => {
      setDeviceData(res.data.data);
      setDeviceState({ ...res.data.data.device_data, show_auto: res.data.data.show_auto });
      setLoading(false);
    })
    .catch((err) => {
      setSeverity("error");
      setMessage("Error in fetching data");
      setAlertOpen(true);
      console.log(err);
    });
};

const getUnAssignedDevices = (
  setDevices,
  setLoading,
  setSeverity,
  setAlertOpen,
  setMessage
) => {
  axiosIntance
    .get("/device/unassigned/all")
    .then((res) => {
      setDevices(res.data.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Error in fetching device list");
    });
};

const assignDeviceToCompany = (
  device_id,
  company_id,
  setSeverity,
  setAlertOpen,
  setMessage,
  handleFormClose,
  setButtonLoading
) => {
  axiosIntance
    .put(`/device/assign/company`, { devices: device_id, company_id: company_id })
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
      setButtonLoading(false);
    })
    .catch((err) => {
      // console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err?.response?.data?.error);
      setButtonLoading(false);
    });
};

const removeDeviceFromCompany = (
  device_id,
  company_id,
  setSeverity,
  setAlertOpen,
  setMessage,
  handleFormClose
) => {
  axiosIntance
    .put(`/device/remove/${device_id}/company`, { company_id: company_id })
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Error in assigning device ");
    });
};

const updateDeviceVersion = (
  device_id,
  version_id,
  setSeverity,
  setAlertOpen,
  setMessage,
  handleFormClose
) => {
  axiosIntance
    .put(`/device/update/version/${device_id}`, { version_id: version_id })
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
    });
};

const updateDeviceState = (
  data,
  setSeverity,
  setAlertOpen,
  setMessage,
  handleFormClose,
  setStateOpen
) => {
  axiosIntance
    .put(`/device/state/update`, data)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
      if (setStateOpen) setStateOpen(false);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Error in updating device state ");
    });
};

const getCentralStats = (setStatData, setLoading) => {
  axiosIntance
    .get("/device/central/stats")
    .then((res) => {
      setStatData(res.data.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err, "error");
    });
};

const getDeviceSignalStrength = (
  device_code,
  setSignal,
  setDialogLoad,
  setSeverity,
  setAlertOpen,
  setMessage
) => {
  setDialogLoad(true);
  axiosIntance
    .get(`/device/signal/strength/${device_code}`)
    .then((res) => {
      setSignal(res.data.data);
      setDialogLoad(false);
    })
    .catch((err) => {
      console.log(err, "error");
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
    });
};

const resetDevice = (
  setSeverity,
  setAlertOpen,
  setMessage,
  handleFormClose,
  setStateOpen,
  data
) => {
  axiosIntance
    .post(`/device/reset`, data)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
      if (setStateOpen) setStateOpen(false);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Error in resetting device ");
    });
};
const deleteDevice = (
  setSeverity,
  setAlertOpen,
  setMessage,
  setChange,
  device_id
) => {
  axiosIntance
    .delete(`/device/delete/${device_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setChange(true);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Error in deleting device ");
    });
};
const testDevices = (
  selecteddevices,
  ontime,
  offtime,
  setSeverity,
  setAlertOpen,
  setMessage,
  settestingstatus
) => {
  const data = {
    deviceId: selecteddevices,
    ontime: ontime,
    offtime: offtime,
  };
  axiosIntance
    .post(`/device/test`, data)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      settestingstatus(true);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Error in Testing device ");
      settestingstatus(false);
    });
};
const stopTestDevices = (
  selecteddevices,
  setSeverity,
  setAlertOpen,
  setMessage,
  settestingstatus,
  setselecteddevices
) => {
  const data = {
    deviceId: selecteddevices,
  };
  axiosIntance
    .post(`/device/test/stop`, data)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      settestingstatus(false);
      setselecteddevices([]);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Error in Stop Testing device ");
      settestingstatus(true);
    });
};
const checkTestDevices = (
  setselecteddevices,
  settestingstatus,
  setLoading,
  setSeverity,
  setAlertOpen,
  setMessage,
  settestingtime
) => {
  axiosIntance
    .get(`/device/test/check`)
    .then((res) => {
      setselecteddevices(res.data.data);
      settestingtime(res.data.timer);
      res.data.data.length > 0
        ? settestingstatus(true)
        : settestingstatus(false);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage("Error in Fetching Testing device ");
      setLoading(false);
    });
};

const getTestingLogs = async (
  page,
  rowsPerPage,
  filter,
  setLogData,
  setTotal,
  setLogTableLoading,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  try {
    const { data } = await axiosIntance.get(
      `/logs/testing?page=${page + 1}&limit=${rowsPerPage}&action=${filter.action
      }&start_time=${filter.start_time}&end_time=${filter.end_time}`
    );
    setLogData(data.data.logs);
    // console.log(data.data.logs);
    setTotal(data.data.metadata.total_results);
    setLogTableLoading(false);
  } catch (error) {
    setAlertOpen(true);
    setSeverity("error");
    setMessage(error.response.data.error);
    setLogTableLoading(false);
  }
};

const fetchTestingStats = async (
  data,
  setAlertOpen,
  setSeverity,
  setMessage,
  testing_code
) => {
  try {
    const res = await axiosIntance.post(`/device/test/stats`, data, {
      responseType: "blob",
    });
    // setLogData(data.data.logs);
    // // console.log(data.data.logs);
    // setTotal(data.data.metadata.total_results);
    // setLogTableLoading(false);
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${testing_code}.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    setAlertOpen(true);
    setSeverity("error");
    setMessage(error.response.data.error);
    // setLogTableLoading(false);
  }
};

const syncSimDetails = async (
  device_code,
  setSeverity,
  setAlertOpen,
  setMessage
) => {
  axiosIntance
    .post(`/device/sync/sim`, { device_code_array: device_code })
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
    });
};

const syncMsisdnDetails = async (
  file,
  setSeverity,
  setAlertOpen,
  setMessage,
  handleFormClose
) => {
  const formData = new FormData();
  formData.append('file', file);
  axiosIntance
    .post(`/device/sync/msisdn`, formData)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
    });
};

const downloadInventory = async (setSeverity, setAlertOpen, setMessage) => {
  let url = process.env.REACT_APP_BASE_URL + "/api/kt/v1/superAdmin/device/sim/inventory";
  console.log(url);
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/csv",
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `sim_inventory.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setMessage(err.response.data.error);
    });
};

const syncDeviceEnergy = async (device_id, setSyncLoading, setSeverity, setAlertOpen, setMessage, handleFormClose) => {
  axiosIntance
    .post(`/energy/sync/${device_id}`)
    .then((res) => {
      setSeverity("success");
      setAlertOpen(true);
      setMessage(res.data.message);
      setSyncLoading(false);
      handleFormClose()
    })
    .catch((err) => {
      console.log(err);
      setSeverity("error");
      setAlertOpen(true);
      setSyncLoading(false);
      setMessage(err.response.data.error);
    });
};

export {
  getDeviceCompanyWise,
  simDetails,
  getDeviceLogs,
  registerDevice,
  registerbulkDevice,
  updateDevice,
  blockDevice,
  publishInitialData,
  getDevicesOfCompany,
  getDeviceList,
  getUnAssignedDevices,
  assignDeviceToCompany,
  removeDeviceFromCompany,
  updateDeviceVersion,
  updateDeviceState,
  updateDeviceMetering,
  autoModeVisible,
  getCentralStats,
  getDeviceSignalStrength,
  resetDevice,
  deleteDevice,
  getEnergyLogs,
  getDeviceStats,
  testDevices,
  stopTestDevices,
  checkTestDevices,
  getTestingLogs,
  fetchTestingStats,
  syncSimDetails,
  downloadInventory,
  syncMsisdnDetails,
  getDeviceMeteringData,
  syncDeviceEnergy,
  getSimLocation
};
