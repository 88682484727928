import { Alert, Breadcrumbs, Divider, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import CreateDevice from "./elements/CreateDevice";
import DeviceTable from "./elements/DeviceTable";
import UpdateDevice from "./elements/UpdateDevice";
import UpdateVersion from "./elements/UpdateVersion";
import DeviceStats from "./elements/DeviceStat";
import DeviceFilter from "./elements/FilterDevice";
import CreateBulkDevice from "./elements/CreateBulkDevices";
import MsisdnSyncDialog from "./elements/MsisdnSyncDialog";

export default function DeviceDashboard() {
  const navigate = useNavigate();

  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [version, setVersion] = useState();
  const [filterCount, setFilterCount] = useState(0);

  const [formOpen, setFormOpen] = useState(false);
  const [bulkformOpen, setbulkFormOpen] = useState(false);
  const [deviceData, setDeviceData] = useState({
    device_description: "",
    imi_no: "",
    mobile_no: "",
    sim_no: "",
    batch_id: "",
    version_id: "",
    design_id: ""
  });
  const [bulkdeviceData, setbulkDeviceData] = useState({
    device_description: "",
    batch_id: "",
    version_id: "",
  });
  const [selectedDevices, setSelectedDevices] = useState([]);

  const [updateForm, setUpdateForm] = useState(false);
  // const [meteringForm, setMeteringForm] = useState(false);
  const [versionForm, setVersionForm] = useState(false);
  const [deviceId, setDeviceID] = useState();
  const [designId, setDesignId] = useState();

  const [change, setChange] = useState(false);

  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    version: "",
    assigned: "",
    batch: "",
    design: "",
    // deleted:"",
    createdAt: "",
  });
  const [file, setFile] = useState();
  const [dialog, setDialog] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setDeviceData({
      ...deviceData,
      imi_no: "",
      mobile_no: "",
      sim_no: "",
      device_description: "",
      batch_id: "",
      version_id: "",
      design_id: "",
      has_metering:"",
      frequency:"",
      has_phase1:false,
      phase1Totalpower:"",
      phase1IndividualPower:"",
      phase1FaultTolerance:"",
      phase1PowerFactor:"",
      phase1Threshold:"",
      phase1PowerLimit:"",
      phase1OverloadTolerance:"",
      phase1OverloadThreshold:"",
      has_phase2:false,
      phase2Totalpower:"",
      phase2IndividualPower:"",
      phase2FaultTolerance:"",
      phase2PowerFactor:"",
      phase2Threshold:"",
      phase2PowerLimit:"",
      phase2OverloadTolerance:"",
      phase2OverloadThreshold:"",
      has_phase3:false,
      phase3Totalpower:"",
      phase3IndividualPower:"",
      phase3FaultTolerance:"",
      phase3PowerFactor:"",
      phase3Threshold:"",
      phase3PowerLimit:"",
      phase3OverloadTolerance:"",
      phase3OverloadThreshold:"",
    });
    setUpdateForm(false);
    setChange(!change);
    setVersionForm(false);

    setDeviceID();
    setVersion();
    setFilterOpen(false);
    let count = 0;
    if (filterData.design !== "") {
      count = count + 1;
    }
    if (filterData.batch !== "") {
      count = count + 1;
    }
    if (filterData.assigned !== "") {
      count = count + 1;
    }
    if (filterData.createdAt !== "") {
      count = count + 1;
    }
    setFilterCount(count);
    setFile();
    setDialog(false);

  };

  const handlebulkFormClose = () => {
    setbulkFormOpen(false);
    setbulkDeviceData({
      ...deviceData,
      device_description: "",
      batch_id: "",
      version_id: "",
    });
    setUpdateForm(false);
    setChange(!change);
    setVersionForm(false);

    setDeviceID();
    setVersion();
    setFilterOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <PageTitle>Central Inventory</PageTitle>
      <Breadcrumbs aria-label="breadcrumb">
        <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
          Dashboard
        </PathLink>

        <ActiveLink>Devices</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      <DeviceStats />
      <DeviceTable
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setUpdateForm={setUpdateForm}
        setDeviceID={setDeviceID}
        setDeviceData={setDeviceData}
        change={change}
        setFormOpen={setFormOpen}
        setbulkFormOpen={setbulkFormOpen}
        setVersionForm={setVersionForm}
        setFilterOpen={setFilterOpen}
        filterData={filterData}
        setDesignId={setDesignId}
        filterCount={filterCount}
        setFilterCount={setFilterCount}
        setChange={setChange}
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        setDialog={setDialog}
        setFile={setFile}
      />
      <CreateDevice
        formOpen={formOpen}
        handleFormClose={handleFormClose}
        deviceData={deviceData}
        setDeviceData={setDeviceData}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
      />
      <CreateBulkDevice
        bulkformOpen={bulkformOpen}
        handlebulkFormClose={handlebulkFormClose}
        deviceData={bulkdeviceData}
        setDeviceData={setbulkDeviceData}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
      />
      <UpdateDevice
        handleFormClose={handleFormClose}
        updateForm={updateForm}
        deviceData={deviceData}
        setDeviceData={setDeviceData}
        deviceId={deviceId}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
      />
      {/* <UpdateMetering 
      meteringForm={meteringForm}
      deviceData={deviceData}
      setDeviceData={setDeviceData}
      handleFormClose={handleFormClose}
      deviceId={deviceId}
      setSeverity={setSeverity}
      setMessage={setMessage}
      setAlertOpen={setAlertOpen}
      /> */}

      {versionForm && (
        <UpdateVersion
          handleFormClose={handleFormClose}
          version={version}
          versionForm={versionForm}
          deviceId={deviceId}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          setVersion={setVersion}
          designId={designId}
        />
      )}

      {filterOpen && (
        <DeviceFilter
          filterOpen={filterOpen}
          handleFilterClose={handleFormClose}
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterCount={setFilterCount}
        />
      )}
      {dialog && (
        <MsisdnSyncDialog
          dialog={dialog}
          file={file}
          handleFormClose={handleFormClose}
          setSeverity={setSeverity}
          setAlertOpen={setAlertOpen}
          setMessage={setMessage}
        />
      )}
    </>
  );
}
