/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Snackbar,
  Alert,
  Button,
  InputBase,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
} from "@mui/icons-material";
import { fetchKtUserRoles } from "../../../api/role";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { key: "role_name", label: "Role Name", align: "left" },
  { key: "permission", label: "Permissions", align: "left" },
  { key: "update", label: "Update", align: "center" },
  { key: "delete", label: "Delete", align: "center" },
];

const RoleTable = ({
  setRoleData,
  setRoleId,
  setUpdateRoleModal,
  setDeleteRoleModal,
  loading,
  setCreateRoleModal,
  refresh,
  setRefresh,
  formState,
  fetch,
  setFetch,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [roles,setRoles]= useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const { userData } = useContext(UserContext);
  
  const [severity, setSeverity] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [message, setMessage] = useState();
  const [total, setTotal] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const navigate= useNavigate();
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchRoles = async () => {
    setTableLoading(true);

    await fetchKtUserRoles(
      setRoles,
      page,
      rowsPerPage,
      setTotal,
      "true",
  setTableLoading,
  setSeverity,
  setAlertOpen,
  setMessage,
  searchQuery
    );
  };
  useEffect(()=>{
    fetchRoles();
  },[page, rowsPerPage, refresh])
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      fetchKtUserRoles(
        setRoles,
        0,
        rowsPerPage,
        setTotal,
        "true",
    setTableLoading,
    setSeverity,
    setAlertOpen,
    setMessage,
    event.target.value
      );
    }, 1000);
  };
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>

        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
          }}
        >
          <Typography variant="h6">Roles</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search KT Roles"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
          {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("CREATE_KTROLE"))&&<div style={{ display: "flex", alignItems: "left" }}>
            <Button 
            variant="outlined"
            onClick={()=>{
              // setCreateRoleModal(true)
              navigate("/dashboard/role/create");
            }}
            >
            Create New Role
            </Button>
          </div>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableLoading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {roles.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell align="left">{row.role_name}</TableCell>
                      <TableCell align="left">{
                        <Tooltip title={row.permissions.join(', ')}>
                        <div>
                        {row.permissions.slice(0,4).join(', ')}
                        {row.permissions.length > 4 && '...'}
                        </div></Tooltip>}</TableCell>
                      <TableCell align="center">
                        <Button 
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_KTROLE"))}
                        variant="outlined"
                        onClick={()=>{
                          // setRoleId(row._id);
                          // setRoleData(row);
                          // setUpdateRoleModal(true);
                          navigate(`/dashboard/role/update/${row._id}`,{state:{roleData:row}});
                        }}
                        >Update Role</Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button 
                        disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_KTROLE"))}
                        variant="outlined"
                        color="error"
                        onClick={()=>{
                          setRoleId(row._id);
                          setDeleteRoleModal(true);
                        }}
                        >Delete Role</Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Create new device dialog */}
    </>
  );
};

export default RoleTable;
