import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllPlans } from "../../../../../api/plans";
import { createTransaction } from "../../../../../api/transaction";

export default function AddPlan({
  formOpen,
  handleFormClose,
  siteId,
  userData,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {
  const [planData, setPlanData] = useState({
    plan_id: "",
    company_id: siteId,
    paid_by: "",
    amount: "",
    transaction_id: "",
  });
  const [allPlans, setAllPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllPlans("false", setAllPlans, siteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    createTransaction(
      planData,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      setLoading
    );
  };

  return (
    <Dialog open={formOpen} onClose={handleFormClose}>
      <DialogTitle>Add new plan</DialogTitle>

      <DialogContent>
        <TextField
          margin="normal"
          fullWidth
          select
          name="plan"
          id="plan"
          variant="outlined"
          label="Select Plan"
          onChange={(e) => {
            setPlanData({ ...planData, plan_id: e.target.value });
          }}
          value={planData.plan_id}
        >
          {allPlans.map((option) => (
            <MenuItem key={option._id} value={option._id}>
              {option.plan_code +
                " x " +
                option.plan_name +
                " x time period-" +
                option.time_period}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          fullWidth
          select
          name="paid_by"
          id="paid_by"
          variant="outlined"
          label="Paid By"
          onChange={(e) => {
            setPlanData({ ...planData, paid_by: e.target.value });
          }}
          value={planData.paid_by}
        >
          {userData &&
            userData.userData.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.full_name}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          margin="normal"
          label="Amount"
          id="amount"
          variant="outlined"
          fullWidth
          required
          onChange={(e) => {
            setPlanData({ ...planData, amount: e.target.value });
          }}
          value={planData.amount}
        />
        <TextField
          margin="normal"
          label="Transaction Id"
          id="transaction_id"
          variant="outlined"
          fullWidth
          required
          onChange={(e) => {
            setPlanData({ ...planData, transaction_id: e.target.value });
          }}
          value={planData.transaction_id}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          // sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          {loading ? <>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </> : "Add new plan"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
