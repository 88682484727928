import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#90AEC6',
    },
    secondary: {
      main: '#213240',
    },
    light: {
      main: '#f4f9f9',
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
