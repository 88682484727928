/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Alert,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Snackbar,
} from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import PlanTable from "./elements/PlanTable";
import { getPlansByType } from "../../api/plans";
import CreatePlan from "./elements/CreatePlan";
// import UpdatePlan from "./elements/UpdatePlan";

const Plans = () => {
  const [loading, setLoading] = React.useState(true);
  const [planData, setPlanData] = React.useState([]);
  const [dialog, setDialog] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [newPlan, setNewPlan] = useState({
    plan_code: "",
    plan_name: "",
    plan_type: "",
    users_allowed: 1,
    groups_allowed: 1,
    price: "",
    time_period: "",
  });

  const [updateForm, setUpdateForm] = useState(false);
  const [planId, setPlanId] = useState();

  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleClose = () => {
    setDialog(false);
    setReload(!reload);
    setNewPlan({
      plan_code: "",
      plan_name: "",
      plan_type: "",
      users_allowed: 1,
      groups_allowed: 1,
      price: "",
      time_period: "",
    });
    setUpdateForm(false);
  };

  React.useEffect(() => {
    getPlansByType(
      setLoading,
      setPlanData,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  }, [reload]);

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <PageTitle>Plans</PageTitle>
      <Breadcrumbs aria-label="breadcrumb">
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Plans</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={(e) => {
              setDialog(true);
            }}
          >
            Add Plan
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PlanTable
            loading={loading}
            planData={planData}
            setUpdateForm={setUpdateForm}
            setNewPlan={setNewPlan}
            setPlanId={setPlanId}
          />
        </Grid>
      </Grid>

      <CreatePlan
        dialog={dialog}
        newPlan={newPlan}
        handleClose={handleClose}
        setNewPlan={setNewPlan}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setAlertOpen={setAlertOpen}
      />

      {/* <UpdatePlan
        dialog={updateForm}
        newPlan={newPlan}
        handleClose={handleClose}
        setNewPlan={setNewPlan}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setAlertOpen={setAlertOpen}
        planId={planId}
      /> */}
    </>
  );
};

export default Plans;
