import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { updateExpiryDate } from "../../../../../api/company";

export default function UpdateExpiry({
  handleFormClose,
  expiry,
  setExpiry,
  setAlertOpen,
  setMessage,
  setSeverity,
}) {
  const [loading, setLoading] = useState(false);

  const handleUpdateExpiry = () => {
    setLoading(true);
    if (expiry.days <= 0) {
      setAlertOpen(true);
      setSeverity("error");
      setMessage("Days cannot be negative or zero");
      setLoading(false);
    } else {
      updateExpiryDate(
        expiry,
        setSeverity,
        setMessage,
        setAlertOpen,
        handleFormClose,
        setLoading
      );
    }
  };
  return (
    <Dialog open={expiry.open} onClose={handleFormClose}>
      <DialogTitle>Update Expiry Date</DialogTitle>
      <DialogContent>
        <TextField
          label="Number of Days"
          value={expiry.days}
          type="number"
          margin="normal"
          fullWidth
          name="days"
          id="days"
          onChange={(e) => {
            setExpiry({ ...expiry, days: parseInt(e.target.value) });
          }}
        />

        <FormControl color="success">
          <FormControlLabel
            labelPlacement="end"
            label={expiry.add ? "Add Days" : "Remove Days"}
            control={
              <Switch
                checked={expiry.add}
                onChange={(e) => {
                  setExpiry({ ...expiry, add: e.target.checked });
                }}
                inputProps={{ "aria-label": "controlled" }}
                color={expiry.add ? "success" : "error"}
              />
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="outlined"
          color="secondary"
          sx={{ mb: 1 }}
          onClick={handleUpdateExpiry}
        >
          {loading ? "Loading..." : "Update Expiry Date"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
