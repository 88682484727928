import React, { useState } from "react";
import { Alert, Breadcrumbs, Divider, Snackbar } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import { useNavigate } from "react-router-dom";

import CreateModal from "./elements/CreateFeature";
import { deletefeature } from "../../api/feature";
import FeatureTable from "./elements/FeatureTable";
import UpdateFeature from "./elements/UpdateFeature";
import DeleteFeature from "./elements/DeleteFeature";
import FeatureFilter from "./elements/FilterFeature";

const Feature = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [featureId, setFeatureId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [deleteModal,setDeleteModal]= useState(false);
  const [deleteLoading,setDeleteLoading]= useState(false);

  const [featureData,setFeatureData]= useState({
    name:'',
    description:''
  });
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    deleted: false,
  });
  const [features,setFeatures]=useState([]);
  const handleFormClose = () => {
    setCreateModal(false);
    setUpdateModal(false);
    setDeleteModal(false);
    setRefresh(!refresh);
    setFilterOpen(false);
  };
  const closeCreateModal=()=>{
    setCreateModal(false);
  }
  const closeUpdateModal=()=>{
    setUpdateModal(false);
  }
  const handleDeleteConfirm=()=>{
    setDeleteLoading(true);
    deletefeature(featureId,setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,setDeleteLoading)
  }
  const handleDeleteCancel=()=>{
    setDeleteModal(false);
  }
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  return (
        <>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
          <PageTitle>{"Feature"}</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>
            <ActiveLink>Feature</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <FeatureTable
            setData={setData}
            data={data}
            setFeatureId={setFeatureId}
            setUpdateModal={setUpdateModal}
            setDeleteModal={setDeleteModal}
            setCreateModal={setCreateModal}
            refresh={refresh}
            setRefresh={setRefresh}
            fetch={fetch}
            setFetch={setFetch}
            setFeatureData={setFeatureData}
            filterData={filterData}
            setFilterOpen={setFilterOpen}
          />

          <CreateModal 
          createModal={createModal}
          closeCreateModal={closeCreateModal}
          handleFormClose={handleFormClose}
          features={features}
          setFeatures={setFeatures}
          />

          <UpdateFeature 
          updateOpen={updateModal}
          handleUpdateClose={closeUpdateModal}
          handleFormClose={handleFormClose}
          featureData={featureData}
          setFeatureData={setFeatureData}
          featureId={featureId}
          features={features}
          />

          <DeleteFeature 
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
          deleteOpen={deleteModal}
          deleteLoading={deleteLoading}
          handleDeleteConfirm={handleDeleteConfirm}
          handleDeleteCancel={handleDeleteCancel}
          />

{filterOpen && (
        <FeatureFilter
          filterOpen={filterOpen}
          handleFilterClose={handleFormClose}
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}
        </>
  );
};

export default Feature;
