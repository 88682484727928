import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { deleteUser } from "../../../../../api/user";

export default function DeleteUser({
  deleteForm,
  userId,
  siteId,
  setAlertOpen,
  setSeverity,
  setMessage,
  handleFormClose
}) {
  const handleDeleteUser = () => {
    deleteUser(userId,
      siteId,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
    );
  };
  return (
    <>
      {/* Remove device confirmation dialog */}

      <Dialog
        open={deleteForm}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the User
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>No</Button>
          <Button color="error" onClick={handleDeleteUser}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
