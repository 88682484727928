import {
  Alert,
  Breadcrumbs,
  Button,
  Divider,
  Snackbar,
  Box,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import DeviceTable from "./elements/DeviceTable";
import UpdateDevice from "./elements/UpdateDevice";
import DeviceStats from "./elements/DeviceStat";
import Testing from "./elements/Testing";
import { stopTestDevices, testDevices } from "../../api/device";
import TestingLogs from "./elements/TestingLogs/TestingLogs";
import { UserContext } from "../../context/UserContext";

export default function TestingDashboard() {
  const navigate = useNavigate();

  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();

  // eslint-disable-next-line no-unused-vars
  const [formOpen, setFormOpen] = useState(false);
  const [deviceData, setDeviceData] = useState({
    device_description: "",
    imi_no: "",
    mobile_no: "",
    sim_no: "",
    batch_id: "",
    version_id: "",
  });

  const [updateForm, setUpdateForm] = useState(false);
  const [deviceId, setDeviceID] = useState();
  const [change, setChange] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [logsData, setLogData] = useState([]);
  const [signalDialog, setSignalDialog] = useState(false);
  const [dialog, setDialog] = useState(false);
  const { userData } = useContext(UserContext);

  const [testingStatus, setTestingStatus] = useState(false);
  const [testingData, settestingData] = useState({
    ontime: 15,
    offtime: 2,
  });
  const [testingOpen, setTestingOpen] = useState(false);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handletestingClose = () => {
    setTestingOpen(false);
  };
  const handleFormClose = () => {
    setFormOpen(false);
    setDeviceData({
      ...deviceData,
      imi_no: "",
      mobile_no: "",
      sim_no: "",
      device_description: "",
      batch_id: "",
      version_id: "",
    });
    setUpdateForm(false);
    setChange(!change);

    setDeviceID();
  };
  const handletestdevices = () => {
    if (testingStatus === false) {
      if (selectedDevices.length === 0) {
        setSeverity("error");
        setMessage("Please Select Some Devices to Test");
        setAlertOpen(true);
        return;
      }
      setTestingOpen(true);
    } else {
      stopTestDevices(
        selectedDevices,
        setSeverity,
        setAlertOpen,
        setMessage,
        setTestingStatus,
        setSelectedDevices
      );
      // setSelectedDevices([]);
      // setTestingStatus(!testingStatus);
    }
  };
  const handleTestingFilterClose = () => {
    setTestingOpen(false);
    testDevices(
      selectedDevices,
      testingData.ontime,
      testingData.offtime,
      setSeverity,
      setAlertOpen,
      setMessage,
      setTestingStatus
    );
    //  setTestingStatus(!testingStatus);
  };
  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "80%" }}>
          <PageTitle>Test Devices</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" onClick={() => navigate("/dashboard")}>
              Dashboard
            </PathLink>

            <ActiveLink>Devices</ActiveLink>
          </Breadcrumbs>
        </Box>
        {(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("TESTING_DEVICE")) && <Box sx={{
          width: `${window.matchMedia("(display-mode: standalone)").matches ||
            window.matchMedia("(max-width: 767px)").matches ? "44%" : "20%"}`, alignSelf: `${window.matchMedia("(display-mode: standalone)").matches ||
              window.matchMedia("(max-width: 767px)").matches ? "center" : "unset"}`
        }}>
          <Button
            variant="outlined"
            color={testingStatus ? "error" : "success"}
            sx={{ width: "100%" }}
            onClick={handletestdevices}
          >
            {testingStatus ? "Stop Testing" : "Test Devices"}
          </Button>
        </Box>}
      </Box>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      <DeviceStats data={data} />
      {(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("TESTING_DEVICE")) && <DeviceTable
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setUpdateForm={setUpdateForm}
        setDeviceID={setDeviceID}
        setDeviceData={setDeviceData}
        change={change}
        setFormOpen={setFormOpen}
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        testingStatus={testingStatus}
        data={data}
        setData={setData}
        setTestingStatus={setTestingStatus}
        testingData={testingData}
        handleFormClose={handleFormClose}
        setSignalDialog={setSignalDialog}
        signalDialog={signalDialog}
        dialog={dialog}
        setDialog={setDialog}
        alertOpen={alertOpen}
      />}
      {/* <CreateDevice
        formOpen={formOpen}
        handleFormClose={handleFormClose}
        deviceData={deviceData}
        setDeviceData={setDeviceData}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
      /> */}

      <UpdateDevice
        handleFormClose={handleFormClose}
        updateForm={updateForm}
        deviceData={deviceData}
        setDeviceData={setDeviceData}
        deviceId={deviceId}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
      />

      {/* {versionForm && (
        <UpdateVersion
          handleFormClose={handleFormClose}
          version={version}
          versionForm={versionForm}
          deviceId={deviceId}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          setVersion={setVersion}
        />
      )} */}

      {/* {filterOpen && (
        <DeviceFilter
          filterOpen={filterOpen}
          handleFilterClose={handleFormClose}
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )} */}

      {testingOpen && (
        <Testing
          testingOpen={testingOpen}
          handletestingClose={handletestingClose}
          testingData={testingData}
          settestingData={settestingData}
          handleTestingFilterClose={handleTestingFilterClose}
        />
      )}

      {(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("TESTING_LOGS")) && <TestingLogs
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setUpdateForm={setUpdateForm}
        setDeviceID={setDeviceID}
        setDeviceData={setDeviceData}
        change={change}
        setFormOpen={setFormOpen}
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        testingStatus={testingStatus}
        logsData={logsData}
        setLogsData={setLogData}
        setTestingStatus={setTestingStatus}
        testingData={testingData}
        handleFormClose={handleFormClose}
      />}
    </>
  );
}
