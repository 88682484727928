import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  MenuItem,
  Autocomplete
} from "@mui/material";
import { updateKtUser } from "../../../api/ktUser";

const UpdateKtUser = ({
  updateOpen,
  handleUpdateClose,
  handleFormClose,
  ktUserData,
  setKtUserData,
  ktUserId,
  roles,
  sites
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleSubmit = () => {
    setUpdateLoading(true);
    updateKtUser(
      ktUserId,
      ktUserData,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      setUpdateLoading
    );
  };
  const handleAlertClose=()=>{
    setAlertOpen(false);
  }
  const handleFieldChange=(e)=>{
    console.log(e.target.value);
    setKtUserData({...ktUserData,role:e.target.value,role_id:e.target.value});
  }
  const handleChange = (event, value) => {
    setKtUserData({...ktUserData,companies:value.map(option => option._id)});
  };
  return (
    <React.Fragment>
      <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
        <Dialog open={updateOpen} onClose={handleUpdateClose} fullWidth maxWidth="md">
        <DialogTitle>Update Super Admin</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            value={ktUserData.name}
            name="Name"
            onChange={(e) => {
              setKtUserData({ ...ktUserData, name: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            value={ktUserData.email}
            name="Email"
            onChange={(e) => {
              setKtUserData({ ...ktUserData, email: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            type='password'
            required
            fullWidth
            id="password"
            label="Password"
            value={ktUserData.password}
            name="Password"
            onChange={(e) => {
              setKtUserData({ ...ktUserData, password: e.target.value });
            }}
          />

          <TextField
            select
            margin="normal"
            fullWidth
            label="Role"
            name="Role"
            id="role"
            value={ktUserData.role_id}
            onChange={handleFieldChange}
          >
            {roles &&
              roles.map((data) => {
                return <MenuItem key={data._id} value={data._id}>{data.role_name}</MenuItem>;
              })}
          </TextField>
          <Autocomplete
          fullWidth
          style={{paddingTop:"10px"}}
          multiple
          value={sites.filter((site)=>ktUserData?.companies?.includes(site._id))}
      options={sites}
      getOptionLabel={(option) => option.company_name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Companies"
          variant="outlined"
        />
      )}
      onChange={handleChange}
    />
        </DialogContent>
        <DialogActions style={{ padding: "16px 28px" }}>
          <Button
            type="submit"
            variant="outlined"
            onClick={handleSubmit}
            color="secondary"
          >
            {updateLoading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Update Super Admin"}
          </Button>
        </DialogActions>
        
    </Dialog>
      
    </React.Fragment>
  );
};

export default UpdateKtUser;
