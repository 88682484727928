import React from "react";
import {
  Button,
  TextField,
  Typography,
  Paper,
  Grid, Tooltip, MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { resetDevice, syncDeviceEnergy, updateDeviceState } from "../../../../api/device";
import { CachedOutlined } from "@mui/icons-material";
import moment from "moment";
export default function DeviceState({ deviceData, deviceState, setDeviceState, setSeverity, setAlertOpen, setMessage, handleFormClose }) {
  const validateTimer2 = (timer, timer2) => {
    if (timer2 === "9999,9999" || timer2 === "NOT SET") return true;
    const [st, et] = timer.split(',').map(Number);
    const startTime = Math.floor(st / 100) * 60 + (st % 100);
    const [st2, et2] = timer2.split(',').map(Number);
    const startTime2 = Math.floor(st2 / 100) * 60 + (st2 % 100);

    const endTime = Math.floor(et / 100) * 60 + (et % 100);
    const endTime2 = Math.floor(et2 / 100) * 60 + (et2 % 100);

    if ((startTime2 >= startTime && startTime2 <= endTime) || (endTime2 >= startTime && endTime2 <= endTime) || (startTime2 >= endTime && endTime2 >= startTime && startTime2 > endTime2) || (startTime2 <= startTime && (endTime2 >= startTime || endTime2 <= startTime2))
    ) {
      setSeverity("error");
      setMessage("Timer-2 should not be in range of Timer-1");
      setAlertOpen(true);
      return false;
    }
    return true;
  }
  const validateTimer = (timer, timer2) => {
    if (timer2 === "9999,9999" || timer2 === "NOT SET") return true;
    const [st, et] = timer2.split(',').map(Number);
    const startTime = Math.floor(st / 100) * 60 + (st % 100);
    const [st2, et2] = timer.split(',').map(Number);
    const startTime2 = Math.floor(st2 / 100) * 60 + (st2 % 100);

    const endTime = Math.floor(et / 100) * 60 + (et % 100);
    const endTime2 = Math.floor(et2 / 100) * 60 + (et2 % 100);

    if ((startTime2 >= startTime && startTime2 <= endTime) || (endTime2 >= startTime && endTime2 <= endTime) || (startTime2 >= endTime && endTime2 >= startTime && startTime2 > endTime2) || (startTime2 <= startTime && (endTime2 >= startTime || endTime2 <= startTime2))) {
      setSeverity("error");
      setMessage("Timer-1 should not be in range of Timer-2");
      setAlertOpen(true);
      return;
    }
    return true;
  }
  const handleStateUpdate = () => {
    let data = {
      switch_val: deviceState.switch,
      deviceId: deviceData.device_id,
      mode: deviceState.mode,
      threshold: deviceState.threshold,
      timer:
        deviceState.timer === "NOT SET" || deviceState.timer === ""
          ? "9999,9999"
          : deviceState.timer,
      timer2:
        deviceState.timer2 === "NOT SET" || deviceState.timer2 === "" || deviceState.timer2 === undefined
          ? "9999,9999"
          : deviceState.timer2,
    };
    if (data.timer === "9999,9999" && data.timer2 !== "9999,9999") {
      setSeverity("error");
      setMessage("Please Clear timer2 first");
      setAlertOpen(true);
      return;
    }
    const t1 = validateTimer(data.timer, data.timer2);
    let t2 = false;
    if (t1 === true) t2 = validateTimer2(data.timer, data.timer2);
    if (t1 && t2) {
      updateDeviceState(
        data,
        setSeverity,
        setAlertOpen,
        setMessage,
        handleFormClose
      );
    }
  };
  const handleResetDevice = () => {
    resetDevice(
      setSeverity,
      setAlertOpen,
      setMessage,
      handleFormClose,
      "",
      { deviceId: deviceData.device_id }
    );
  };
  const handleEnergySync = (device_id) => {
    syncDeviceEnergy(
      deviceData.device_id,
      setSeverity, setAlertOpen, setMessage, handleFormClose
    )
  }
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", margin: "20px 0px" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Device State
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={5.5} md={3.5} style={{ marginLeft: "10px" }}>
            <Tooltip arrow placement="top" title={deviceState.switchStatus === "1" ? "ON" : "OFF"}>
              <TextField
                margin="normal"
                fullWidth
                select
                id="switch"
                label="Switch"
                value={deviceState.switch === "1" ? "1" : "0"}
                onChange={(e) => {
                  setDeviceState({
                    ...deviceState,
                    switch: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value={"0"}>OFF</MenuItem>
                <MenuItem value={"1"}>ON</MenuItem>
              </TextField>
            </Tooltip>
          </Grid>
          <Grid item xs={5.5} md={4} style={{ marginLeft: "10px" }}>
            <Tooltip arrow placement="top" title={deviceState.modeStatus}>
              <TextField
                margin="normal"
                fullWidth
                select
                id="switch"
                label="Mode"
                name="switch"
                value={deviceState.mode === "1" ? "1" : "0"}
                onChange={(e) => {
                  setDeviceState({
                    ...deviceState,
                    mode: e.target.value,
                  });
                  // setUpdatedDeviceState({
                  //   ...updatedDeviceState,
                  //   mode: e.target.value,
                  // });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value={"0"}>AUTO</MenuItem>
                <MenuItem value={"1"}>MANUAL</MenuItem>
              </TextField>
            </Tooltip>
          </Grid>
          <Grid item xs={5.5} md={2} style={{ marginLeft: "10px" }}>
            <Tooltip arrow placement="top" title={deviceState.timerStatus}>
              <TextField
                margin="normal"
                fullWidth
                id="time"
                label="Timer"
                name="time"
                value={deviceState.timer}
                placeholder="start time,end time"
                onChange={(e) => {
                  setDeviceState({
                    ...deviceState,
                    timer: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={5.5} md={2} style={{ marginLeft: "10px" }}>
            <Tooltip arrow placement="top" title={deviceState.timerStatus2}>
              <TextField
                margin="normal"
                fullWidth
                id="time2"
                label="Timer 2"
                name="time2"
                value={deviceState.timer2}
                disabled={(deviceState.timer === "9999,9999" || deviceState.timer === "NOT SET") || !deviceState.timer}
                placeholder="start time,end time"
                onChange={(e) => {
                  setDeviceState({
                    ...deviceState,
                    timer2: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={5.5} md={3.5} style={{ marginLeft: "10px" }}>
            <TextField
              margin="normal"
              fullWidth
              id="switch"
              label="Threshold"
              name="switch"
              value={deviceState.threshold}
              onChange={(e) => {
                setDeviceState({
                  ...deviceState,
                  threshold: e.target.value,
                });
                // setUpdatedDeviceState({
                //   ...updatedDeviceState,
                //   threshold: e.target.value,
                // });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* <Grid item xs={5.5} md={4} style={{marginLeft:"10px"}}>
              <Tooltip arrow placement="top" title={deviceState.show_auto===true?"Yes":"No"}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  id="autoVisible"
                  label="Auto Visible"
                  value={deviceState.show_auto===true?true:false}
                  onChange={(e) => {
                    setDeviceState({
                      ...deviceState,
                      show_auto: e.target.value,
                    });
                    // setUpdatedDeviceState({
                    //   ...updatedDeviceState,
                    //   auto: e.target.value,
                    // });
                  }}
                >
                  <MenuItem value={false}>NO</MenuItem>
                  <MenuItem value={true}>YES</MenuItem>
                </TextField>
              </Tooltip>
            </Grid> */}
          {/* <Grid item xs={2.5} md={1.4} style={{marginLeft:"10px"}}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="energy"
                  label="Energy"
                  value={deviceData?.energy?.value}
                  inputProps={{
                    readOnly:true
                  }}
                >
                </TextField>
            </Grid>
            <Grid item xs={2.5} md={2.5} style={{marginLeft:"10px"}}>
            {syncLoading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:<Button
               variant="outlined"
               fullWidth
               onClick={handleEnergySync}
               style={{marginTop:"16px"}}
              >
                Sync Energy
              </Button>} */}
          {/* <Typography variant="body2" gutterBottom>
        Last Sync: {deviceData.energy? new Date(deviceData?.energy?.last_updated_at).toLocaleString('en-GB'):""}
      </Typography> */}
          {/* </Grid> */}
          <Grid item xs={4} md={4} style={{ marginLeft: "10px" }}>
            <TextField
              margin="normal"
              fullWidth
              id="energy"
              label="Energy"
              value={deviceData?.energy?.value}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleEnergySync}>
                      <CachedOutlined />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
          <Grid item xs={4} md={4} style={{ marginLeft: "10px" }}>
            <TextField
              margin="normal"
              fullWidth
              id="energy"
              label="Last Sync Time"
              value={
                deviceData.energy ? moment(deviceData?.energy?.last_updated_at).toLocaleString('en-GB') : ""
              }
              inputProps={{
                readOnly: true,
              }}
            ></TextField>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="warning"
            disabled={deviceState.blocked}
            onClick={handleResetDevice}
            style={{ margin: "10px" }}
          >
            Reset Device
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={deviceState.blocked}
            onClick={handleStateUpdate}
            style={{ margin: "10px" }}
          >
            Update State
          </Button>
        </div>
      </Paper>
    </>
  );
}
