/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Grid,
  Snackbar,
  Alert,
  Button,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { Cached, Download, FilterList } from "@mui/icons-material";
import { getEnergyLogs } from "../../../api/device";
import { CSVLink } from "react-csv";
import { useNavigate, useParams } from "react-router-dom";

const headers = [
  { key: "energyp1", label: "Energy (Kw/h)", align: "center" },
  { key: "currentp1", label: "Current(A) Phase1", align: "left" },
  { key: "currentp2", label: "Current(A) Phase2", align: "left" },
  { key: "currentp3", label: "Current(A) Phase3", align: "left" },
  { key: "voltagep1", label: "Voltage(V) Phase1", align: "left" },
  { key: "voltagep2", label: "Voltage(V) Phase2", align: "left" },
  { key: "voltagep3", label: "Voltage(V) Phase3", align: "left" },
  { key: "powerp1", label: "Power(W) Phase1", align: "left" },
  { key: "powerp2", label: "Power(W) Phase2", align: "left" },
  { key: "powerp3", label: "Power(W) Phase3", align: "left" },
  { key: "pfp1", label: "Power Factor Phase1", align: "left" },
  { key: "pfp2", label: "Power Factor Phase2", align: "left" },
  { key: "pfp3", label: "Power Factor Phase3", align: "left" },
  { key: "createdAt", label: "Created At", align: "left" },
];

const EnergyTable = ({
  deviceData,
  loading,
  setFilterOpen,
  refresh,
  setRefresh,
  formState,
  filterData,
  setFormState,
  setFilterData,
  companyData,
  deviceLoad,
  selectedCompanies,
  setSelectedCompanies,
  fetch,
  setFetch,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [alertOpen, setAlertOpen] = useState(false);
  const [logData, setLogData] = useState([]);
  const [severity, setSeverity] = useState();
  const [deviceId, setDeviceId] = useState();
  const [message, setMessage] = useState();
  const [total, setTotal] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const  navigate= useNavigate();
  const {device_id}= useParams();
  useEffect(()=>{
  if(device_id){
    setDeviceId(device_id);
  }    
},[device_id])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchDeviceLogs = async () => {
    setTableLoading(true);
    let filter = {
      action: formState.action,
      start_time:
        filterData.start_time !== ""
          ? new Date(filterData.start_time).toISOString()
          : "",
      end_time:
        filterData.end_time !== ""
          ? new Date(filterData.end_time).toISOString()
          : "",
    };

    await getEnergyLogs(
      deviceId,
      page,
      rowsPerPage,
      filter,
      setLogData,
      setTotal,
      setTableLoading,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  };

  useEffect(() => {
    if (deviceId !== undefined) {
      fetchDeviceLogs();
      navigate(`/dashboard/energy/${deviceId}`)
    }
  }, [deviceId, page, rowsPerPage, refresh]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  let sel_device = deviceData.filter((item) => item._id === deviceId);
  let ld = [];
  logData.forEach((element) => {
    ld.push({
      ...element,
      energyp1: element.energy,
      voltagep1: element.phase1.voltage,
      voltagep2: element.phase2.voltage,
      voltagep3: element.phase3.voltage,
      currentp1: element.phase1.current,
      currentp2: element.phase2.current,
      currentp3: element.phase3.current,
      powerp1: element.phase1.power,
      powerp2: element.phase2.power,
      powerp3: element.phase3.power,
      pfp1: element.phase1.powerfactor,
      pfp2: element.phase2.powerfactor,
      pfp3: element.phase3.powerfactor,
      createdAt: new Date(element.createdAt).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      }),
    });
  });

  const csvReport = {
    filename: `Energy-Logs-${sel_device[0]?.device_id}.csv`,
    headers: headers,
    data: ld,
  };

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          {!loading && (
            <>
              <Autocomplete
                multiple
                id="companies"
                limitTags={2}
                options={companyData}
                getOptionLabel={(option) => option.company_name}
                value={selectedCompanies}
                onChange={(e, value) => {
                  // let dt = [];

                  setSelectedCompanies(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Site"
                    placeholder="Select Mulitple sites"
                    helperText="Optional"
                  />
                )}
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  setFetch(!fetch);
                }}
              >
                Get Company Device List
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {deviceLoad ? (
            <TextField
              label="Devices"
              value={"Loading Data.."}
              disabled
              fullWidth
            />
          ) : (
            <Autocomplete
              id="devices"
              limitTags={2}
              options={deviceData}
              value={deviceId?deviceData.filter((device)=>device._id===deviceId)[0]:null}
              getOptionLabel={(option) => option.device_id}
              onChange={(e, value) => {
                setDeviceId(value._id);
                setFilterData({ end_time: "", start_time: "" });
                setFormState({ action: [] });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Devices"
                  required
                  helperText="Select one Device"
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>

        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
          }}
        >
          <Typography variant="h6">Logs</Typography>
          <div style={{ display: "flex", alignItems: "left" }}>
            {logData.length !== 0 && (
              <>
                <CSVLink
                  style={{ color: "green", textDecoration: "none" }}
                  {...csvReport}
                >
                  <Button
                    variant="outlined"
                    sx={{ ml: 2, mr: 2 }}
                    endIcon={<Download />}
                  >
                    CSV
                  </Button>
                </CSVLink>
              </>
            )}
            <IconButton
              onClick={(e) => {
                setFilterOpen(true);
              }}
            >
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(e) => {
                setPage(0);
                setRefresh(!refresh);
              }}
            >
              <Tooltip title="Refresh" arrow placement="top">
                <Cached />
              </Tooltip>
            </IconButton>
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key={"energy"} align={"center"}>
                  Energy(Kw/h)
                </TableCell>
                <TableCell key={"voltage"} align={"center"} colSpan={3}>
                  Voltage(V)
                </TableCell>
                <TableCell key={"current"} align={"center"} colSpan={3}>
                  Current (A)
                </TableCell>
                <TableCell key={"power"} align={"center"} colSpan={3}>
                  Power(W)
                </TableCell>
                <TableCell key={"pf"} align={"center"} colSpan={3}>
                  PowerFactor
                </TableCell>
                <TableCell key={"ct"} align={"center"}>
                  Created At
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                ></TableCell>
                <TableCell>Phase 1</TableCell>
                <TableCell>Phase 2</TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                >
                  Phase 3
                </TableCell>
                <TableCell>Phase 1</TableCell>
                <TableCell>Phase 2</TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                >
                  Phase 3
                </TableCell>
                <TableCell>Phase 1</TableCell>
                <TableCell>Phase 2</TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                >
                  Phase 3
                </TableCell>
                <TableCell>Phase 1</TableCell>
                <TableCell>Phase 2</TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                    borderRight: "1px solid gray",
                  }}
                >
                  Phase 3
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {tableLoading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {logData.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.energy}
                      </TableCell>
                      <TableCell align="center">{row.phase1.voltage}</TableCell>
                      <TableCell align="center">{row.phase2.voltage}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.phase3.voltage}
                      </TableCell>
                      <TableCell align="center">{row.phase1.current}</TableCell>
                      <TableCell align="center">{row.phase2.current}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.phase3.current}
                      </TableCell>
                      <TableCell align="center">{row.phase1.power}</TableCell>
                      <TableCell align="center">{row.phase2.power}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.phase3.power}
                      </TableCell>
                      <TableCell align="center">
                        {row.phase1.powerfactor}
                      </TableCell>
                      <TableCell align="center">
                        {row.phase2.powerfactor}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0px 0px",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {row.phase3.powerfactor}
                      </TableCell>

                      <TableCell align="center">
                        {new Date(row.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 75, 250]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Create new device dialog */}
    </>
  );
};

export default EnergyTable;
