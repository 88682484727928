import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createRole, updateRole } from "../../../../../api/role";
import axiosIntance from "../../../../../helper/axios";

export default function CrudRole({
  formOpen,
  handleFormClose,
  setAlertOpen,
  update,
  roleId,
  setSeverity,
  setMessage,
  roleName,
  formState,
  setRoleName,
  setFormState,
  siteId,
}) {
  const [allPermissions, setAllPermissions] = useState([]);
  const [loading,setLoading]=useState(false);
  const handleFieldChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  useEffect(() => {
    axiosIntance
      .get("/role/permission/all")
      .then((res) => {
        setAllPermissions(res.data.data);
      })
      .catch((err) => {
        console.log(err, "Error");
        setAlertOpen(true);
        setSeverity("error");
        setMessage(err.response.data.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    // code to register role
    setLoading(true);
    let dt = {
      role_name: roleName,
      company_id: siteId,
      permission_array: formState.permissionArray,
    };
    createRole(dt, setSeverity, setMessage, setAlertOpen, handleFormClose,setLoading);
  };

  const handleUpdate = () => {
    // code to register user
    setLoading(true);
    let dt = {
      role_name: roleName,
      permission_array: formState.permissionArray,
    };
    updateRole(
      roleId,
      dt,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      setLoading
    );
  };

  return (
    <>
      {/* Dialog to create and update roles */}
      <Dialog open={formOpen} onClose={handleFormClose}>
        {update ? (
          <DialogTitle>Update Role</DialogTitle>
        ) : (
          <DialogTitle>Create new Role</DialogTitle>
        )}
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            value={roleName}
            id="rolename"
            label="Role Name"
            name="rolename"
            autoFocus
            onChange={(e) => {
              setRoleName(e.target.value);
            }}
          />

          <TextField
            margin="normal"
            fullWidth
            select
            name="permissionArray"
            id="permissionArray"
            variant="outlined"
            label="permissionArray"
            SelectProps={{
              multiple: true,
              value: formState.permissionArray,
              onChange: handleFieldChange,
            }}
          >
            {allPermissions
              .filter(
                (i) =>
                  !["CREATE_ROLE", "UPDATE_ROLE", "DELETE_ROLE"].includes(i)
              )
              .map((pData) => {
                return <MenuItem value={pData} key={pData}>{pData}</MenuItem>;
              })}
          </TextField>
        </DialogContent>
        <DialogActions>
          {update ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // sx={{ mt: 3, mb: 2 }}
                onClick={handleUpdate}
            >
              {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Update Role"}
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
            >
              {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create New Role"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
