import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import CrudUser from "./elements/CrudUser";
import ViewUsers from "./elements/ViewUser";
import DeleteUser from "./elements/RemoveUserDialog";
import { UserContext } from "../../../../context/UserContext";

export default function UserDashboard({
  setCommonData,
  commonData,
  groupData,
  roleData,
}) {
  const { siteId } = useParams();
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [userId, setUserId] = useState();
  const [change, setChange] = useState(false);
  const [deleteForm, setdeleteForm] = useState(false);
  const { user } = useContext(UserContext).userData;
  // console.log(user);

  const [userData, setUserData] = useState({
    full_name: "",
    phone_no: "",
    password: "",
    company_id: siteId,
    role_id: null,
  });

  const [formState, setFormState] = useState({
    groupArray: [],
  });

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFormClose = () => {
    setUserId();
    setUserData({
      full_name: "",
      phone_no: "",
      password: "",
      company_id: siteId,
      role_id: null,
    });
    setFormOpen(false);
    setdeleteForm(false);
    setUpdate(false);
    setChange(!change);
    setFormState({ groupArray: [] });
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {(user?.isSuperAdmin===true||user?.role?.permissions.includes("FETCH_SITE_USERS"))&&<ViewUsers
        siteId={siteId}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setAlertOpen={setAlertOpen}
        setFormOpen={setFormOpen}
        setUpdate={setUpdate}
        setUserId={setUserId}
        change={change}
        setCommonData={setCommonData}
        commonData={commonData}
        setUserData={setUserData}
        userData={userData}
        groupData={groupData}
        roleData={roleData}
        formState={formState}
        setFormState={setFormState}
        handleFormClose={handleFormClose}
        setdeleteForm={setdeleteForm}
      />}

      {formOpen && (
        <CrudUser
          formOpen={formOpen}
          handleFormClose={handleFormClose}
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
          siteId={siteId}
          update={update}
          userData={userData}
          setUserData={setUserData}
          groupData={groupData}
          userId={userId}
          roleData={roleData}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {deleteForm&&(
        <DeleteUser 
          deleteForm={deleteForm}
          userId={userId}
          siteId={siteId}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          handleFormClose={handleFormClose}
        />
      )

      }
    </>
  );
}
