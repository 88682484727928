import axiosIntance from "../helper/axios";

const createUser = (
  userData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  axiosIntance
    .post("/user/create", userData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      if(setLoading)setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};
const createexistingUser = (
  userData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .post("/user/existing/create", userData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const updateUser = (
  userId,
  userData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
  setLoading
) => {
  console.log(userData, "userData");
  axiosIntance
    .put(`/user/update/${userId}`, userData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
      setLoading(false);
    });
};


const updateUserDetails = (
  userId,
  userData,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  console.log(userData, "userData");
  axiosIntance
    .put(`/user/details/update/${userId}`, userData)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const getUserCompany = (
  companyId,
  setData,
  setLoading,
  page,
  limit,
  table,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  setCommonData,
  commonData,
  searchQuery
) => {
  // setLoading(true);
  if (table === "true") {
    axiosIntance
      .get(
        `/user/all/${companyId}?page=${page + 1}&limit=${limit}&table=${table}&searchTerm=${searchQuery}`
      )
      .then((res) => {
        setData(res.data.data.user_data);
        setTotal(res.data.data.metadata.total_results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.error);
        setAlertOpen(true);
        setSeverity("error");
        setMessage(err.response.data.error);
      });
  } else {
    setLoading(true);
    axiosIntance
      .get(`/user/all/${companyId}?table=${table}`)
      .then((res) => {
        setLoading(false);
        setCommonData({ ...commonData, userData: res.data.data });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.error);
        setCommonData({ ...commonData, userData: 0 });
      });
  }
};
const getuserdata=(
  setuseroptions,
  table,
  page,limit,
  setLoading,
  searchQuery,
  setTotal,
  filterData
  )=>{
    axiosIntance
      .get(
        `/user/existing/all?table=${table}&page=${page+1}&limit=${limit}&searchTerm=${searchQuery}&isDeleted=${filterData?.deleted}`
      )
      .then((res) => {
        // console.log(res.data);
        if(table){
        setuseroptions(res.data.data.user_data);
        setTotal(res.data.data.metadata.total_results);
        }else{
          setuseroptions(res.data.data);
        }
        if(setLoading)setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
}
const removeuser=(
  user_id,
  setReload,
  setSeverity,
  setMessage,
  setAlertOpen
  )=>{
    axiosIntance
      .delete(
        `/user/remove/${user_id}`
      )
      .then((res) => {
        setSeverity("success");
        setMessage(res.data.message);
        setAlertOpen(true);
        setReload(true);
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
}
const deleteUser = (
  userId,
  companyId,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose,
) => {
  axiosIntance
    .delete(`/user/delete/${companyId}/${userId}`)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};
export { createUser, updateUser,updateUserDetails, getUserCompany,getuserdata,createexistingUser,removeuser,deleteUser };
