import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { publishInitialData, updateDevice } from "../../../../../api/device";

export default function UpdateDevice({
  handleFormClose,
  updateForm,
  deviceData,
  setDeviceData,
  deviceId,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {
  const handleUpdate = () => {
    const data = {
      imi_no: deviceData.imi_no,
      sim_no: deviceData.sim_no,
      location: deviceData.device_description,
      mobile_no: deviceData.mobile_no,
      has_metering: deviceData.has_metering,
      auto:deviceData.show_auto,
    };
    setUpdateLoading(true);
    updateDevice(
      deviceId,
      data,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose
    );
  };
  const [publishLoading, setPublishLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleInitialPublish = () => {
    setPublishLoading(true);
    publishInitialData(
      deviceId,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose
    );
  };

  return (
    <>
      {/* Update device block */}
      <Dialog open={updateForm} onClose={handleFormClose}>
        <DialogTitle>Update Device</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Device Password"
            name="password"
            disabled={true}
            value={deviceData.password}
            onClick={(e) => {
              navigator.clipboard.writeText(deviceData.password);
              setAlertOpen(true);
              setSeverity("success");
              setMessage("Password copied to clipboard");
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="device_description"
            label="Device description"
            name="device_description"
            value={deviceData.device_description}
            autoFocus
            onChange={(e) => {
              setDeviceData({
                ...deviceData,
                device_description: e.target.value,
              });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="imi_no"
            label="IMI number"
            name="imi_no"
            autoFocus
            value={deviceData.imi_no}
            onChange={(e) => {
              setDeviceData({ ...deviceData, imi_no: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="sim_no"
            label="SIM number"
            name="sim_no"
            autoFocus
            value={deviceData.sim_no}
            onChange={(e) => {
              setDeviceData({ ...deviceData, sim_no: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="mobile_no"
            label="Mobile number (SIM)"
            name="mobile_no"
            value={deviceData.mobile_no}
            autoFocus
            onChange={(e) => {
              setDeviceData({ ...deviceData, mobile_no: e.target.value });
            }}
          />
              <Tooltip arrow placement="top" title={deviceData.show_auto===true?"Yes":"No"}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  id="autoVisible"
                  label="Auto Visible"
                  value={deviceData.show_auto}
                  onChange={(e) => {
                    setDeviceData({
                      ...deviceData,
                      show_auto: e.target.value,
                    });
                    // setUpdatedDeviceState({
                    //   ...updatedDeviceState,
                    //   auto: e.target.value,
                    // });
                  }}
                >
                  <MenuItem value={false}>NO</MenuItem>
                  <MenuItem value={true}>YES</MenuItem>
                </TextField>
              </Tooltip>
          <FormControlLabel
            label="Has metering"
            control={
              <Checkbox
                inputProps={{ "aria-label": "Has metering" }}
                checked={deviceData.has_metering}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    has_metering: e.target.checked,
                  });
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            disabled={deviceData.blocked}
            onClick={handleInitialPublish}
          >
            {publishLoading ? "Loading..." : "Publish Initial Data"}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleUpdate}
          >
            {updateLoading ? "Loading..." : "Update device"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
