import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";
import { updateDeviceMetering } from "../../../../api/device";

export default function MeteringForm({
  device_id,
  deviceData,
  setDeviceData,
  setAlertOpen,
  setSeverity,
  setMessage,
  handleFormClose,
}) {
  // const [phase1Fault,setPhase1Fault]= useState(deviceData.phase1FaultTolerance);
  // const [phase1PowerFactor,setPhase1PowerFactor]= useState(deviceData.phase1PowerFactor);
  // const [phase2Fault,setPhase2Fault]= useState(deviceData.phase2FaultTolerance);
  // const [phase2PowerFactor,setPhase2PowerFactor]= useState(deviceData.phase2PowerFactor);
  // const [phase3Fault,setPhase3Fault]= useState(deviceData.phase3FaultTolerance);
  // const [phase3PowerFactor,setPhase3PowerFactor]= useState(deviceData.phase3PowerFactor);
  //   const [deviceData,setDeviceData]=useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleUpdate = () => {
    const data = {
      has_metering: deviceData.has_metering,
      frequency: deviceData.frequency,
      device_power: deviceData.device_power,
      deviceOverloadLimit: deviceData.deviceOverloadLimit,
      metering_alert: deviceData.metering_alert,
      has_phase1: deviceData.has_phase1,
      phase1Totalpower: deviceData.phase1Totalpower,
      phase1LowestIndividualPower: deviceData.phase1LowestIndividualPower,
      phase1HighestIndividualPower: deviceData.phase1HighestIndividualPower,
      phase1FaultTolerance: deviceData.phase1FaultTolerance,
      phase1PowerFactor: deviceData.phase1PowerFactor,
      phase1Threshold: deviceData.phase1Threshold,
      phase1PowerLimit: deviceData.phase1PowerLimit,
      phase1OverloadTolerance: deviceData.phase1OverloadTolerance,
      phase1OverloadThreshold: deviceData.phase1OverloadThreshold,
      has_phase2: deviceData.has_phase2,
      phase2Totalpower: deviceData.phase2Totalpower,
      phase2LowestIndividualPower: deviceData.phase2LowestIndividualPower,
      phase2HighestIndividualPower: deviceData.phase2HighestIndividualPower,
      phase2FaultTolerance: deviceData.phase2FaultTolerance,
      phase2PowerFactor: deviceData.phase2PowerFactor,
      phase2Threshold: deviceData.phase2Threshold,
      phase2PowerLimit: deviceData.phase2PowerLimit,
      phase2OverloadTolerance: deviceData.phase2OverloadTolerance,
      phase2OverloadThreshold: deviceData.phase2OverloadThreshold,
      has_phase3: deviceData.has_phase3,
      phase3Totalpower: deviceData.phase3Totalpower,
      phase3LowestIndividualPower: deviceData.phase3LowestIndividualPower,
      phase3HighestIndividualPower: deviceData.phase3HighestIndividualPower,
      phase3FaultTolerance: deviceData.phase3FaultTolerance,
      phase3PowerFactor: deviceData.phase3PowerFactor,
      phase3Threshold: deviceData.phase3Threshold,
      phase3PowerLimit: deviceData.phase3PowerLimit,
      phase3OverloadTolerance: deviceData.phase3OverloadTolerance,
      phase3OverloadThreshold: deviceData.phase3OverloadThreshold,
    };
    setButtonLoading(true);
    updateDeviceMetering(
      device_id,
      data,
      setSeverity,
      setMessage,
      setAlertOpen,
      setButtonLoading,
      handleFormClose
    );
  };
  //   const handleAlertClose = () => {
  //     setAlertOpen(false);
  //   };
  //   useEffect(()=>{
  //     getDeviceMeteringData(device_id,setDeviceData,setDeviceState,setSeverity,setAlertOpen,setMessage, setLoading);
  //   },[])
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", margin: "20px 0px" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Device Metering</Typography>
        </div>
        {/* <form onSubmit={handleUpdate} style={{margin:"10px"}}> */}
        <div style={{ margin: "10px" }}>
          <FormControlLabel
            label="Has metering"
            control={
              <Checkbox
                inputProps={{ "aria-label": "Has metering" }}
                checked={deviceData.has_metering === true ? true : false}
                sx={{ alignSelf: "center" }}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    has_metering: e.target.checked,
                  });
                }}
              />
            }
            style={{ display: "flex" }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="frequency"
                label="Frequency"
                name="frequency"
                disabled={!deviceData.has_metering}
                value={deviceData.frequency}
                autoFocus
                onChange={(e) => {
                  setDeviceData({ ...deviceData, frequency: e.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="meteringAlert"
                label="Alert after readings"
                name="meteringAlert"
                disabled={!deviceData.has_metering}
                value={deviceData.metering_alert}
                autoFocus
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    metering_alert: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="devicePower"
                label="Device Power(in watts)"
                name="devicePower"
                disabled={!deviceData.has_metering}
                value={deviceData.device_power}
                autoFocus
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    device_power: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="deviceOverloadLimit"
                label="Device Overload Limit(in watts)"
                name="deviceOverloadLimit"
                disabled={!deviceData.has_metering}
                value={deviceData.deviceOverloadLimit}
                autoFocus
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    deviceOverloadLimit: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: "20px" }}>
              {/* <Typography variant="h6" gutterBottom>
      Phase 1
    </Typography> */}
              <FormControlLabel
                label="Phase 1"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Phase 1" }}
                    checked={deviceData.has_phase1 === true ? true : false}
                    sx={{ alignSelf: "center" }}
                    onChange={(e) => {
                      setDeviceData({
                        ...deviceData,
                        has_phase1: e.target.checked,
                      });
                    }}
                  />
                }
                style={{ display: "flex" }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1Totalpower"
                label="Total power(in watts)"
                name="phase1Totalpower"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1Totalpower}
                autoFocus
                onChange={(e) => {
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase1Totalpower: e.target.value,
                    };
                    if (
                      deviceData.phase1OverloadThreshold !== "" &&
                      deviceData.phase1OverloadThreshold !== undefined
                    ) {
                      const threshold = (
                        parseFloat(updatedDeviceData.phase1OverloadThreshold) +
                        parseFloat(e.target.value)
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase1TheftLowerLimit: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1LowestIndividualPower"
                label="Lowest Individual Power(in watts)"
                name="phase1LowestIndividualPower"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1LowestIndividualPower}
                onChange={(e) => {
                  const newPower = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase1LowestIndividualPower: newPower,
                    };
                    if (
                      updatedDeviceData.phase1PowerFactor &&
                      updatedDeviceData.phase1PowerFactor !== "" &&
                      updatedDeviceData.phase1FaultTolerance &&
                      updatedDeviceData.phase1FaultTolerance !== ""
                    ) {
                      const threshold = (
                        parseFloat(newPower) *
                        parseFloat(updatedDeviceData.phase1PowerFactor) *
                        (1 - parseFloat(updatedDeviceData.phase1FaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase1Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1HighestIndividualPower"
                label="Highest Individual Power(in watts)"
                name="phase1HighestIndividualPower"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1HighestIndividualPower}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase1HighestIndividualPower: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1FaultTolerance"
                label="Fault Tolerance"
                name="phase1FaultTolerance"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1FaultTolerance}
                onChange={(e) => {
                  const newFaultTolerance = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase1FaultTolerance: newFaultTolerance,
                    };
                    if (
                      updatedDeviceData.phase1PowerFactor &&
                      updatedDeviceData.phase1PowerFactor !== "" &&
                      updatedDeviceData.phase1LowestIndividualPower &&
                      updatedDeviceData.phase1LowestIndividualPower !== ""
                    ) {
                      const threshold = (
                        parseFloat(
                          updatedDeviceData.phase1LowestIndividualPower
                        ) *
                        parseFloat(updatedDeviceData.phase1PowerFactor) *
                        (1 - parseFloat(newFaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase1Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1PowerFactor"
                label="Power Factor"
                name="phase1PowerFactor"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1PowerFactor}
                onChange={(e) => {
                  const newPowerFactor = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase1PowerFactor: newPowerFactor,
                    };
                    if (
                      updatedDeviceData.phase1FaultTolerance &&
                      updatedDeviceData.phase1FaultTolerance !== "" &&
                      updatedDeviceData.phase1LowestIndividualPower &&
                      updatedDeviceData.phase1LowestIndividualPower !== ""
                    ) {
                      const threshold = (
                        parseFloat(
                          updatedDeviceData.phase1LowestIndividualPower
                        ) *
                        parseFloat(newPowerFactor) *
                        (1 - parseFloat(updatedDeviceData.phase1FaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase1Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1Threshold"
                label="Fault Threshold(in watts)"
                name="phase1Threshold"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1Threshold}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase1Threshold: e.target.value,
                  });
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1PowerLimit"
                label="Overload Limit(in watts)"
                name="phase1PowerLimit"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1PowerLimit}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase1PowerLimit: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1OverloadTolerance"
                label="Theft Tolerance"
                name="phase1OverloadTolerance"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1OverloadTolerance}
                onChange={(e) => {
                  const newOverloadTolerance = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase1OverloadTolerance: newOverloadTolerance,
                    };
                    const threshold = (
                      parseFloat(updatedDeviceData.phase1Totalpower) *
                      parseFloat(e.target.value)
                    ).toFixed(2);
                    const lowerLimit = parseFloat(
                      parseFloat(threshold) +
                      parseFloat(prevDeviceData.phase1Totalpower)
                    ).toFixed(2);
                    return {
                      ...updatedDeviceData,
                      phase1OverloadThreshold: threshold,
                      phase1TheftLowerLimit: lowerLimit,
                    };
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1OverloadThreshold"
                label="Theft Threshold(in watts)"
                name="phase1OverloadThreshold"
                disabled={!deviceData.has_metering || !deviceData.has_phase1}
                value={deviceData.phase1OverloadThreshold}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const newOverloadThreshold = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase1OverloadThreshold: newOverloadThreshold,
                    };
                    if (
                      updatedDeviceData.phase1Totalpower !== "" &&
                      updatedDeviceData.phase1Totalpower !== undefined
                    ) {
                      const threshold = (
                        parseFloat(updatedDeviceData.phase1Totalpower) +
                        parseFloat(e.target.value)
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase1TheftLowerLimit: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase1TheftLowerLimit"
                label="Theft Lower Limit(in watts)"
                name="phase1TheftLowerLimit"
                disabled={true}
                value={
                  parseFloat(deviceData.phase1Totalpower) +
                  parseFloat(deviceData.phase1OverloadThreshold)
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {/* Phase 2 */}
            <div style={{ marginRight: "20px" }}>
              {/* <Typography variant="h6" gutterBottom>
      Phase 2
    </Typography> */}
              <FormControlLabel
                label="Phase 2"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Phase 2" }}
                    checked={deviceData.has_phase2 === true ? true : false}
                    sx={{ alignSelf: "center" }}
                    onChange={(e) => {
                      setDeviceData({
                        ...deviceData,
                        has_phase2: e.target.checked,
                      });
                    }}
                  />
                }
                style={{ display: "flex" }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2Totalpower"
                label="Total power(in watts)"
                name="phase2Totalpower"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2Totalpower}
                onChange={(e) => {
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase2Totalpower: e.target.value,
                    };
                    if (
                      deviceData.phase2OverloadThreshold !== "" &&
                      deviceData.phase2OverloadThreshold !== undefined
                    ) {
                      const threshold = (
                        parseFloat(updatedDeviceData.phase2OverloadThreshold) +
                        parseFloat(e.target.value)
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase2TheftLowerLimit: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2LowestIndividualPower"
                label="Lowest Individual Power(in watts)"
                name="phase2LowestIndividualPower"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2LowestIndividualPower}
                onChange={(e) => {
                  const newPower = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase2LowestIndividualPower: newPower,
                    };
                    if (
                      updatedDeviceData.phase2PowerFactor &&
                      updatedDeviceData.phase2PowerFactor !== "" &&
                      updatedDeviceData.phase2FaultTolerance &&
                      updatedDeviceData.phase2FaultTolerance !== ""
                    ) {
                      const threshold = (
                        parseFloat(newPower) *
                        parseFloat(updatedDeviceData.phase2PowerFactor) *
                        (1 - parseFloat(updatedDeviceData.phase2FaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase2Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2HighestIndividualPower"
                label="Highest Individual Power(in watts)"
                name="phase2HighestIndividualPower"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2HighestIndividualPower}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase2HighestIndividualPower: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2FaultTolerance"
                label="Fault Tolerance"
                name="phase2FaultTolerance"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2FaultTolerance}
                onChange={(e) => {
                  const newFaultTolerance = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase2FaultTolerance: newFaultTolerance,
                    };
                    if (
                      updatedDeviceData.phase2PowerFactor &&
                      updatedDeviceData.phase2PowerFactor !== "" &&
                      updatedDeviceData.phase2LowestIndividualPower &&
                      updatedDeviceData.phase2LowestIndividualPower !== ""
                    ) {
                      const threshold = (
                        parseFloat(
                          updatedDeviceData.phase2LowestIndividualPower
                        ) *
                        parseFloat(updatedDeviceData.phase2PowerFactor) *
                        (1 - parseFloat(newFaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase2Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2PowerFactor"
                label="Power Factor"
                name="phase2PowerFactor"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2PowerFactor}
                onChange={(e) => {
                  const newPowerFactor = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase2PowerFactor: newPowerFactor,
                    };
                    if (
                      updatedDeviceData.phase2FaultTolerance &&
                      updatedDeviceData.phase2FaultTolerance !== "" &&
                      updatedDeviceData.phase2LowestIndividualPower &&
                      updatedDeviceData.phase2LowestIndividualPower !== ""
                    ) {
                      const threshold = (
                        parseFloat(
                          updatedDeviceData.phase2LowestIndividualPower
                        ) *
                        parseFloat(newPowerFactor) *
                        (1 - parseFloat(updatedDeviceData.phase2FaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase2Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2Threshold"
                label="Fault Threshold(in watts)"
                name="phase2Threshold"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2Threshold}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase2Threshold: e.target.value,
                  });
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2PowerLimit"
                label="Overload Limit(in watts)"
                name="phase2PowerLimit"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2PowerLimit}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase2PowerLimit: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2OverloadTolerance"
                label="Theft Tolerance"
                name="phase2OverloadTolerance"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2OverloadTolerance}
                onChange={(e) => {
                  const newOverloadTolerance = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase2OverloadTolerance: newOverloadTolerance,
                    };
                    const threshold = (
                      parseFloat(updatedDeviceData.phase2Totalpower) *
                      parseFloat(e.target.value)
                    ).toFixed(2);
                    const lowerLimit = parseFloat(
                      parseFloat(threshold) +
                      parseFloat(prevDeviceData.phase2Totalpower)
                    ).toFixed(2);
                    return {
                      ...updatedDeviceData,
                      phase2OverloadThreshold: threshold,
                      phase2TheftLowerLimit: lowerLimit,
                    };
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2OverloadThreshold"
                label="Theft Threshold(in watts)"
                name="phase2OverloadThreshold"
                disabled={!deviceData.has_metering || !deviceData.has_phase2}
                value={deviceData.phase2OverloadThreshold}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const newOverloadThreshold = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase2OverloadThreshold: newOverloadThreshold,
                    };
                    if (
                      updatedDeviceData.phase2Totalpower !== "" &&
                      updatedDeviceData.phase2Totalpower !== undefined
                    ) {
                      const threshold = (
                        parseFloat(updatedDeviceData.phase2Totalpower) +
                        parseFloat(e.target.value)
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase2TheftLowerLimit: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase2TheftLowerLimit"
                label="Theft Lower Limit(in watts)"
                name="phase2TheftLowerLimit"
                disabled={true}
                value={
                  parseFloat(deviceData.phase2Totalpower) +
                  parseFloat(deviceData.phase2OverloadThreshold)
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {/* Phase 3 */}
            <div>
              {/* <Typography variant="h6" gutterBottom>
      Phase 3
    </Typography> */}
              <FormControlLabel
                label="Phase 3"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Phase 3" }}
                    checked={deviceData.has_phase3 === true ? true : false}
                    sx={{ alignSelf: "center" }}
                    onChange={(e) => {
                      setDeviceData({
                        ...deviceData,
                        has_phase3: e.target.checked,
                      });
                    }}
                  />
                }
                style={{ display: "flex" }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3Totalpower"
                label="Total power(in watts)"
                name="phase3Totalpower"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3Totalpower}
                onChange={(e) => {
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase3Totalpower: e.target.value,
                    };
                    if (
                      deviceData.phase3OverloadThreshold !== "" &&
                      deviceData.phase3OverloadThreshold !== undefined
                    ) {
                      const threshold = (
                        parseFloat(updatedDeviceData.phase3OverloadThreshold) +
                        parseFloat(e.target.value)
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase3TheftLowerLimit: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3LowestIndividualPower"
                label="Lowest Individual Power(in watts)"
                name="phase3LowestIndividualPower"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3LowestIndividualPower}
                onChange={(e) => {
                  const newPower = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase3LowestIndividualPower: newPower,
                    };
                    if (
                      updatedDeviceData.phase3PowerFactor &&
                      updatedDeviceData.phase3PowerFactor !== "" &&
                      updatedDeviceData.phase3FaultTolerance &&
                      updatedDeviceData.phase3FaultTolerance !== ""
                    ) {
                      const threshold = (
                        parseFloat(newPower) *
                        parseFloat(updatedDeviceData.phase3PowerFactor) *
                        (1 - parseFloat(updatedDeviceData.phase3FaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase3Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3HighestIndividualPower"
                label="Highest Individual Power(in watts)"
                name="phase3HighestIndividualPower"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3HighestIndividualPower}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase3HighestIndividualPower: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3FaultTolerance"
                label="Fault Tolerance"
                name="phase3FaultTolerance"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3FaultTolerance}
                onChange={(e) => {
                  const newFaultTolerance = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase3FaultTolerance: newFaultTolerance,
                    };
                    if (
                      updatedDeviceData.phase3PowerFactor &&
                      updatedDeviceData.phase3PowerFactor !== "" &&
                      updatedDeviceData.phase3LowestIndividualPower &&
                      updatedDeviceData.phase3LowestIndividualPower !== ""
                    ) {
                      const threshold = (
                        parseFloat(
                          updatedDeviceData.phase3LowestIndividualPower
                        ) *
                        parseFloat(updatedDeviceData.phase3PowerFactor) *
                        (1 - parseFloat(newFaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase3Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3PowerFactor"
                label="Power Factor"
                name="phase3PowerFactor"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3PowerFactor}
                onChange={(e) => {
                  const newPowerFactor = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase3PowerFactor: newPowerFactor,
                    };
                    if (
                      updatedDeviceData.phase3FaultTolerance &&
                      updatedDeviceData.phase3FaultTolerance !== "" &&
                      updatedDeviceData.phase3LowestIndividualPower &&
                      updatedDeviceData.phase3LowestIndividualPower !== ""
                    ) {
                      const threshold = (
                        parseFloat(
                          updatedDeviceData.phase3LowestIndividualPower
                        ) *
                        parseFloat(newPowerFactor) *
                        (1 - parseFloat(updatedDeviceData.phase3FaultTolerance))
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase3Threshold: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3Threshold"
                label="Fault Threshold(in watts)"
                name="phase3Threshold"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3Threshold}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase3Threshold: e.target.value,
                  });
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3PowerLimit"
                label="Overload Limit(in watts)"
                name="phase3PowerLimit"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3PowerLimit}
                onChange={(e) => {
                  setDeviceData({
                    ...deviceData,
                    phase3PowerLimit: e.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3OverloadTolerance"
                label="Theft Tolerance"
                name="phase3OverloadTolerance"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3OverloadTolerance}
                onChange={(e) => {
                  const newOverloadTolerance = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase3OverloadTolerance: newOverloadTolerance,
                    };
                    const threshold = (
                      parseFloat(updatedDeviceData.phase3Totalpower) *
                      parseFloat(e.target.value)
                    ).toFixed(2);
                    const lowerLimit = parseFloat(
                      parseFloat(threshold) +
                      parseFloat(prevDeviceData.phase3Totalpower)
                    ).toFixed(2);
                    return {
                      ...updatedDeviceData,
                      phase3OverloadThreshold: threshold,
                      phase3TheftLowerLimit: lowerLimit,
                    };
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3OverloadThreshold"
                label="Theft Threshold(in watts)"
                name="phase3OverloadThreshold"
                disabled={!deviceData.has_metering || !deviceData.has_phase3}
                value={deviceData.phase3OverloadThreshold}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const newOverloadThreshold = e.target.value;
                  setDeviceData((prevDeviceData) => {
                    const updatedDeviceData = {
                      ...prevDeviceData,
                      phase3OverloadThreshold: newOverloadThreshold,
                    };
                    if (
                      updatedDeviceData.phase3Totalpower !== "" &&
                      updatedDeviceData.phase3Totalpower !== undefined
                    ) {
                      const threshold = (
                        parseFloat(updatedDeviceData.phase3Totalpower) +
                        parseFloat(e.target.value)
                      ).toFixed(2);
                      return {
                        ...updatedDeviceData,
                        phase3TheftLowerLimit: threshold,
                      };
                    }
                    return updatedDeviceData;
                  });
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phase3TheftLowerLimit"
                label="Theft Lower Limit(in watts)"
                name="phase3TheftLowerLimit"
                disabled={true}
                value={
                  parseFloat(deviceData.phase3Totalpower) +
                  parseFloat(deviceData.phase3OverloadThreshold)
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          {buttonLoading ? (
            <>
              Loading{" "}
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginLeft: "8px" }}
              />
            </>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // sx={{ mt: 3, mb: 2 }}
              onClick={handleUpdate}
            >
              Update Metering
            </Button>
          )}
        </div>
        {/* </form> */}
      </Paper>
    </>
  );
}
