/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Button,
  Avatar,
  Box,
  Badge,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
} from "@mui/material";
import {
  Search as SearchIcon,
  FilterList,
  AddBox,
  AddBoxTwoTone,
  Sync,
  SimCardDownloadOutlined,
  CallMergeOutlined,
} from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import {
  deleteDevice,
  downloadInventory,
  getDeviceList,
  syncSimDetails,
} from "../../../api/device";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 230,
  },
});

const headers = [
  { id: "deviceID", label: "Device ID" },
  { id: "design", label: "Design" },
  { id: "version", label: "Version" },
  { id: "batch", label: "Batch Code" },
  { id: "site", label: "Site" },
  { id: "action", label: "Action" },
  { id: "metering", label: "Metering" },
  { id: "logs", label: "Logs" },
  { id: "delete", label: "Delete" },
];

const headerSim = [
  { id: "deviceID", label: "Device ID" },
  { id: "imei_no", label: "IMEI No" },
  { id: "sim_no", label: "Sim No" },
  { id: "msisdn", label: "MSISDN number" },
  { id: "site", label: "Site" },
  { id: "action", label: "Action" },
];

const DeviceTable = ({
  setSeverity,
  setMessage,
  setAlertOpen,
  setFormOpen,
  setbulkFormOpen,
  setDeviceData,
  deviceData,
  setDeviceID,
  setUpdateForm,
  change,
  setVersionForm,
  setFilterOpen,
  filterData,
  setDesignId,
  filterCount,
  setChange,
  selectedDevices,
  setSelectedDevices,
  setFile,
  setDialog,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { userData, setUserData } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleFormOpen = () => {
    setFormOpen(true);
  };
  const handlebulkFormOpen = () => {
    setbulkFormOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handledeletedevice = (event, device_id) => {
    deleteDevice(setSeverity, setAlertOpen, setMessage, setChange, device_id);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      getDeviceList(
        setData,
        setLoading,
        "true",
        0,
        rowsPerPage,
        filterData,
        setTotal,
        setSeverity,
        setAlertOpen,
        setMessage,
        "",
        event.target.value
      );
    }, 1000);
  };

  // Device selection using checkbox
  const handleDeviceSelect = (e, device_id) => {
    if (e.target.checked) {
      setSelectedDevices((prev) => [...prev, device_id]);
    } else {
      setSelectedDevices((prev) => prev.filter((id) => id !== device_id));
    }
  };

  const handleAllDeviceSelect = (e, data) => {
    if (e.target.checked) {
      const deviceids = data.map((device) => device.device_id);
      let arr = deviceids;
      arr.push(...selectedDevices.filter((c) => !arr.includes(c)));
      setSelectedDevices(arr);
    } else {
      setSelectedDevices([]);
    }
  };

  // Function for bulk sim sync
  const handleSyncSim = (device_code) => {
    syncSimDetails(device_code, setSeverity, setAlertOpen, setMessage);
  };

  useEffect(() => {
    setLoading(true);
    getDeviceList(
      setData,
      setLoading,
      "true",
      page,
      rowsPerPage,
      filterData,
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      "",
      searchQuery
    );
  }, [page, rowsPerPage, change]);


  return (
    <>
      {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("SIM_INVENTORY"))&&
      <ToggleButtonGroup
        color="secondary"
        selected={toggle}
        onChange={() => {
          setToggle(!toggle);
        }}
        sx={{ marginBottom: "16px" }}
      >
        <ToggleButton style={{ color: toggle === true && "green" }} value={toggle}>
          Sim Inventory Toggle
        </ToggleButton>
      </ToggleButtonGroup>}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {toggle === true ? "Sim inventory" : "Device Details"}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Devices"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {/* <Button
              onClick={fetchDeviceList}
              variant="outlined"
              color="primary"
              aria-label="search"
            >
              Search{""}
            </Button> */}
            <Badge badgeContent={filterCount} color="primary">
              <IconButton
                onClick={(e) => {
                  setFilterOpen(true);
                }}
              >
                <Tooltip title="Filters" arrow placement="top">
                  <FilterList />
                </Tooltip>
              </IconButton>
            </Badge>
            {!toggle && (
              <>
                {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("CREATE_DEVICE"))&&
                <IconButton>
                  <Tooltip title="Add Device" arrow placement="top">
                    <AddBox onClick={handleFormOpen} />
                  </Tooltip>
                </IconButton>}
                {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("CREATE_BULK_DEVICE"))&&
                <IconButton>
                  <Tooltip title="Add Bulk Devices" arrow placement="top">
                    <AddBoxTwoTone onClick={handlebulkFormOpen} />
                  </Tooltip>
                </IconButton>}
              </>
            )}
          </div>
        </div>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
          }}
        >
          <div style={{ display: "flex", alignItems: "right" }}>
            {toggle &&(
              <>
                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  component="label"
                  disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("MSISDN_SYNC"))}
                  sx={{ marginRight: "8px" }}
                  startIcon={<CallMergeOutlined />}
                >
                  MSISDN Sync
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                    onChange={(e) => {
                      // console.log(e.target.files);
                      setFile(e.target.files[0]);
                      setDialog(true);
                    }}
                  />
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: "8px" }}
                  onClick={() => {
                    downloadInventory(setSeverity, setAlertOpen, setMessage);
                  }}
                  startIcon={<SimCardDownloadOutlined />}
                >
                  Download Inventory
                </Button>
                <Button
                  color="warning"
                  variant="outlined"
                  size="small"
                  disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("BULK_SYNC_SIM"))}
                  onClick={() => {
                    handleSyncSim(selectedDevices);
                  }}
                  startIcon={<Sync />}
                >
                  Bulk Sim sync
                </Button>
              </>
            )}{" "}
          </div>
        </div>
        {toggle === false ? (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
              ) : (
                <TableBody>
                  {data.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Avatar
                              style={
                                row.status === "online"
                                  ? {
                                      backgroundColor: "#3cb043",
                                      height: 10,
                                      width: 10,
                                      boxShadow: "0 0 10px 1px green",
                                    }
                                  : row.status === "updating"
                                  ? {
                                      backgroundColor: "#FFC300",
                                      height: 10,
                                      width: 10,
                                      boxShadow: "0 0 10px 1px yellow",
                                    }
                                  : {
                                      backgroundColor: "#d0312d",
                                      height: 10,
                                      width: 10,
                                      boxShadow: "0 0 10px 1px red",
                                    }
                              }
                            >
                              &nbsp;
                            </Avatar>
                            <CustomTooltip
                              arrow
                              placement="top"
                              title={
                                "Created At: " +
                                new Date(row.createdAt).toLocaleString(
                                  "en-IN",
                                  {
                                    timeZone: "Asia/Kolkata",
                                  }
                                ) +
                                "\nUpdated At: " +
                                new Date(row.updatedAt).toLocaleString(
                                  "en-IN",
                                  {
                                    timeZone: "Asia/Kolkata",
                                  }
                                )
                              }
                            >
                              <span style={{ marginLeft: 12 }}>
                                {row.device_id}
                              </span>
                            </CustomTooltip>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={row.design_data.title}>
                            <div>{row.design_data.design_id}</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Button
                          disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_DEVICE_VERSION"))}
                            color="warning"
                            onClick={(e) => {
                              setDeviceID(row._id);
                              setVersionForm(true);
                              setDesignId(row.design_data._id);
                            }}
                          >
                            {row.version_data.version_id}
                          </Button>
                        </TableCell>

                        <TableCell>{row.batch_data.batch_code}</TableCell>
                        <TableCell>{row.company_data?.company_name}</TableCell>

                        <TableCell>
                          <Button
                          disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_DEVICE"))}
                            onClick={(e) => {
                              setUpdateForm(true);
                              setDeviceID(row._id);
                              setDeviceData({
                                ...deviceData,
                                device_description: row.device_description,
                                imi_no: row.imi_no,
                                sim_no: row.sim_no,
                                mobile_no: row.mobile_no,
                                password: row.password,
                                show_auto:row.show_auto,
                                location:row.location
                              });
                            }}
                          >
                            Update
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                          disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("UPDATE_METERING"))}
                            onClick={(e) => {
                              navigate(`/dashboard/device/${row._id}`)
                            }}
                          >
                            Metering
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                          disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("FETCH_DEVICE_LOGS"))}
                            onClick={(e) => {
                              window.open(`/dashboard/logs/${row._id}`);
                            }}
                          >
                            Get Logs
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                          disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("DELETE_DEVICE"))}
                            variant="outlined"
                            color="error"
                            onClick={(e) => {
                              handledeletedevice(e, row._id);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell key="action">
                    <Checkbox
                      checked={false}
                      onChange={(e) => handleAllDeviceSelect(e, data)}
                    />
                  </TableCell>
                  {headerSim.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <div style={{ textAlign: "center" }}>loading data....</div>
              ) : (
                <TableBody>
                  {data.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell>
                          <Checkbox
                            // disabled={testingstatus}
                            checked={selectedDevices.includes(row.device_id)}
                            onChange={(e) =>
                              handleDeviceSelect(e, row.device_id)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Avatar
                              style={
                                row.status === "online"
                                  ? {
                                      backgroundColor: "#3cb043",
                                      height: 10,
                                      width: 10,
                                      boxShadow: "0 0 10px 1px green",
                                    }
                                  : row.status === "updating"
                                  ? {
                                      backgroundColor: "#FFC300",
                                      height: 10,
                                      width: 10,
                                      boxShadow: "0 0 10px 1px yellow",
                                    }
                                  : {
                                      backgroundColor: "#d0312d",
                                      height: 10,
                                      width: 10,
                                      boxShadow: "0 0 10px 1px red",
                                    }
                              }
                            >
                              &nbsp;
                            </Avatar>
                            <CustomTooltip
                              arrow
                              placement="top"
                              title={
                                "Created At: " +
                                new Date(row.createdAt).toLocaleString(
                                  "en-IN",
                                  {
                                    timeZone: "Asia/Kolkata",
                                  }
                                ) +
                                "\nUpdated At: " +
                                new Date(row.updatedAt).toLocaleString(
                                  "en-IN",
                                  {
                                    timeZone: "Asia/Kolkata",
                                  }
                                )
                              }
                            >
                              <span style={{ marginLeft: 12 }}>
                                {row.device_id}
                              </span>
                            </CustomTooltip>
                          </Box>
                        </TableCell>
                        <TableCell>{row.imi_no}</TableCell>
                        <TableCell>{row.sim_no}</TableCell>

                        <TableCell>{row.mobile_no}</TableCell>

                        <TableCell>{row.company_data?.company_name}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            disabled={!(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("SYNC_SIM"))}
                            onClick={() => {
                              handleSyncSim([row.device_id]);
                            }}
                          >
                            Sync Sim details
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default DeviceTable;
