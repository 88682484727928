import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Paper, Typography } from "@mui/material";

export default function DesignLog({ logData }) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <div
        style={{
          margin: "16px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Design Update Logs</Typography>
      </div>
      <Timeline position="alternate-reverse">
        {logData.map((row) => {
          return (
            <TimelineItem key={row._id}>
              <TimelineOppositeContent color="text.primary">
                {new Date(row.time).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                })}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent>{row.message}</TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Paper>
  );
}
