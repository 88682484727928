import React, { useContext, useEffect, useState } from "react";
import { Alert, Divider, Snackbar } from "@mui/material";
import VersionTable from "./elements/VersionTable";
import CreateVersion from "./elements/CreateVersion";
import UpdateVersion from "./elements/UpdateVersion";
import { UserContext } from "../../../../context/UserContext";
import { getFeatures } from "../../../../api/feature";
import { useParams } from "react-router-dom";

const VersionDashboard = () => {
  const [reload, setReload] = React.useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState("");
  const { designId } = useParams();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const { userData } = useContext(UserContext);

  const [attachment, setAttachment] = useState(null);
  const [features, setFeatures] = useState([]);
  const [featureData, setFeatureData] = useState([]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setReload(!reload);
    setVersionData({
      version_id: "",
      description: "",
    });
    setAttachment(null);
    setUpdateOpen(false);
    setFeatureData([]);
  };
  const [versionData, setVersionData] = useState({
    version_id: "",
    description: "",
    _id: "",
  });

  const [updateData, setUpdateData] = useState({
    version_id: "",
    description: "",
  });
  useEffect(()=>{
    if(userData &&
      (userData?.user?.isSuperAdmin === true ||
        userData?.user?.role?.permissions.includes(
          "FETCH_FEATURES"
        ))){
    async function fetchFeatures(){
    await getFeatures(
      setFeatures,
  "",
  "",
  "",
  "false",
  "",
  setSeverity,
  setAlertOpen,
  setMessage,
    );
  } 
  fetchFeatures();
}
  },[userData]);

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      {(userData?.user?.isSuperAdmin===true||userData?.user?.role?.permissions.includes("FETCH_VERSIONS"))&&<VersionTable
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        reload={reload}
        setFormOpen={setFormOpen}
        updateData={updateData}
        setUpdateData={setUpdateData}
        setUpdateOpen={setUpdateOpen}
        setReload={setReload}
        setFeatureData={setFeatureData}
      />}
      <CreateVersion
        dialog={formOpen}
        handleClose={handleFormClose}
        versionData={versionData}
        setVersionData={setVersionData}
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        attachment={attachment}
        setAttachment={setAttachment}
        features={features}
        featureData={featureData}
        setFeatureData={setFeatureData}
        designId={designId}
      />
      <UpdateVersion
        dialog={updateOpen}
        handleClose={handleFormClose}
        versionData={updateData}
        setVersionData={setUpdateData}
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        attachment={attachment}
        setAttachment={setAttachment}
        features={features}
        featureData={featureData}
        setFeatureData={setFeatureData}
      />
    </>
  );
};

export default VersionDashboard;
