import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { createBatch } from "../../../api/batch";

export default function CreateBatch({
  dialog,
  handleClose,
  batchData,
  setBatchData,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {
  const handleCreateBatch = () => {
    setLoading(true);
    createBatch(batchData, setSeverity, setMessage, setAlertOpen, handleClose,setLoading);
  };
  const [loading,setLoading]=useState(false);
  return (
    <>
      {/* Dialog to create new Batch */}

      <Dialog open={dialog} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Create New Batch</DialogTitle>

        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="batch_Code"
            label="BatchCode"
            value={batchData.batch_code}
            onChange={(e) => {
              setBatchData({ ...batchData, batch_code: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="batch_name"
            label="Batch Name"
            value={batchData.batch_name}
            onChange={(e) => {
              setBatchData({ ...batchData, batch_name: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            value={batchData.batch_description}
            onChange={(e) => {
              setBatchData({ ...batchData, batch_description: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCreateBatch}
          >{loading?<>
            Loading{" "}
            <CircularProgress
              color="inherit"
              size={20}
              style={{ marginLeft: "8px" }}
            />
          </>:
            'Create Batch'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
