import React, { useEffect, useState } from 'react';
import { Toolbar, Box, CssBaseline, Snackbar, Alert } from '@mui/material';
import { io } from 'socket.io-client';

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const drawerWidth = 240;

const AuthLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_URL)
    socket.on("connect", () => {
      socket.on("adminNotification", (message) => {
        console.log(message, 'messages',JSON.parse(message))
        let parsedMessage = JSON.parse(message)
        setAlertOpen(true);
        if (parsedMessage.success) {
          setSeverity("success")
        }
        else {
          setSeverity("error")
        }
        // setSeverity("error");
        setMessage(parsedMessage.body);
      });
    });
  }, [])


  const handleClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Navbar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Sidebar
        mobileOpen={mobileOpen}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: '#f7f9fc',
          overflowX: `${window.matchMedia("(display-mode: standalone)").matches ||
            window.matchMedia("(max-width: 767px)").matches ? "scroll" : "unset"}`
        }}
      >
        <Toolbar />
        <Snackbar
          open={alertOpen}
          autoHideDuration={50000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>{" "}

        <Box
          style={{
            minHeight: 'calc(100vh - 152px)',
          }}
          sx={{ padding: { lg: '32px' } }}
        >
          {children}
        </Box>

        <Footer />
      </Box>
    </Box>
  );
};

export default AuthLayout;
