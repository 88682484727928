// Get plans with type
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function PlanTable({
  planData,
  loading,
  setUpdateForm,
  setNewPlan,
  setPlanId,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {loading ? (
        <p>loading data...</p>
      ) : (
        <Grid container spacing={2}>
          {planData.length === 0 ? (
            <Grid item xs={12}>
              No Plans registered
            </Grid>
          ) : (
            <>
              {planData.map((row) => (
                <Grid item xs={12}>
                  <Accordion
                    expanded={expanded === row._id}
                    onChange={handleChange(row._id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id={row._id}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Plan Type - <b>{row._id.toUpperCase()}</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>Plan Code</TableCell>
                                <TableCell>Plan Name</TableCell>
                                <TableCell>Price (in Rs)</TableCell>
                                <TableCell>Time Period (in days)</TableCell>
                                <TableCell>Users Allowed</TableCell>
                                <TableCell>Groups Allowed</TableCell>

                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.data.map((row1) => (
                                <TableRow key={row1._id}>
                                  <TableCell scope="row">
                                    {row1.plan_code}
                                  </TableCell>
                                  <TableCell>{row1.plan_name}</TableCell>
                                  <TableCell>{row1.price}</TableCell>
                                  <TableCell>{row1.time_period}</TableCell>
                                  <TableCell>{row1.users_allowed}</TableCell>
                                  <TableCell>{row1.groups_allowed}</TableCell>

                                  <TableCell>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      onClick={() => {
                                        setUpdateForm(true);
                                        console.log(row1);
                                        setPlanId(row1._id);
                                        setNewPlan({
                                          plan_code: row1.plan_code,
                                          plan_name: row1.plan_name,
                                          plan_type: row._id,
                                          users_allowed: row1.users_allowed,
                                          groups_allowed: row1.groups_allowed,
                                          price: row1.price,
                                          time_period: row1.time_period,
                                        });
                                      }}
                                    >
                                      Update Plan
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      )}
    </div>
  );
}
