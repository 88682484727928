import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { updateFeature } from "../../../api/feature";

const UpdateFeature = ({
  updateOpen,
  handleUpdateClose,
  handleFormClose,
  featureData,
  setFeatureData,
  featureId,
  features
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleSubmit = () => {
    setUpdateLoading(true);
    updateFeature(
      featureId,
      featureData,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      setUpdateLoading
    );
  };
  const handleAlertClose=()=>{
    setAlertOpen(false);
  }
  return (
    <React.Fragment>
      <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
        <Dialog open={updateOpen} onClose={handleUpdateClose} fullWidth maxWidth="md">
        <DialogTitle>Update Feature</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            value={featureData.name}
            name="Name"
            onChange={(e) => {
              setFeatureData({ ...featureData, name: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            value={featureData.description}
            name="Description"
            onChange={(e) => {
              setFeatureData({ ...featureData, description: e.target.value });
            }}
          />

        </DialogContent>
        <DialogActions style={{ padding: "16px 28px" }}>
          <Button
            type="submit"
            variant="outlined"
            onClick={handleSubmit}
            color="secondary"
          >
            {updateLoading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Update Feature"}
          </Button>
        </DialogActions>
        
    </Dialog>
      
    </React.Fragment>
  );
};

export default UpdateFeature;
