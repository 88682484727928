/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Search as SearchIcon, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { fetchRolesofCompany } from "../../../../../api/role";
import { UserContext } from "../../../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "name", label: "Role Name" },
  { id: "created_by", label: "Created By" },
  { id: "permissions", label: "Permissions" },
  { id: "action", label: "Action", align: "center" },
  { id: "delete", label: "Delete", align: "center" },
];

const ViewRoles = ({
  siteId,
  setSeverity,
  setMessage,
  setAlertOpen,
  setFormOpen,
  setUpdate,
  setRoleId,
  change,
  setRoleName,
  setFormState,
  setCommonData,
  commonData,
  setDeleteOpen
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [permissionsOpen, setPermissionsOpen] = useState(false);
  const [permissionsData, setPermissionsData] = useState({
    name: "",
    permissions: [],
  });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const { userData } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handlePermissionsOpen = (name, permissions) => {
    setPermissionsData({
      name: name,
      permissions: permissions,
    });
    setPermissionsOpen(true);
  };

  const handlePermissionsClose = () => {
    setPermissionsOpen(false);
    setPermissionsData({ name: "", permissions: [] });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      fetchRolesofCompany(
        siteId,
        setData,
        setLoading,
        0,
        rowsPerPage,
        "true",
        setTotal,
        setSeverity,
        setAlertOpen,
        setMessage,
        "",
        "",
        event.target.value
      );
    }, 1000);
  };

  useEffect(() => {
    setLoading(true);
    fetchRolesofCompany(
      siteId,
      setData,
      setLoading,
      page,
      rowsPerPage,
      "true",
      setTotal,
      setSeverity,
      setAlertOpen,
      setMessage,
      "",
      "",
      searchQuery
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change, page, rowsPerPage]);

  useEffect(() => {
    setLoading(true);
    fetchRolesofCompany(
      siteId,
      "",
      setLoading,
      "",
      "",
      "false",
      "",
      "",
      "",
      "",
      setCommonData,
      commonData,
      searchQuery
    );
  }, [change]);

  // useEffect(() => {
  //   // Filter data based on search query after a delay
  //   const timer = setTimeout(() => {
  //     const filtered = data.filter((row) =>
  //       row.role_name.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setFilteredData(filtered);
  //   }, 300); // Adjust the delay time as needed

  //   return () => clearTimeout(timer);
  // }, [searchQuery, data]);


  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Roles</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Roles"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {/* <Button onClick={fetchRoles} variant="outlined" color="primary" aria-label="search">
              Search{""}
             </Button> */}
            {/* <IconButton>
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton> */}
            {((userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("CREATE_SITE_ROLE"))) && <IconButton>
              <Tooltip title="Add Role" arrow placement="top">
                <AddBox onClick={handleFormOpen} />
              </Tooltip>
            </IconButton>}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.role_name}
                      </TableCell>
                      {/* <TableCell>
                      {new Date(row.createdAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </TableCell> */}
                      <TableCell>
                        {row.created_by}
                        {/* {new Date(row.updatedAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })} */}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          onClick={() =>
                            handlePermissionsOpen(
                              row.role_name,
                              row.permissions
                            )
                          }
                        >
                          VIEW ({row.permissions.length})
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("UPDATE_SITE_ROLE"))}
                          onClick={() => {
                            setRoleName(row.role_name);
                            setFormState((formState) => ({
                              ...formState,
                              permissionArray: row.permissions,
                            }));
                            setUpdate(true);
                            setRoleId(row._id);
                            setFormOpen(true);
                          }}
                        >
                          Update
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          color="error"
                          disabled={!(userData?.user?.isSuperAdmin === true || userData?.user?.role?.permissions.includes("DELETE_SITE_ROLE"))}
                          onClick={() => {
                            setDeleteOpen(true);
                            setRoleId(row._id);
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Permission List Dialog */}
      <Dialog open={permissionsOpen} onClose={handlePermissionsClose}>
        <DialogTitle>Permissions of {permissionsData.name}</DialogTitle>
        <List style={{ paddingLeft: 8 }}>
          {permissionsData.permissions.map((permission) => (
            <ListItem>
              <ListItemText primary={permission} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
};

export default ViewRoles;
