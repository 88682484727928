import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { getAllBatchList } from "../../../api/batch";
  import {  registerbulkDevice } from "../../../api/device";
  import { getAllVersionList } from "../../../api/version";
import { getAllDesignList } from "../../../api/design";
  
  export default function CreateBulkDevice({
    bulkformOpen,
    handlebulkFormClose,
    deviceData,
    setDeviceData,
    setSeverity,
    setMessage,
    setAlertOpen,
  }) {
    const [batchData, setBatchData] = useState([]);
    const [versionData, setVersionData] = useState([]);
    const [designData, setDesignData] = useState([]);
    const [loading,setLoading]=useState(false);
  
    const handleSubmit = () => {
      setLoading(true);
      // code to register user
      registerbulkDevice(
        deviceData,
        setSeverity,
        setMessage,
        setAlertOpen,
        handlebulkFormClose,
        setLoading
      );
    };
  
    useEffect(() => {
      getAllBatchList(setBatchData, "false");
      getAllDesignList(setDesignData, "false");
    }, []);
  
    return (
      <>
        <Dialog open={bulkformOpen} onClose={handlebulkFormClose}>
          <DialogTitle>Create New Devices</DialogTitle>
          <DialogContent>
          <TextField
            select
            margin="normal"
            fullWidth
            label="Design"
            value={deviceData.design_id}
            onChange={(e) => {
              setDeviceData({
                ...deviceData,
                design_id: e.target.value,
              });
              getAllVersionList(setVersionData, "false", e.target.value);
            }}
          >
            {designData &&
              designData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.design_id + " " + option.title}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            select
            margin="normal"
            fullWidth
            label="Version"
            value={deviceData.version_id}
            onChange={(e) => {
              setDeviceData({
                ...deviceData,
                version_id: e.target.value,
              });
            }}
          >
            {versionData &&
              versionData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.version_id}
                </MenuItem>
              ))}
          </TextField>
          <TextField
              margin="normal"
              required
              fullWidth
              id="no_of_devices"
              label="No.of Devices"
              name="no_of_devices"
              value={deviceData.no_of_devices}
              autoFocus
              onChange={(e) => {
                // setDeviceData({ ...deviceData, no_of_devices: e.target.value });
                const value = e.target.value.trim(); 
                if (/^\d+$/.test(value)) { // Check if the value consists of digits only
                const intValue = parseInt(value, 10); // Convert input value to integer
                setDeviceData({ ...deviceData, no_of_devices: intValue });
                } else {
                setSeverity("error");
                setMessage("No. of devices should be a Integer value.")
                setAlertOpen(true);
                setDeviceData({ ...deviceData, no_of_devices: '' });
                }
              }}
            />
            <TextField
              select
              margin="normal"
              fullWidth
              label="Batch"
              value={deviceData.batch_id}
              onChange={(e) => {
                setDeviceData({
                  ...deviceData,
                  batch_id: e.target.value,
                });
              }}
            >
              {batchData &&
                batchData.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.batch_name + "-" + option.batch_code}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              margin="normal"
              required
              fullWidth
              id="device_description"
              label="Device description"
              name="device_description"
              autoFocus
              onChange={(e) => {
                setDeviceData({
                  ...deviceData,
                  device_description: e.target.value,
                });
              }}
            />
            
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create Devices"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  