/*jshint esversion: 11 */
import React, { useState } from 'react';
import { Dialog, Button, Alert, TextField, DialogTitle, DialogContent, MenuItem, DialogActions, Autocomplete } from '@mui/material';
import { CircularProgress, Snackbar } from '@mui/material';
import { createKtUser } from '../../../api/ktUser';

const CreateModal = ({
    createModal,
    closeCreateModal,
    handleFormClose,
    roles,
    setRoles,
    sites
}) => {
    const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [ktUserData, setKtUserData] = useState({
    name: '',
    email: '',
    password:'',
    role:null,
    companies:[]
  });
  const [CreateRequestLoading, setCreateRequestLoading] =
    useState(false);
    const handleChange = (event, value) => {
      setKtUserData({...ktUserData,companies:value.map(option => option._id)});
    };

  // useEffect(() => {
  //     fetchAvailablePermissions();
  //   // eslint-disable-next-line
  // }, [createRoleModal]);

  // const fetchAvailablePermissions = async () => {
  //   setPermissionListLoading(true);
  //   fetchSuperAdminPermission(setAvailablePermissions,setPermissionListLoading,setSeverity,setAlertOpen,setMessage,);
  // };

//   function buildSelectionInput(permissionMap) {
//     const permissionInputMap = new Map();
//     Object.keys(permissionMap)
//       .flatMap((k) => permissionMap[k])
//       .forEach((t) => {
//         permissionInputMap.set(t, false);
//       });
//     return Object.fromEntries(permissionInputMap);
//   }

  async function handleKtUserCreation(event) {
    setCreateRequestLoading(true);
    await createKtUser(
        ktUserData,
        setSeverity,
        setMessage,
        setAlertOpen,
        handleFormClose,
        setCreateRequestLoading
    );
    setKtUserData({
      name: '',
    email: '',
    password:'',
    role:null
    })
  }
  const handleAlertClose=()=>{
    setAlertOpen(false);
  }
  const handleFieldChange=(e)=>{
    setKtUserData({...ktUserData,role:e.target.value});
  }
  return (
    <React.Fragment>
      <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
        <Dialog open={createModal} onClose={closeCreateModal} fullWidth maxWidth="md">
        <DialogTitle>Create New Super Admin</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            value={ktUserData.name}
            name="Name"
            onChange={(e) => {
              setKtUserData({ ...ktUserData, name: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            value={ktUserData.email}
            name="Email"
            onChange={(e) => {
              setKtUserData({ ...ktUserData, email: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            type='password'
            required
            fullWidth
            id="password"
            label="Password"
            value={ktUserData.password}
            name="Password"
            onChange={(e) => {
              setKtUserData({ ...ktUserData, password: e.target.value });
            }}
          />

          <TextField
            select
            margin="normal"
            fullWidth
            label="Role"
            name="Role"
            id="role"
            value={ktUserData.role===null?"":ktUserData.role}
              onChange={handleFieldChange}
            
          >
            {roles &&
              roles.map((data) => {
                return <MenuItem key={data._id} value={data._id}>{data.role_name}</MenuItem>;
              })}
          </TextField>

          <Autocomplete
          fullWidth
          style={{paddingTop:"10px"}}
          multiple
          value={sites.filter((site)=>ktUserData?.companies?.includes(site._id))}
      options={sites}
      getOptionLabel={(option) => option.company_name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Companies"
          variant="outlined"
        />
      )}
      onChange={handleChange}
    />

        </DialogContent>
        <DialogActions style={{ padding: "16px 28px" }}>
          <Button
            type="submit"
            variant="outlined"
            onClick={handleKtUserCreation}
            color="secondary"
          >
            {CreateRequestLoading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create Super Admin"}
          </Button>
        </DialogActions>
        
    </Dialog>
    </React.Fragment>
  );
};

export default CreateModal;
