import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { blockDevice } from "../../../../../api/device";

export default function BlockDevice({
  blockForm,
  handleFormClose,
  block,
  deviceId,
  setSeverity,
  setMessage,
  setAlertOpen,
}) {
  const handleBlockDevice = () => {
    blockDevice(
      deviceId,
      block,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose
    );
  };
  return (
    <>
      {/* Block confirmation dialog */}

      <Dialog
        open={blockForm}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Block Device"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {block === true ? "block" : "unblock"} the
            device
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>No</Button>
          <Button color="error" onClick={handleBlockDevice}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
