import React, { useState } from "react";
import { Alert, Breadcrumbs, Divider, Snackbar } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import BatchTable from "./elements/BatchTable";
import CreateBatch from "./elements/createBatch";
import BactchFilter from "./elements/FilterBatch";

const Batch = () => {
  const [reload, setReload] = React.useState(false);

  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    deleted:false,
    createdAt:""
  });
  const [filterCount, setFilterCount] = useState(0);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setReload(!reload);
    setBatchData({
      batch_name: "",
      batch_code: "",
      batch_description: "",
    });
    setFilterOpen(false);
    let count = 0;
    if (filterData.createdAt !== "") {
      count = count + 1;
    }
    setFilterCount(count);
  };
  const [batchData, setBatchData] = useState({
    batch_name: "",
    batch_code: "",
    batch_description: "",
  });

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <PageTitle>Batches</PageTitle>
      <Breadcrumbs aria-label="breadcrumb">
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Batches</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <BatchTable
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        reload={reload}
        setFormOpen={setFormOpen}
        filterCount={filterCount}
        setFilterOpen={setFilterOpen}
        filterData={filterData}
        setReload={setReload}
      />
      <CreateBatch
        dialog={formOpen}
        handleClose={handleFormClose}
        batchData={batchData}
        setBatchData={setBatchData}
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
      />
      {filterOpen && (
        <BactchFilter
          filterOpen={filterOpen}
          handleFilterClose={handleFormClose}
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterCount={setFilterCount}
        />
      )}
    </>
  );
};

export default Batch;
