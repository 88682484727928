import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import {
  AssignmentTurnedIn,
  SimCard,
  Group,
  Workspaces,
} from "@mui/icons-material";

const StatCards = ({ deviceCount, userCount, roleCount, groupCount }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Devices
              </Typography>
              <Typography variant="h4" color="primary">
                {!deviceCount ? "0" : deviceCount.deviceData.length}
              </Typography>
            </Box>
            <SimCard sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }} />
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Users
              </Typography>
              <Typography variant="h4" color="primary">
              {!userCount ? "0" : userCount.userData.length}
              </Typography>
            </Box>
            <Group sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }} />
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Roles
              </Typography>
              <Typography variant="h4" color="primary">
                {!roleCount ? "0" : roleCount.roleData.length}{" "}
              </Typography>
            </Box>
            <AssignmentTurnedIn
              sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Groups
              </Typography>
              <Typography variant="h4" color="primary">
                {!groupCount ? "0" : groupCount.groupData.length}
              </Typography>
            </Box>
            <Workspaces sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default StatCards;
