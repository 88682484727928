import React, { useState } from "react";
import { Alert, Breadcrumbs, Divider, Snackbar } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import VersionTable from "./elements/VersionTable";
import CreateVersion from "./elements/CreateVersion";
import UpdateVersion from "./elements/UpdateVersion";

const Version = () => {
  const [reload, setReload] = React.useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const [attachment, setAttachment] = useState(null);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setReload(!reload);
    setVersionData({
      version_id: "",
      description: "",
    });
    setAttachment(null);
    setUpdateOpen(false);
  };
  const [versionData, setVersionData] = useState({
    version_id: "",
    description: "",
    _id: "",
  });

  const [updateData, setUpdateData] = useState({
    version_id: "",
    description: "",
  });

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <PageTitle>Device Version</PageTitle>
      <Breadcrumbs aria-label="breadcrumb">
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Versions</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <VersionTable
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        reload={reload}
        setFormOpen={setFormOpen}
        updateData={updateData}
        setUpdateData={setUpdateData}
        setUpdateOpen={setUpdateOpen}
        setReload={setReload}
      />
      <CreateVersion
        dialog={formOpen}
        handleClose={handleFormClose}
        versionData={versionData}
        setVersionData={setVersionData}
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        attachment={attachment}
        setAttachment={setAttachment}
      />
      <UpdateVersion
        dialog={updateOpen}
        handleClose={handleFormClose}
        versionData={updateData}
        setVersionData={setUpdateData}
        setSeverity={setSeverity}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        attachment={attachment}
        setAttachment={setAttachment}
      />
    </>
  );
};

export default Version;
