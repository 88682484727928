/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { Workspaces, PlaylistAdd, PlaylistAddCheck } from "@mui/icons-material";

const DeviceStats = (data) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [statData,setStatData]=useState({
    offline:"",
    online:""
  })
  const getofflinecount=()=>{
    let offlinecount=0;
    data.data.map((device)=>{
      if(device.status==="offline"){
        offlinecount++;
      }
    })
    return offlinecount;
  }
  const getonlinecount=()=>{
    let onlinecount=0;
    data.data.map((device)=>{
      if(device.status==="online"){
        onlinecount++;
      }
    })
    return onlinecount;
  }
  useEffect(()=>{
    setStatData({
      online:getonlinecount(),
      offline:getofflinecount()
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])
  return (
    <React.Fragment>
      <Grid container spacing={4} style={{ marginBottom: 16 }}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Offline
              </Typography>
              <Typography variant="h4" color="primary">
                {loading ? "Loading.." : statData.offline}
              </Typography>
            </Box>
            <PlaylistAdd
              sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Online
              </Typography>
              <Typography variant="h4" color="primary">
                {loading ? "Loading.." : statData.online}
              </Typography>
            </Box>
            <PlaylistAddCheck
              sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Total
              </Typography>
              <Typography variant="h4" color="primary">
                {loading
                  ? "Loading.."
                  : statData.offline + statData.online}
              </Typography>
            </Box>
            <Workspaces sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DeviceStats;
