/* eslint-disable react-hooks/exhaustive-deps */
/*jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import {
  Button,
  Alert,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

import { Snackbar } from "@mui/material";
import { getUpcomingExpiredPlans } from "../../../../api/plans";
import UpcomingPlanDialog from "./UpcomingPlanDialog";

const PlanExpiry = ({ loading, setLoading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [plans, setPlans] = useState([]);
  const [upcomingPlanDialog, setUpcomingPlanDialog] = useState(false);
  const [upcomingPlanData, setUpcomingPlanData] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleFormClose = () => {
    setUpcomingPlanData({});
    setUpcomingPlanDialog(false);
  };
  useEffect(() => {
    getUpcomingExpiredPlans(
      setLoading,
      page,
      rowsPerPage,
      setTotal,
      setPlans,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  }, [page, rowsPerPage]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const isWithinOneDay = (dateString) => {
    const providedDate = new Date(dateString);
    // Get the current date
    const currentDate = new Date();
    // Set time portion to 00:00:00 for both dates
    providedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    // Calculate the next day by adding 1 day to the current date
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    // Check if the provided date falls within the current date or the next day
    return (
      providedDate.getTime() === currentDate.getTime() ||
      providedDate.getTime() === nextDate.getTime()
    );
  };
  const headers = [
    { id: "plane_name", label: "Plan Name" },
    { id: "plan_code", label: "Plan Code" },
    { id: "expiry_date", label: "Expiry Date" },
    { id: "company", label: "Company Name" },
    { id: "upcoming", label: "Upcoming Plan" },
    { id: "purchase_date", label: "Purchase Date" },
  ];
  return (
    <React.Fragment>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Paper
        variant="outlined"
        square
        style={{ padding: "20px", textAlign: "left" }}
      >
        <Typography variant="h6">Upcoming Expired Plans</Typography>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell>Loading data...</TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {plans.map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row._id}
                      style={{
                        backgroundColor: `${
                          isWithinOneDay(row.end_date) === true
                            ? "#f2f2f2"
                            : "unset"
                        }`,
                      }}
                    >
                      <TableCell key={row.plan_name}>{row.plan_name}</TableCell>
                      <TableCell key={row.plan_code}>{row.plan_code}</TableCell>

                      <TableCell key={row.expiry_date}>
                        {row.expiry_date}
                      </TableCell>

                      <TableCell key={row.company_name}>
                        {row.company_name}
                      </TableCell>
                      <TableCell key="upcoming_plan">
                        <Button
                          color={
                            row.upcoming_plan != null ? "success" : "error"
                          }
                          onClick={(e) => {
                            setUpcomingPlanData(row.upcoming_plan);
                            setUpcomingPlanDialog(true);
                          }}
                        >
                          {row.upcoming_plan != null ? "Yes" : "No"}
                        </Button>
                      </TableCell>
                      {row.upcoming_plan != null?<TableCell key={row?.upcoming_plan?.purchasedAt}>
                        {row?.upcoming_plan?.purchasedAt ?new Date(row.upcoming_plan.purchasedAt).toLocaleDateString('en-IN'):"-"}
                      </TableCell>:<TableCell>-</TableCell>}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {upcomingPlanDialog && (
        <UpcomingPlanDialog
          upcomingPlanDialog={upcomingPlanDialog}
          upcomingPlan={upcomingPlanData}
          handleFormClose={handleFormClose}
        />
      )}
    </React.Fragment>
  );
};

export default PlanExpiry;
