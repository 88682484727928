import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import {
  createGroup,
  deleteGroupSuperAdmin,
  updateGroup,
} from "../../../../../api/group";

export default function CrudGroup({
  formOpen,
  handleFormClose,
  setAlertOpen,
  siteId,
  update,
  groupId,
  setSeverity,
  setMessage,
  groupName,
  formState,
  setGroupName,
  setFormState,
  deviceData,
  deleteForm,
  handleDeleteFormClose,
}) {
  const [loading,setLoading]=useState(false);
  const handleFieldChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const handleUpdate = () => {
    // code to register user
    setLoading(true);
    let grp = {
      group_name: groupName,
      device_array: formState.deviceArray,
    };
    updateGroup(
      groupId,
      grp,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      setLoading
    );
  };

  const deleteGroup = () => {
    console.log("DELETE group");
    deleteGroupSuperAdmin(
      groupId,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleDeleteFormClose
    );
  };

  const handleSubmit = () => {
    // code to register group
    setLoading(true);
    let groupData = {
      group_name: groupName,
      company_id: siteId,
      device_array: formState.deviceArray,
    };

    createGroup(
      groupData,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      setLoading
    );
  };
  return (
    <>
      <Dialog open={formOpen} onClose={handleFormClose}>
        {update ? (
          <DialogTitle>Update Group</DialogTitle>
        ) : (
          <DialogTitle>Create new Group</DialogTitle>
        )}
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="GroupName"
            label="GroupName"
            value={groupName}
            name="GroupName"
            autoFocus
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
          />
          <TextField
            // classes={{ root: classes.root }}
            margin="normal"
            fullWidth
            select
            name="deviceArray"
            id="deviceArray"
            variant="outlined"
            label="Devices"
            SelectProps={{
              multiple: true,
              value: formState.deviceArray,
              onChange: handleFieldChange,
            }}
          >
            {deviceData &&
              deviceData.deviceData.map((data) => {
                return <MenuItem key={data._id} value={data._id}>{data.device_id}</MenuItem>;
              })}
          </TextField>
        </DialogContent>
        <DialogActions>
          {update ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleUpdate}
            >
              {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Update Group"}
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              {loading?<>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>:"Create New Group"}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Delete group dialog */}
      <Dialog open={deleteForm} onClose={handleDeleteFormClose}>
        <DialogTitle>Delete Group</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this group
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            variant="outlined"
            onClick={handleDeleteFormClose}
          >
            NO
          </Button>
          <Button color="error" variant="outlined" onClick={deleteGroup}>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
